import React from 'react';
import { withRouter } from 'react-router-dom';
//container
import View from './registration-complete-view';
//services
import {CompetitionService} from '../../utils/data-transfer';
import AuthorisationService from '../../utils/authorisation/authorisationService';


class RegistrationCompleteContainer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      hasAccount: false,
      registrationCode: '',
      user: null,
      isLoading: true
    };

    this._competitionService = new CompetitionService();
  }

  componentDidMount() {
    return this.getPlayerStatus();
  }

  getPlayerStatus = async () => {
    try {
        let status = await this._competitionService.getPlayerStatus(this.props.competitionId, this.props.competitionPlayerId);
        this.setState({
            hasAccount: status.hasAccount,
            registrationCode: status.registrationCode
        });

        let user = await AuthorisationService.getUser();
        this.setState({
          user,
          isLoading: false
        });
    } catch (err) {
      console.log(err)
      this.setState({isLoading: false});
    }
  };

  nextAction = async () => {
    if (!this.state.hasAccount)
      return this.props.history.push('/register-user/' + this.state.registrationCode);

    if (this.state.user)
      return this.props.history.push('/dashboard');

    AuthorisationService.signinRedirect();
  }

  render() {
    return (
      <View
        teamName={this.props.teamName}
        drawDate={this.props.drawDate}
        hasAccount={this.state.hasAccount}
        user={this.state.user}
        nextAction={this.nextAction}
        isLoading={this.state.isLoading}/>
    );
  }
}

export default withRouter(RegistrationCompleteContainer);