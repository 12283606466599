const AppFooterStyles = theme => ({
  footerContainer: {
    width: '100%',
    backgroundColor: theme.palette.siteBackdrop.main,
    padding: '10px 10px 0'
  },
  divider: {
    backgroundColor: '#d9d9d9'
  },
  buttonContainer: {
    padding: '10px 0',
    lineHeight: '42px',
    textAlign: 'center',
    [theme.breakpoints.down('sm')]: {
      lineHeight: 0
    }
  },
  footerButton: {
    backgroundColor: 'transparent'
  },
  poweredByContainer: {
    textAlign: 'left',
    padding: '10px 20px 10px 0',
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center',
      padding: '10px 0'
    }
  },
  poweredByText: {
    display: 'inline-block',
    //height: '100%',
    lineHeight: '30px',
    fontSize: '12px',
    verticalAlign: 'middle',
    [theme.breakpoints.down('sm')]: {
      marginLeft: -5
    }
  },
  poweredByImage: {
    width: 150,
    marginBottom: -7,
    marginTop:-40,
    [theme.breakpoints.down('sm')]: {
      marginLeft: 10
    }
  }
});

export default AppFooterStyles;