import CoreStyles from '../../utils/styles/core-styles';

const RegistrationCompleteStyles = theme => ({
  containerPadding: {
    [theme.breakpoints.down('sm')]: {
      paddingLeft: 0,
      paddingRight: 0
    }
  },
  errorText: {
    fontSize: '12px',
    color: 'red'
  },
  registrationCard: {
    padding: ['30px 16px', '!important']
  },
  registrationCompleteDate: {
    fontSize: 20,
    // paddingLeft: 30
  },
  errorIcon: {
    height: 60,
    width: 60
  },
  errorHeader: {
    padding: '20px 0'
  },
  errorBody: {
    padding: '30px 0'
  },
  header: {
    fontSize: '18px',
    fontWeight: 'bold',
    color: theme.palette.primary.contrastText,
    backgroundColor: theme.palette.primary.main,
    textAlign: 'center',
    padding: '10px 0'
  },
  ...CoreStyles(theme)
});

export default RegistrationCompleteStyles;