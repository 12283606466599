import React from 'react';
//material
import {Container, Grid, TextField, withStyles} from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
//components
import EventImageItem from '../../../components/events/event-item';
//styles
import styles from './events-styles';

const EventsView = (props) => {
  const {
    isLoading,
    events,
    classes,
    handleSearchChange,
    filterText
  } = props;

  return (
    <Container maxWidth={'md'}>
      <Grid container>
        <Grid item xs={12} sm={8} md={6} className={classes.searchContainer}>
          {
            props.isLoading ?
              <Skeleton height={56}/> :
              <TextField
                fullWidth
                label={'Search Events'}
                placeholder={'Search Events by Name or Course'}
                value={filterText}
                name={'filterText'}
                onChange={handleSearchChange}
                variant={'outlined'}/>
          }
        </Grid>

        <Grid container spacing={2}>
          {
            !isLoading && events.length > 0 && events.map((e, i) =>
              <Grid key={i} item xs={12} sm={4}>
                <EventImageItem
                  event={e}/>
              </Grid>)
          }

          {
            isLoading &&
              <>
                <Grid item xs={12} sm={4}><EventImageItem isLoading/></Grid>
                <Grid item xs={12} sm={4}><EventImageItem isLoading/></Grid>
                <Grid item xs={12} sm={4}><EventImageItem isLoading/></Grid>
                <Grid item xs={12} sm={4}><EventImageItem isLoading/></Grid>
                <Grid item xs={12} sm={4}><EventImageItem isLoading/></Grid>
                <Grid item xs={12} sm={4}><EventImageItem isLoading/></Grid>
                <Grid item xs={12} sm={4}><EventImageItem isLoading/></Grid>
                <Grid item xs={12} sm={4}><EventImageItem isLoading/></Grid>
              </>
          }
        </Grid>

        {
          !isLoading && events.length === 0 &&
            <div className={classes.noEvents}>
              No Events to show
            </div>
        }
      </Grid>

    </Container>
  )
}

export default withStyles(styles) (EventsView);
