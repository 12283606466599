import React, { useEffect } from 'react';
import styles from '../registration-styles';
//material
import { withStyles, Card, CardContent, Grid, TextField, Button, Avatar, Tooltip } from '@material-ui/core';

let helpLink = process.env.REACT_APP_COMPETITIONGOLF_HELP_URL;
helpLink = (!helpLink.startsWith('#{') && !helpLink.endsWith('}#')) ? helpLink : '';

function AdditionalPlayerView (props) {
  const classes = props.classes;

  useEffect(() => {
    const isStepComplete = () => props.registration.additionalPlayerFirstName && props.registration.additionalPlayerLastName && props.registration.additionalPlayerHandicap !== '' &&
      (!props.registration.additionalPlayerEmail || props.registration.additionalPlayerEmail.match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)) &&
      (!props.registrationSettings.additionalPlayerEmailRequired || props.registration.additionalPlayerEmail) &&
      (!props.registrationSettings.additionalPlayerTelephoneRequired || props.registration.additionalPlayerPhone);
    const stepCompleted = isStepComplete();

    if (stepCompleted && !props.isStepValid)
      props.setStepValid(true);

    if (!stepCompleted && props.isStepValid)
      props.setStepValid(false);
  });

  return (
    <Card>
      <div className={classes.slideHeader}>
        Additional Player
      </div>

      <CardContent>
        {
          helpLink &&
          <a
            className={classes.helpButton}
            href={helpLink}
            target={'_blank'}
            rel={'noopener'}>
            need help?
          </a>
        }

        <p className={classes.slideInfo}>
          Please enter the details of the additional player.
        </p>

        <Grid container className={classes.componentGrid}>
          <Grid item xs={12} md={10}>
            <Grid container spacing={2}>
              <Grid className={classes.textFieldSpacing} item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label={'First Name'}
                  value={props.registration.additionalPlayerFirstName}
                  name={'additionalPlayerFirstName'}
                  onChange={props.handleUpdateRegistration}
                  variant={'outlined'}/>
              </Grid>

              <Grid className={classes.textFieldSpacing} item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label={'Last Name'}
                  value={props.registration.additionalPlayerLastName}
                  name={'additionalPlayerLastName'}
                  onChange={props.handleUpdateRegistration}
                  variant={'outlined'}/>
              </Grid>

              <Grid className={classes.textFieldSpacing} item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label={'Email Address ' + ( props.registrationSettings.additionalPlayerEmailRequired ? '' : '(Optional)')}
                  value={props.registration.additionalPlayerEmail}
                  name={'additionalPlayerEmail'}
                  onChange={props.handleUpdateRegistration}
                  variant={'outlined'}
                  type={'email'}/>
              </Grid>

              <Grid className={classes.textFieldSpacing} item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label={'Telephone ' + ( props.registrationSettings.additionalPlayerTelephoneRequired ? '' : '(Optional)')}
                  value={props.registration.additionalPlayerPhone}
                  name={'additionalPlayerPhone'}
                  onChange={props.handleUpdateRegistration}
                  variant={'outlined'}
                  type={'tel'}/>
              </Grid>

              <Grid className={classes.textFieldSpacing} item xs={12} sm={6}>
                <Tooltip title={'Please enter your WHS handicap index.'}>
                  <TextField
                    fullWidth
                    label={'Handicap Index'}
                    placeholder={'(ex: 10.7)'}
                    value={props.registration.additionalPlayerHandicap}
                    name={'additionalPlayerHandicap'}
                    onChange={props.handleUpdateRegistration}
                    variant={'outlined'}
                    type={'number'}/>
                </Tooltip>
              </Grid>

              <Grid className={classes.textFieldSpacing} item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label={'Handicap Number (Optional)'}
                  value={props.registration.additionalPlayerHandicapNumber}
                  name={'additionalPlayerHandicapNumber'}
                  onChange={props.handleUpdateRegistration}
                  variant={'outlined'}/>
              </Grid>

            </Grid>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  )
}

export default withStyles(styles) (AdditionalPlayerView);
