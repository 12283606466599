import Welcome from './welcome';
import TheRules from './the-rules';
import Faqs from './faqs';
import TermsAndConditions from './terms-and-conditions';
import SessionExpired from './session-expired';
import MyDashboard from './my-dashboard';
import Competition from './competition';
import Registration from './registration';
import ExpressCheckout from './express-checkout';
import ExpressCheckoutComplete from './express-checkout-complete';
import InvalidCode from './invalid-code';
import AccountRegistration from './account-registration';
import RegistrationComplete from './registration-complete';
import InvalidUser from './invalid-user';
import ViewBracket from './view-bracket';
import SignIn from './sign-in';
import RedeemCode from './redeem-code';
import Members from './v2/members';
import ShowMember from './show-member';
import Events from './v2/events';
import Dashboard from './dashboard';
import Event from './v2/event';

let hidePublicPages = process.env.REACT_APP_HIDE_PUBLIC_PAGES;
hidePublicPages = (!hidePublicPages.startsWith('#{') && !hidePublicPages.endsWith('}#')) ? JSON.parse(hidePublicPages) : false;

export const GetRoutes = (user, useExpressCheckout) => {
  let routes = Routes.filter(route => route.allowAnonymous || (user && !user.expired));

  if (useExpressCheckout)
    routes.forEach(r => {
      if (r.path === '/registration/:discountCode?')
        r.component = ExpressCheckout;
    });

  if (hidePublicPages)
    routes = routes.filter(r => r.path !== '/welcome' && r.path !== '/the-rules' && r.path !== '/faqs');

  return routes;
};

export const Routes = [
  { path: '/welcome', component: Welcome, name: 'Welcome', allowAnonymous: true },
  { path: '/the-rules', component: TheRules, name: 'The Rules', allowAnonymous: true },
  { path: '/faqs', component: Faqs, name: 'FAQs', allowAnonymous: true },
  { path: '/terms-and-conditions', component: TermsAndConditions, name: 'Terms & Conditions', allowAnonymous: true },
  { path: '/session-expired', component: SessionExpired, name: 'Session Expired', allowAnonymous: true },
  { path: '/dashboard', component: MyDashboard, name: 'Dashboard', allowAnonymous: false },
  { path: '/competition/:competitionId/:competitionPlayerId', simplePathName: '/competition', component: Competition, name: 'Competition', allowAnonymous: false },
  { path: '/invite/:inviteCode', simplePathName: '/invite', component: Registration, name: 'Registration', allowAnonymous: true },
  { path: '/resume-registration/:teamCode', simplePathName: '/resume-registration', component: Registration, name: 'Registration', allowAnonymous: true },
  { path: '/error', component: InvalidCode, name: 'Invalid Code', allowAnonymous: true },
  { path: '/registration/:discountCode?', component: Registration, name: 'Registration', allowAnonymous: true },
  { path: '/register-user/:accountCode', simplePathName: '/register-user', component: AccountRegistration, name: 'User Registration', allowAnonymous: true },
  { path: '/registration-complete', component: RegistrationComplete, name: 'Registration Complete', allowAnonymous: true },
  { path: '/invalid-user', component: InvalidUser, name: 'Invalid User', allowAnonymous: true },
  { path: '/brackets/:bracketCode', component: ViewBracket, name: 'View Bracket', allowAnonymous: true },
  { path: '/checkout-complete', component: ExpressCheckoutComplete, name: 'Checkout Complete', allowAnonymous: true },
  { path: '/sign-in', component: SignIn, name: 'Sign In', allowAnonymous: true },
  { path: '/redeem-code', component: RedeemCode, name: 'Redeem Code', allowAnonymous: true },
  { path: '/members', component: Members, name: 'Members', allowAnonymous: false },
  { path: '/events', component: Events, name: 'Events', allowAnonymous: false },
  //This will want to be the /dashboard route once the other one is renamed to be just about Matchplay.
  { path: '/home', component: Dashboard, name: 'Dashboard', allowAnonymous: false },
  { path: '/show-member/:organisationMemberId', component: ShowMember, name: 'Show Member', allowAnonymous: false },
  { path: '/event/:eventId', component: Event, name: 'Event', allowAnonymous: false },
];
