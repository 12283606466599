const MemberStyles = theme => ({
  avatar: {
    width: '100px',
    height: '100px',
    margin: '0 auto'
  },
  center: {
    textAlign: 'center'
  },
  iconButton: {
    padding: 7
  },
  name: {
    fontSize: '20px',
    fontWeight: 500,
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    paddingTop: 10
  },
  company: {
    fontSize: '12px',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    paddingBottom: '10px',
    minHeight: '26px'
  },
  details: {
    paddingTop: '5px',
    minHeight: '23px',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    fontSize: '12px',
  },
  handicap: {
    paddingTop: '10px',
    paddingBottom: 25,
    minHeight: '23px',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    fontSize: '12px',
  },
  cardContent: {
    padding: ['10px 10px 5px', '!important']
  },
  facebookButton: {
    color: '#4267B2'
  },
  linkedInButton: {
    color: '#2867B2'
  },
  twitterButton: {
    color: '#1DA1F2'
  },
  primaryButton: {
    color: theme.palette.primary.main
  },
  skeletonLoading: {
    marginBottom: 5
  },
  skeletonCentered: {
    display: 'inline-block'
  },
  avatarBackground: {
    height: 300,
    width: 300,
    borderRadius: 150,
    backgroundColor: theme.palette.primary.main,
    position: 'absolute',
    top: '-180px',
    left: '50%',
    transform: 'translateX(-50%)',
  },
  card: {
    overflow: 'hidden',
    position: 'relative'
  },
  memberTypePill: {
    position: 'absolute',
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    padding: '3px 15px',
    borderRadius: 5,
    bottom: 1,
    left: '50%',
    transform: 'translateX(-50%)',
  },
  footerContainer: {
    paddingTop: 0
  },
});

export default MemberStyles;
