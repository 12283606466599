import Axios from 'axios';

const competitionGolfApi = process.env.REACT_APP_COMPETITIONGOLF_PUBLIC_API_ENDPOINT;

class ClubService {

  getFilteredClubs (nameFilter) {
    return Axios.get(competitionGolfApi + 'clubs/' + nameFilter)
      .then(resp => resp.data)
      .catch(err => {
        if (err.response.status === 404)
          return [];

        window.rg4js('send', {
          error: err
        });
        throw err;
      });
  }

  getAnonymousFilteredClubs (nameFilter) {
    return Axios.get(competitionGolfApi + 'clubs/anonymous/' + nameFilter)
      .then(resp => resp.data)
      .catch(err => {
        if (err.response.status === 404)
          return [];

        window.rg4js('send', {
          error: err
        });
        throw err;
      });
  }
}

export default ClubService;
