import React from 'react';
import styles from '../details/details-styles';
import Moment from 'moment';
//material
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Grid, TextField, withStyles, MenuItem, CircularProgress, Typography } from '@material-ui/core';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/moment';
//moment
import AsyncSelect from '../../async-select/index';
//utils
import { MatchplayScores } from '../../../utils/constants/index';

function EnterResultsView (props) {
  const classes = props.classes;
  let collection = props.clubs.map(club => { return {id: club.clubId, name: club.name + ' (' + club.county +')'}; });
  let teams = props.details.nextOpponent ? [
    { id: props.details.competitionTeamId, name: props.details.pairName},
    { id: props.details.nextOpponent.competitionTeamId, name: props.details.nextOpponent.name  }
  ] : [];

  return (
    <Dialog
      maxWidth={'sm'}
      open={props.viewEnterResults}
      onClose={() => {if (props.resultsSubmissionLoading) return; props.handleShowEnterResults(false);}}
      fullWidth
      classes={{ paper: classes.enterResultsPaper }}>
      <DialogTitle>Match Results</DialogTitle>
      <DialogContent>
        <Grid container>
          <Grid item xs={12}>
            <p className={classes.enterResultsDetails + ' ' + classes.marginTop0}>
              Please enter the results of your match.
              <br/>
              Once you submit the score, both teams will receive an email with the results.
            </p>
          </Grid>

          <Grid item xs={8} sm={6} className={classes.marginBottom10}>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDatePicker
                autoOk
                disableToolbar
                fullWidth
                inputVariant={'outlined'}
                label={'Match Date'}
                invalidDateMessage={''}
                value={props.resultsEntry.matchDate}
                format='DD/MM/YYYY'
                onChange={props.handleDateChange}/>
            </MuiPickersUtilsProvider>
          </Grid>

          <Grid container>
            <Grid item xs={10} sm={8} className={classes.marginBottom10}>
              <AsyncSelect
                asyncCall={props.getClubsMatchingQuery}
                collection={collection}
                label={'Golf Club'}
                placeholder={'Search for a club by name'}
                handleAssignId={props.handleClubChange}/>
            </Grid>
          </Grid>

          <Grid container>
            <Grid item xs={10} sm={8} className={classes.marginBottom10}>
              <TextField
                fullWidth
                select
                name={'winningTeamId'}
                onChange={props.handleResultChange}
                value={props.resultsEntry.winningTeamId}
                label={'Winning Team'}
                variant={'outlined'}>
                {
                  teams.map((team, index) => (<MenuItem key={index} value={team.id}>{team.name}</MenuItem>))
                }
                <MenuItem style={{display: 'none'}}/>
              </TextField>
            </Grid>
          </Grid>

          <Grid container>
            <Grid item xs={8} sm={6} className={classes.marginBottom10}>
              <TextField
                fullWidth
                select
                name={'score'}
                onChange={props.handleResultChange}
                value={props.resultsEntry.score}
                label={'Score'}
                variant={'outlined'}>
                {
                  MatchplayScores.map((score, index) => (<MenuItem key={index} value={score}>{score}</MenuItem>))
                }
                <MenuItem style={{display: 'none'}}/>
              </TextField>
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button color={'primary'} onClick={() => props.handleShowEnterResults(false)}>Cancel</Button>
        <Button color={'primary'} disabled={(!props.resultsEntry.matchDate || (props.resultsEntry.matchDate && !Moment(props.resultsEntry.matchDate).isValid())) || !props.resultsEntry.clubId  || !props.resultsEntry.winningTeamId || !props.resultsEntry.score} onClick={props.handleResultsSubmission}>Submit</Button>
      </DialogActions>

      {
        props.resultsSubmissionLoading &&
        <div className={classes.enterResultsLoadingContainer}>
          <div><CircularProgress color={'primary'} size={60}/></div>
          <div><Typography variant={'body1'} display={'block'}>Please Wait...</Typography></div>
        </div>
      }
    </Dialog>
  );
}

export default withStyles(styles) (EnterResultsView);