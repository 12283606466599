export const TeamPlayStatus = {
  Won: 1,
  Eliminated: 2,
  Undrawn: 3,
  Ready: 4,
  Playing: 5,
  Incomplete: 6
};

export const MatchplayScores = [
  '1 Up',
  '2 Up',
  '2 & 1',
  '3 & 1',
  '3 & 2',
  '4 & 2',
  '4 & 3',
  '5 & 3',
  '5 & 4',
  '6 & 4',
  '6 & 5',
  '7 & 5',
  '7 & 6',
  '8 & 6',
  '8 & 7',
  '9 & 7',
  '9 & 8',
  '10 & 8',
];

export const DefaultGravatarUrl = 'https://www.gravatar.com/avatar/000?s=250&d=mm';