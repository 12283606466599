import React from 'react';
import styles from './team-player-styles';
//material
import { Card, CardContent, withStyles, Typography, Grid, Avatar, Button } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';

function TeamPlayerView (props) {
  const classes = props.classes;

  return (
    <Card className={classes.playerCardSpacer}>
      <CardContent className={classes.cardContent}>
        <Typography className={classes.cardHeader}>{ props.isLeadPlayer ? 'Lead Player' : 'Additional Player' }</Typography>

        {
          props.isWizardView &&
          <Button
            className={classes.wizardEdit}
            disableElevation
            color={'primary'}
            variant={'contained'}
            size={'small'}
            onClick={props.handleWizardEditClick}
            disabled={props.isLoading}>
            edit
          </Button>
        }

        <Grid container className={props.displayAvatar ? classes.cardAvatarBodyPadding : classes.cardBodyPadding}>
          {
            props.displayAvatar &&
            <Grid item xs={12} sm={4}>
              {
                !props.isLoading  ?
                  <Avatar
                    classes={{root: classes.avatarRoot + ' ' + (!props.canEditPlayer ? classes.avatarLarge : '')}}
                    src={props.player.photoUrl}/> :
                  <Skeleton className={classes.alignSelfCenter} variant={'circle'} height={80} width={80}/>
              }

              {
                props.canEditPlayer &&
                ( !props.isLoading ?
                    <Typography className={classes.editAvatar} align={'center'} onClick={() => props.handleShowDialog(true, props.player)}>edit</Typography> :
                    <Skeleton className={classes.alignSelfCenter} height={15} width={40}/>
                )
              }
            </Grid>
          }

          <Grid item xs={12} sm={8} className={classes.playerTextSmallPadding}>
            {
              !props.isLoading ?
                <Typography className={classes.cardBar}>{ props.player.name }</Typography> :
                <Skeleton height={25}/>
            }

            {
              !props.isLoading ?
                <Typography className={classes.cardBar}>HCP&nbsp;Index:&nbsp;{ props.player.handicap  }</Typography> :
                <Skeleton height={25}/>
            }

            {
              !props.isLoading ?
                (props.player.email && <Typography className={classes.cardBar}>{ props.emailLink ? <a href={'mailto:' + props.player.email}>{ props.player.email }</a> : props.player.email }</Typography>) :
                <Skeleton height={25}/>
            }

            {
              !props.isLoading ?
                (props.player.phone && <Typography className={classes.cardBar}><a href={'tel:' + props.player.phone }>{ props.player.phone }</a></Typography>) :
                <Skeleton height={25}/>
            }
          </Grid>
        </Grid>

      </CardContent>
    </Card>
  );
}

export default withStyles(styles) (TeamPlayerView);
