
const ExpressCheckoutStyles = theme => ({
  radioButtonItem: {
    marginTop: 16
  },
  submitButton: {
    float: 'right',
    margin: '10px 0'
  },
  buttonOuterContainer: {
    marginTop: 20
  },
  buttonContainer: {
    margin: '0 auto'
  },
  stripePaymentButton: {
    backgroundColor: 'rgb(44, 46, 47);',
    color: '#ffffff',
    '&:hover':{
      backgroundColor: '#000000',
      color: '#ffffff',
    }
  },
  faIcon: {
    width: 'fit-content',
    marginRight: 10
  },
  voucherButtonContainer: {
    lineHeight: '56px',
    verticalAlign: 'middle'
  },
  voucherSkeleton: {
    marginTop: '20px'
  },
  containerPadding: {
    [theme.breakpoints.down('sm')]: {
      paddingLeft: 0,
      paddingRight: 0
    }
  }
});

export default ExpressCheckoutStyles;
