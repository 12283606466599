import React from 'react';
import { withRouter } from 'react-router-dom';
//components
import DashboardView from './dashboard-view';
//utils
import OrganisationService from '../../utils/data-transfer/organisation-service';

class DashboardContainer extends React.Component {
  constructor (props) {
    super (props);

    this._organisationService = new OrganisationService();
    this.state = {
      competitions: [],
      isLoading: true,
      toggleState: '0'
    }
  }

  componentDidMount () {
    this._isMounted = true;
    return this.getCompetitions();
  }

  componentWillUnmount () {
    this._isMounted = false;
  }

  getCompetitions = async () => {
    try {
      let competitions = await this._organisationService.getUserCompetitions();

      if (!this._isMounted)
        return;

      this.setState({competitions, isLoading: false});
    } catch (err) {
      console.log(err);
      this.props.history.push('/error?errorMessage=An unexpected error occurred when retrieving your Competitions.\nIf this error persists please Contact Us.');
    }
  };

  viewCompetition = (competitionId, competitionPlayerId) => {
    this.props.history.push(`competition/${competitionId}/${competitionPlayerId}`);
  };

  completeRegistration = (inviteCode) => {
    this.props.history.push(`invite/${inviteCode}`);
  };

  setToggleState = (e, toggleState) => {
    if (toggleState != null)
      this.setState({toggleState});
  };

  render () {
    return (
      <DashboardView
        competitions={this.state.competitions}
        viewCompetition={this.viewCompetition}
        isLoading={this.state.isLoading}
        toggleState={this.state.toggleState}
        setToggleState={this.setToggleState}
        completeRegistration={this.completeRegistration}/>
    );
  }
}

export default withRouter(DashboardContainer);