import React from 'react';
import _ from 'lodash';
import ReactGA from 'react-ga';
//Components
import UserSessionView from './user-session-view';
//Routing
import { withRouter } from 'react-router-dom';
import { Routes } from '../../routes';
//Utils
import AuthorisationService from '../../utils/authorisation/authorisationService';
import { OrganisationService } from '../../utils/data-transfer';

const rayGunKey = process.env.REACT_APP_COMPETITIONGOLF_RAYGUN_KEY;
const activeCampaignKey = process.env.REACT_APP_COMPETITIONGOLF_ACTIVE_CAMPAIGN;
const googleAnalyticsKey = process.env.REACT_APP_COMPETITIONGOLF_GOOGLE_ANALYTICS;

class UserSessionContainer extends React.Component {

  constructor (props) {
    super(props);

    this.state = {
      children: props.children,
      user: null,
      isReady: false,
      userHasPermissions: false,
      settings: null
    };

    this._authService = new AuthorisationService();
    this._organisationService = new OrganisationService();

    window.rg4js('apiKey', rayGunKey);
    window.rg4js('enableCrashReporting', true);
    window.rg4js('enablePulse', true);
    props.history.listen((location) => this.handleRouteChange(location));
  }

  componentDidMount () {
    return this.checkUserPermissions();
  }

  setIsReady = (isReady) => this.setState({ isReady });

  checkUserPermissions = async () => {
    try {
      if (this.props.location.pathname === '/sign-in')
        return AuthorisationService.signinRedirect();

      let settings = await this._organisationService.getOrganisationSettings();
      this.setState({settings});

      let user = await AuthorisationService.getUser();
      let userHasPermissions = false;
      this.configureGoogleAnalyticsSession(user ? user.profile : null);

      if (user) {
        this._authService.setAuthorisationHeader(user.access_token);
        this.configureRayGunSession(user.profile);
        this.configureActiveCampaignSession(user.profile);

        userHasPermissions = await this._organisationService.checkUserPermissions();
        if (user.expired)
          user = null;

        if (user && !userHasPermissions)
          this.props.history.push('/invalid-user');
      }

      this.setState({user: user ? user.profile : null, userHasPermissions });

      this.setIsReady(true);
    } catch (err) {
      console.log('err', err);
      this.setIsReady(true);
    }
  };

  handleRouteChange = async (location) => {
    try {
      window.rg4js('trackEvent', { type: 'pageView', path: location.pathname });
      ReactGA.set({ page: location.pathname });
      ReactGA.pageview(location.pathname);

      let currentRoute = _.find(Routes, route => _.includes(location.pathname, (route.simplePathName ? route.simplePathName : route.path)) );

      let user = await AuthorisationService.getUser();
      if (!currentRoute.allowAnonymous) {
        if (!user)
          return this.props.history.push('/welcome');

        if (!this.state.userHasPermissions)
          this.props.history.push('/invalid-user');
      }

      if (user.expired) {
        this.setState({ user: null });

        if (!currentRoute.allowAnonymous)
          return this.props.history.push('/session-expired');
      }
    } catch (err) {
    }
  };

  configureRayGunSession = (profile) => {
    window.rg4js('setUser', {
      identifier: profile.sub,
      isAnonymous: false,
      email: profile.email,
      fullName: profile.name
    });
  };

  configureGoogleAnalyticsSession = (profile) => {
    ReactGA.initialize(googleAnalyticsKey, {
      debug: false,
      gaOptions: {
        identifier: profile ? profile.sub : null,
        email: profile ? profile.email : null
      }
    });
  };

  configureActiveCampaignSession = (profile) => {
    (function(e,t,o,n,p,r,i){e.visitorGlobalObjectAlias=n;e[e.visitorGlobalObjectAlias]=e[e.visitorGlobalObjectAlias]||function(){(e[e.visitorGlobalObjectAlias].q=e[e.visitorGlobalObjectAlias].q||[]).push(arguments)};e[e.visitorGlobalObjectAlias].l=(new Date()).getTime();r=t.createElement("script");r.src=o;r.async=true;i=t.getElementsByTagName("script")[0];i.parentNode.insertBefore(r,i)})(window,document,"https://diffuser-cdn.app-us1.com/diffuser/diffuser.js","vgo");

    window.vgo('setAccount', activeCampaignKey);
    window.vgo('setTrackByDefault', true);
    window.vgo('setEmail', profile.email);
    window.vgo('process');
  };

  render () {

    return (
      <UserSessionView>
        { this.state.isReady && React.Children.map(this.state.children, child =>
          React.cloneElement(child, {user: this.state.user, userHasPermissions: this.state.userHasPermissions, settings: this.state.settings })) }
      </UserSessionView>
    );
  }
}

export default withRouter(UserSessionContainer);
