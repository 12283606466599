import React from 'react';
//material
import { Container, Grid, Hidden } from '@material-ui/core';
//components
import DrawnPerRound from '../competition/bracket/drawn-per-round';
import Bracket16 from '../competition/bracket/bracket-16';
import Bracket32 from '../competition/bracket/bracket-32';
import Bracket64 from '../competition/bracket/bracket-64';
import ViewMatch from '../competition/bracket/view-match';

function PublicBracketView (props) {
  return (
    <Container maxWidth={'md'}>
      <Grid container>
        {
          props.bracketMatches.length > 0 && props.details.newDrawPerRound &&
          <Grid item xs={12}>
            <DrawnPerRound
              bracketMatches={props.bracketMatches}
              details={props.details}
              hideEmptyRounds
              viewMatch={props.viewMatch}
              rounds={props.rounds}/>
          </Grid>
        }

        {
          props.bracketMatches.length > 0 && !props.details.newDrawPerRound && props.details.bracketSize === 16 &&
          <Grid item xs={12}>
            <Hidden mdUp>
              <DrawnPerRound
                bracketMatches={props.bracketMatches}
                details={props.details}
                hideEmptyRounds
                viewMatch={props.viewMatch}
                rounds={props.rounds}/>
            </Hidden>
            <Hidden smDown>
              <Bracket16
                elementSize={16}
                bracketMatches={props.bracketMatches}
                details={props.details}
                viewMatch={props.viewMatch}
                rounds={props.rounds}/>
            </Hidden>
          </Grid>
        }

        {
          props.bracketMatches.length > 0 && !props.details.newDrawPerRound && props.details.bracketSize === 32 &&
          <Grid item xs={12}>
            <Hidden mdUp>
              <DrawnPerRound
                bracketMatches={props.bracketMatches}
                details={props.details}
                hideEmptyRounds
                viewMatch={props.viewMatch}
                rounds={props.rounds}/>
            </Hidden>
            <Hidden smDown>
              <Bracket32
                elementSize={12}
                bracketMatches={props.bracketMatches}
                details={props.details}
                viewMatch={props.viewMatch}
                rounds={props.rounds}/>
            </Hidden>
          </Grid>
        }

        {
          props.bracketMatches.length > 0 && !props.details.newDrawPerRound && props.details.bracketSize === 64 &&
          <Grid item xs={12}>
            <Hidden mdUp>
              <DrawnPerRound
                bracketMatches={props.bracketMatches}
                details={props.details}
                hideEmptyRounds
                viewMatch={props.viewMatch}
                rounds={props.rounds}/>
            </Hidden>
            <Hidden smDown>
              <Bracket64
                elementSize={10}
                bracketMatches={props.bracketMatches}
                details={props.details}
                viewMatch={props.viewMatch}
                rounds={props.rounds}/>
            </Hidden>
          </Grid>
        }

        {
          props.showMatchDialog &&
          <ViewMatch
            details={props.details}
            showDialog={props.showMatchDialog}
            handleCloseDialog={props.handleCloseViewMatchDialog}
            match={props.match}
            matchLoading={props.matchLoading}/>
        }
      </Grid>

    </Container>
  )
}

export default PublicBracketView;