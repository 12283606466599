import React from 'react';
import styles from './express-checkout-complete-styles';
//material
import {withStyles, Container, Grid, Card, CardContent, Button} from '@material-ui/core';

function ExpressCheckoutCompleteView (props) {
  const classes = props.classes;

  return (
    <Container maxWidth={'sm'}>
      <Grid item xs={12}>
        <Card className={classes.marginTop20 + ' ' + classes.marginBottom40}>
          <div className={classes.header}>
            Checkout Complete
          </div>

          <CardContent className={classes.card}>
            <Grid container>
              {
                props.hasAccount &&
                <Grid item xs={12} className={classes.textCenter}>
                  { !props.voucherRedeemed ? 'Thank you for your purchase.' : 'Thank you for redeeming your Gift Code.'}
                  <br/><br/>
                  Please {!props.user || props.user.expired ? 'Sign In and ' : ''}go to the Dashboard to complete your registration.
                  <br/><br/>
                </Grid>
              }

              {
                !props.hasAccount &&
                <Grid item xs={12} className={classes.textCenter}>
                  { !props.voucherRedeemed ? 'Thank you for your purchase.' : 'Thank you for redeeming your Gift Code.'}
                  <br/><br/>
                  Please click the button below to register for your account.
                  <br/><br/>
                </Grid>
              }

              <Grid item xs={12} className={classes.marginBottom10 + ' ' + classes.textCenter}>
                <Button
                  color={'primary'}
                  variant={'contained'}
                  onClick={props.nextAction}>
                  { props.hasAccount ? ((props.user && !props.user.expired) ? 'Go to Dashboard' : 'Sign In') : 'Create Your Account' }
                </Button>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Grid>
    </Container>
  );
}

export default withStyles (styles) (ExpressCheckoutCompleteView);
