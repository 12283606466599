import React from 'react';
import styles from '../detailed-bracket-styles';
import _ from 'lodash';
import Moment from 'moment';
//material
import { makeStyles } from '@material-ui/core';
//components
import Match from '../match';
import BracketMatch from '../bracket-match';

const useStyles = makeStyles(styles);

function Bracket64View (props) {
  const classes = useStyles(props);
  let headings = [{name: 'Round 1', number: 1}, {name: 'Round 2', number: 2}, {name:'Round 3', number: 3}, {name:'Quarter-Finals', number:4}, {name:'Semi-Finals', number:5}, {name:'Final', number:6}];
  headings.forEach(h => {
    let round = _.find(props.rounds, r => r.roundNumber === h.number);
    h.deadline = round && round.endDate ? Moment(new Date(round.endDate)).format('Do MMMM YYYY') : ' ';
  });

  return (
    <div className={classes.bracket + ' ' + classes.bracket64}>
      <div className={classes.headerContainer}>
        {
          headings.map((heading, i) =>
            <div key={i} className={classes.columnHeader + ' dark-mode-text'}>
              <div>{heading.name}</div>
              {
                !JSON.parse(process.env.REACT_APP_COMPETITIONGOLF_HIDE_ROUND_DATES) &&
                <div className={classes.columnSubheader}>{heading.deadline}</div>
              }
            </div>)
        }
      </div>

      <div className={classes.round + ' ' + classes.round1}>
        <BracketMatch
          viewMatch={props.viewMatch}
          classes={classes}
          match1Position={1}
          match2Position={3}
          matches={props.bracketMatches}/>

        <BracketMatch
          viewMatch={props.viewMatch}
          classes={classes}
          match1Position={5}
          match2Position={7}
          matches={props.bracketMatches}/>

        <BracketMatch
          viewMatch={props.viewMatch}
          classes={classes}
          match1Position={9}
          match2Position={11}
          matches={props.bracketMatches}/>

        <BracketMatch
          viewMatch={props.viewMatch}
          classes={classes}
          match1Position={13}
          match2Position={15}
          matches={props.bracketMatches}/>

        <BracketMatch
          viewMatch={props.viewMatch}
          classes={classes}
          match1Position={17}
          match2Position={19}
          matches={props.bracketMatches}/>

        <BracketMatch
          viewMatch={props.viewMatch}
          classes={classes}
          match1Position={21}
          match2Position={23}
          matches={props.bracketMatches}/>

        <BracketMatch
          viewMatch={props.viewMatch}
          classes={classes}
          match1Position={25}
          match2Position={27}
          matches={props.bracketMatches}/>

        <BracketMatch
          viewMatch={props.viewMatch}
          classes={classes}
          match1Position={29}
          match2Position={31}
          matches={props.bracketMatches}/>

        <BracketMatch
          viewMatch={props.viewMatch}
          classes={classes}
          match1Position={2}
          match2Position={4}
          matches={props.bracketMatches}/>

        <BracketMatch
          viewMatch={props.viewMatch}
          classes={classes}
          match1Position={6}
          match2Position={8}
          matches={props.bracketMatches}/>

        <BracketMatch
          viewMatch={props.viewMatch}
          classes={classes}
          match1Position={10}
          match2Position={12}
          matches={props.bracketMatches}/>

        <BracketMatch
          viewMatch={props.viewMatch}
          classes={classes}
          match1Position={14}
          match2Position={16}
          matches={props.bracketMatches}/>

        <BracketMatch
          viewMatch={props.viewMatch}
          classes={classes}
          match1Position={18}
          match2Position={20}
          matches={props.bracketMatches}/>

        <BracketMatch
          viewMatch={props.viewMatch}
          classes={classes}
          match1Position={22}
          match2Position={24}
          matches={props.bracketMatches}/>

        <BracketMatch
          viewMatch={props.viewMatch}
          classes={classes}
          match1Position={26}
          match2Position={28}
          matches={props.bracketMatches}/>

        <BracketMatch
          viewMatch={props.viewMatch}
          classes={classes}
          match1Position={30}
          match2Position={32}
          matches={props.bracketMatches}/>
      </div>
      <div className={classes.round + ' ' + classes.round2}>
        <BracketMatch
          viewMatch={props.viewMatch}
          classes={classes}
          match1Position={33}
          match2Position={35}
          matches={props.bracketMatches}/>

        <BracketMatch
          viewMatch={props.viewMatch}
          classes={classes}
          match1Position={37}
          match2Position={39}
          matches={props.bracketMatches}/>

        <BracketMatch
          viewMatch={props.viewMatch}
          classes={classes}
          match1Position={41}
          match2Position={43}
          matches={props.bracketMatches}/>

        <BracketMatch
          viewMatch={props.viewMatch}
          classes={classes}
          match1Position={45}
          match2Position={47}
          matches={props.bracketMatches}/>

        <BracketMatch
          viewMatch={props.viewMatch}
          classes={classes}
          match1Position={34}
          match2Position={36}
          matches={props.bracketMatches}/>

        <BracketMatch
          viewMatch={props.viewMatch}
          classes={classes}
          match1Position={38}
          match2Position={40}
          matches={props.bracketMatches}/>

        <BracketMatch
          viewMatch={props.viewMatch}
          classes={classes}
          match1Position={42}
          match2Position={44}
          matches={props.bracketMatches}/>

        <BracketMatch
          viewMatch={props.viewMatch}
          classes={classes}
          match1Position={46}
          match2Position={48}
          matches={props.bracketMatches}/>
      </div>
      <div className={classes.round + ' ' + classes.round3}>
        <BracketMatch
          viewMatch={props.viewMatch}
          classes={classes}
          match1Position={49}
          match2Position={51}
          matches={props.bracketMatches}/>
        <BracketMatch
          viewMatch={props.viewMatch}
          classes={classes}
          match1Position={53}
          match2Position={55}
          matches={props.bracketMatches}/>
        <BracketMatch
          viewMatch={props.viewMatch}
          classes={classes}
          match1Position={50}
          match2Position={52}
          matches={props.bracketMatches}/>
        <BracketMatch
          viewMatch={props.viewMatch}
          classes={classes}
          match1Position={54}
          match2Position={56}
          matches={props.bracketMatches}/>
      </div>
      <div className={classes.round + ' ' + classes.round4}>
        <BracketMatch
          viewMatch={props.viewMatch}
          classes={classes}
          match1Position={57}
          match2Position={59}
          matches={props.bracketMatches}/>
        <BracketMatch
          viewMatch={props.viewMatch}
          classes={classes}
          match1Position={58}
          match2Position={60}
          matches={props.bracketMatches}/>
      </div>
      <div className={classes.round + ' ' + classes.round5}>
        <BracketMatch
          viewMatch={props.viewMatch}
          classes={classes}
          match1Position={61}
          match2Position={62}
          matches={props.bracketMatches}/>
      </div>
      <div className={classes.round}>
        <div className={classes.bracketMatches}>
          <div className={classes.matchups}>
            <Match
              viewMatch={props.viewMatch}
              classes={classes}
              matchPosition={63}
              matches={props.bracketMatches}
              isSingle/>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Bracket64View;