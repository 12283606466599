import React from 'react';
//material
import {Card, withStyles, CardMedia, CardContent, Button, CardActionArea, CardActions} from '@material-ui/core';
import {Skeleton} from '@material-ui/lab';
//styles
import styles from './event-item-styles';
//library
import Moment from 'moment';
import { withRouter } from 'react-router-dom';

let bookingUrl = process.env.REACT_APP_COMPETITIONGOLF_TEMP_BOOKING_URL;
bookingUrl = (!bookingUrl.startsWith('#{') && !bookingUrl.endsWith('}#')) ? bookingUrl : null;

const EventItemView = (props) => {
  const {classes, event, isLoading, history} = props;
  const navigate = () =>
    history.push(`event/${event.eventId}`);

  return (
    <Card>
      <CardActionArea disableRipple={isLoading} onClick={!isLoading ? navigate : null}>
        {
          isLoading ?
            <Skeleton variant={'rect'} height={165} /> :
            <CardMedia
              title={'Event Image'}
              image={event.imageUrl}
              className={classes.img}
            />
        }

        <CardContent className={classes.cardContent}>
          {
            isLoading ?
              <Skeleton height={24}/> :
              <div className={classes.name}>
                {event.eventName}
              </div>
          }

          {
            isLoading ?
              <Skeleton height={12} width={'40%'} /> :
              <div className={classes.subtitle}>
                {event.eventDate ? Moment(new Date(event.eventDate)).format('Do MMMM YYYY') : ''}
              </div>
          }

          {
            isLoading ?
              <Skeleton height={12} width={'40%'} /> :
              <div className={classes.subtitle}>
                {event.course}
              </div>
          }
        </CardContent>
      </CardActionArea>

      <CardActions className={classes.actions}>
        {
          !isLoading && event.canBook &&
          <Button
            className={classes.bookButton}
            color={'primary'}
            size={'small'}
            aria-label={'small'}
            variant={'contained'}
            href={bookingUrl}
            target={'_blank'}
            rel={'noopener'}>
            Book
          </Button>
        }

        {
          isLoading ?
            <Skeleton height={16} width={'25%'} className={classes.skeletonCurrency}/> :
            <div className={classes.currency}>
              {
                event.entryAmount ?
                  (event.currencySymbol ? event.currencySymbol : '£') + event.entryAmount.toFixed(2) :
                  ' '
              }
            </div>
        }
      </CardActions>
    </Card>
  );
}

export default withRouter(withStyles(styles) (EventItemView));
