const EventsStyles = theme => ({
  searchContainer: {
    margin: '0 auto',
    paddingBottom: 40
  },
  noEvents: {
    fontSize: '20px',
    width: '100%',
    textAlign: 'center',
    marginTop: 60,
  },
});

export default EventsStyles;
