import React from 'react';
//components
import View from './avatar-editor-view';

class AvatarEditorContainer extends React.Component {
  constructor (props) {
    super (props);

    this.state = {
      scale: 1,
    };
  }

  setEditorRef = (editor) => this.editor = editor;

  handleSliderChange = (e, scale) => this.setState({ scale });

  handleImageChange = () => {
    if (this.editor && this.editor.getImageScaledToCanvas())
      this.props.onImageChange(this.editor.getImageScaledToCanvas().toDataURL('image/jpeg').substr(23));
  };

  render () {
    return (
      <View
        scale={this.state.scale}
        height={this.props.height}
        width={this.props.width}
        setEditorRef={this.setEditorRef}
        handleSliderChange={this.handleSliderChange}
        image={this.props.image}
        handleImageChange={this.handleImageChange}
        commitOnChangeOnly={this.props.commitOnChangeOnly}/>
    );
  }
}

export default AvatarEditorContainer;