import React from 'react';
import styles from './select-multiple-competitions-styles';
import Moment from 'moment';
import { withRouter } from 'react-router-dom';
//material
import { Dialog, DialogTitle, DialogContent, DialogActions, List, ListItem, ListItemText, ListItemIcon, ListItemSecondaryAction, Checkbox, withStyles, Button } from '@material-ui/core';

function SelectMultipleCompetitions (props) {
  const classes = props.classes;
  const [checked, setChecked] = React.useState([]);

  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  return (
    <Dialog
      maxWidth={'sm'}
      fullWidth
      open={props.showDialog}>
      <DialogTitle
        classes={{ root: classes.dialogHeader }}>
        Please select the Competitions you would like to register for:
      </DialogTitle>
      <DialogContent
        classes={{ root: classes.dialogContentRoot }}>
        <List>
          {
            props.competitions.map((competition, key) =>
              <ListItem key={key} button onClick={handleToggle(competition)}>
                <ListItemIcon>
                  <Checkbox
                    edge="start"
                    checked={checked.indexOf(competition) !== -1}
                    tabIndex={-1}
                    disableRipple
                    color={'primary'}/>
                </ListItemIcon>
                <ListItemText primary={competition.name} secondary={Moment(new Date(competition.date)).format('Do MMMM YYYY')}/>
                <ListItemSecondaryAction>
                  <ListItemText primary={competition.entryAmount ? (competition.currencySymbol + competition.entryAmount) : ''}/>
                </ListItemSecondaryAction>
              </ListItem>)
          }
        </List>
      </DialogContent>
      <DialogActions
        classes={{ root: classes.dialogActions }}>
        <Button onClick={() => props.history.push('/welcome')}>Cancel</Button>
        <Button color={'primary'} disabled={!checked.length} onClick={() => props.selectCompetitions(checked)}>
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default withStyles(styles) (withRouter(SelectMultipleCompetitions));
