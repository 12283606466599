import React from 'react';
//material
import {Container, withStyles, Grid} from '@material-ui/core';
//styles
import styles from './event-styles';
//components
import EventCard from '../../../components/events/event-card';
import DetailsCard from '../../../components/events/details-card';
import AgendaCard from '../../../components/events/agenda-card';
import PrizesCard from '../../../components/events/prizes-card';

const EventView = (props) => {
  const {classes, isLoading, event, agendaItems, prizes} = props;

  return (
    <div>
      <div style={{backgroundImage: `url(${!isLoading ? event.imageUrl : ''})`}} className={classes.heroImage}/>

      <Container maxWidth={'md'} className={classes.container}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <EventCard
              isLoading={isLoading}
              event={event}/>
          </Grid>

          <Grid item xs={12} md={6}>
            <DetailsCard
              isLoading={isLoading}
              event={event}/>
          </Grid>

          <Grid item xs={12} md={6}>
            <AgendaCard
              isLoading={isLoading}
              agendaItems={agendaItems}/>
          </Grid>

          {
            !isLoading && prizes.prizes.length > 0 &&
            <Grid item xs={12} md={6}>
              <PrizesCard
                isLoading={isLoading}
                prizes={prizes}/>
            </Grid>
          }
        </Grid>
      </Container>
    </div>
  )
};

export default withStyles(styles) (EventView);
