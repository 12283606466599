import React from 'react';
//material
import {Card, CardContent, withStyles} from '@material-ui/core';
import {Skeleton} from '@material-ui/lab';
//styles
import styles from './agenda-card-styles';
//lib
import Moment from 'moment';

const AgendaCardView = (props) => {
  const {classes, isLoading, agendaItems} = props;
  return (
    <Card>
      <CardContent>
        {
          isLoading ?
            <Skeleton height={40} width={'60%'} /> :
            <div className={classes.title}>
              Agenda
            </div>
        }

        {
          isLoading ?
            <div className={classes.skeletonTable}>
              <Skeleton height={23} className={classes.skeletonRow}/>
              <Skeleton height={23} className={classes.skeletonRow}/>
              <Skeleton height={23} className={classes.skeletonRow}/>
              <Skeleton height={23} className={classes.skeletonRow}/>
              <Skeleton height={23} className={classes.skeletonRow}/>
            </div> :
            <table className={classes.table}>
              <tbody>
              {
                agendaItems.map((a, key) =>
                  (
                    <tr key={key}>
                      <td className={classes.column}>{Moment(a.itemTime).format('HH:mm')}</td>
                      <td className={classes.data}>{a.description}</td>
                    </tr>
                  ))
              }
              </tbody>
            </table>
        }

        {
          !isLoading && agendaItems.length === 0 &&
            <div className={classes.defaultText}>
              Agenda coming soon
            </div>
        }
      </CardContent>
    </Card>
  );
};

export default withStyles(styles) (AgendaCardView);
