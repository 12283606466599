import React from 'react';
import _ from 'lodash';
//components
import View from './members-view';
//utils
import { OrganisationService } from '../../../utils/data-transfer';

class MembersContainer extends React.Component {
  constructor(props) {
    super(props);

    this._organisationService = new OrganisationService();

    this.state = {
      members: [],
      pinnedMembers: [],
      filterText: '',
      isLoading: true
    };

    this.debounceSearchMembers = _.debounce(this.getMembers, 1000);
  }

  componentDidMount() {
    this.getMembers();
  }

  getMembers = async () => {
    try {
      let members = await this._organisationService.getMembers(this.state.filterText);
      const pinnedMembers = members.filter(m => m.memberType.showSeparate);
      members = members.filter(m => !m.memberType.showSeparate);

      this.setState({ members, pinnedMembers, isLoading: false });
    } catch (err) {

    }
  };

  handleSearchChange = (e) => {
    this.setState({filterText: e.target.value});
    return this.debounceSearchMembers();
  };

  render() {
    return (
      <View
        isLoading={this.state.isLoading}
        members={this.state.members}
        pinnedMembers={this.state.pinnedMembers}
        filterText={this.state.filterText}
        handleSearchChange={this.handleSearchChange}/>
    );
  }
}

export default MembersContainer;
