import React from 'react';
import styles from './tell-a-friend-styles';
//material
import {Container, Grid, IconButton, withStyles} from '@material-ui/core';
import { Facebook, LinkedIn, Twitter } from '@material-ui/icons';

function TellAFriendView (props) {
  const classes = props.classes;

  const navigate = (location) => window.open(location, '_blank');

  return (
    <Container maxWidth={'sm'}>
      <Grid container>
        <Grid item xs={12}>
          {/*<Card>*/}
          {/*  <div className={classes.header}>*/}
          {/*    Tell a Friend*/}
          {/*  </div>*/}

          {/*  <CardContent className={classes.registrationCard}>*/}
          {/*    <Grid item xs={12}>*/}
          {/*      <IconButton*/}
          {/*        className={classes.facebookButton}*/}
          {/*        onClick={() => navigate('https://www.facebook.com/sharer/sharer.php?u='+encodeURIComponent(process.env.REACT_APP_COMPETITIONGOLF_TELL_A_FRIEND_LINK))}>*/}
          {/*        <Facebook/>*/}
          {/*      </IconButton>*/}
          {/*      <IconButton*/}
          {/*        className={classes.twitterButton}*/}
          {/*        onClick={() => navigate('https://twitter.com/share?url='+encodeURIComponent(process.env.REACT_APP_COMPETITIONGOLF_TELL_A_FRIEND_LINK))}>*/}
          {/*        <Twitter/>*/}
          {/*      </IconButton>*/}
          {/*      <IconButton*/}
          {/*        className={classes.linkedInButton}*/}
          {/*        onClick={() => navigate('https://www.linkedin.com/sharing/share-offsite/?url='+encodeURIComponent(process.env.REACT_APP_COMPETITIONGOLF_TELL_A_FRIEND_LINK))}>*/}
          {/*        <LinkedIn/>*/}
          {/*      </IconButton>*/}
          {/*    </Grid>*/}
          {/*  </CardContent>*/}
          {/*</Card>*/}

          <div className={classes.header}>
            Tell a Friend
          </div>

          <Grid item xs={12} className={classes.buttonContainer}>
            <IconButton
              className={classes.facebookButton}
              onClick={() => navigate('https://www.facebook.com/sharer/sharer.php?u=' + encodeURIComponent(process.env.REACT_APP_COMPETITIONGOLF_TELL_A_FRIEND_LINK))}>
              <Facebook/>
            </IconButton>
            <IconButton
              className={classes.twitterButton}
              onClick={() => navigate('https://twitter.com/share?url=' + encodeURIComponent(process.env.REACT_APP_COMPETITIONGOLF_TELL_A_FRIEND_LINK))}>
              <Twitter/>
            </IconButton>
            <IconButton
              className={classes.linkedInButton}
              onClick={() => navigate('https://www.linkedin.com/sharing/share-offsite/?url=' + encodeURIComponent(process.env.REACT_APP_COMPETITIONGOLF_TELL_A_FRIEND_LINK))}>
              <LinkedIn/>
            </IconButton>
          </Grid>
        </Grid>
      </Grid>
    </Container>
  )
}

export default withStyles(styles) (TellAFriendView);
