import CoreStyles from '../../utils/styles/core-styles';

const DashboardStyles = theme => ({
  gridItem: {
    padding: [10, 5]
  },
  skeletonContainer: {
    height: 125,
    padding: 10,
    position: 'relative',
    marginBottom: 20
  },
  skeletonButton: {
    right: 10,
    bottom: 10,
    position: 'absolute'
  },
  emptyListView: {
    fontSize: '20px',
    textAlign: 'center',
    marginTop: 60,
    width: '100%'
  },
  gridContainer: {
    padding: '20px 0'
  },
  toggleButton: {
    minWidth: '90px'
  },
  ...CoreStyles(theme)
});

export default DashboardStyles;