import React from 'react';
//components
import Match from '../match';

function BracketMatchView (props) {
  const classes = props.classes;

  return (
    <div className={classes.bracketMatches}>
      <div className={classes.matchups}>
        <Match
          elementSize={props.elementSize}
          classes={classes}
          matchPosition={props.match1Position}
          matches={props.matches}
          viewMatch={props.viewMatch}/>

        <Match
          elementSize={props.elementSize}
          classes={classes}
          matchPosition={props.match2Position}
          matches={props.matches}
          viewMatch={props.viewMatch}/>
      </div>

      <div className={classes.connector}>
        <div className={classes.merger}/>
        <div className={classes.line}/>
      </div>
    </div>
  );
}

export default BracketMatchView;