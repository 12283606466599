import { UserManager, WebStorageStateStore } from 'oidc-client';
import Axios from 'axios';

class AuthorisationService {
  constructor () {
    AuthorisationService._userManager = new UserManager(oidcConfiguration);

    AuthorisationService._userManager.events.removeUserLoaded();
    AuthorisationService._userManager.events.addUserLoaded(this.handleSilentRenewEvent);
  }

  handleSilentRenewEvent = async () => {
    try {
      let user = await AuthorisationService.getUser();
      this.setAuthorisationHeader(user.access_token);
    } catch (err) {
      console.log(err);
    }
  };

  static getUser = () => {
    return AuthorisationService._userManager.getUser();
  };

  static signinRedirect = () => {
    AuthorisationService._userManager.signinRedirect();
  };

  static signoutRedirect = () => {
    AuthorisationService._userManager.signoutRedirect();
  };

  static changePassword = () => {
    window.open(process.env.REACT_APP_COMPETITIONGOLF_AUTHORITY + '/changepassword?returnurl=' + window.location.protocol + '//' + window.location.host);
  };

  setAuthorisationHeader = (accessToken) => {
    Axios.defaults.headers.common['Authorization'] = 'Bearer ' + accessToken;
  };

  registerAuthorisationInterceptor = (history) => {
    Axios.interceptors.response.use((response) => {return response;},
      (error) => {
        if (error.response.status === 401) {
          history.push('/unauthorised');
          return Promise.reject(error);
        }

        return Promise.reject(error);
      });
  };

  getSignInStatus = () => {
    return AuthorisationService._userManager.querySessionStatus();
  };
}

const oidcConfiguration = {
  authority: process.env.REACT_APP_COMPETITIONGOLF_AUTHORITY,
  client_id: process.env.REACT_APP_COMPETITIONGOLF_CLIENT_ID,
  redirect_uri: 'https://' + window.location.host + '/callback.html',
  response_type: 'id_token token',
  scope: process.env.REACT_APP_COMPETITIONGOLF_SCOPE,
  post_logout_redirect_uri: 'https://' + window.location.host,
  silent_redirect_uri: 'https://' + window.location.host + '/silent-renew.html',
  automaticSilentRenew: true,
  userStore: new WebStorageStateStore({ store: localStorage})
};

export default AuthorisationService;
