import React from 'react';
import styles from './team-styles';
//material
import { Card, CardContent, Typography, withStyles, Button } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';

function TeamView (props) {
  const classes = props.classes;

  return (
    <Card>
      <CardContent className={classes.cardContent}>
        <Typography className={classes.cardHeader}>{props.isSingles ? 'Player Details' : 'Team Details'}</Typography>
        {
          props.isWizardView &&
            <Button
              className={classes.wizardEdit}
              disableElevation
              color={'primary'}
              variant={'contained'}
              size={'small'}
              onClick={props.handleWizardEditClick}
              disabled={props.isLoading}>
              edit
            </Button>
        }

        <div className={classes.cardBodyPadding}>
          {
            !props.isLoading ?
              <Typography className={classes.cardBar}>{props.isSingles ? 'Player Name' : 'Team Name'}: {props.details.pairName ? props.details.pairName : '-'}</Typography> :
              <Skeleton height={25}/>
          }

          {
            !props.isLoading ?
              <Typography className={classes.cardBar}>Home Club: {props.details.clubName ? props.details.clubName : '-'}</Typography> :
              <Skeleton height={25}/>
          }
        </div>

      </CardContent>
    </Card>
  );
}

export default withStyles(styles) (TeamView);