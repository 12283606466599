import React from 'react';
//component
import CompetitionView from './competition-view';
//utils
import { CompetitionService, ClubService } from '../../utils/data-transfer';

class CompetitionContainer extends React.Component {
  constructor (props) {
    super (props);

    this.state = {
      tab: 0,
      isError: false,
      details: { leadPlayer: {}, additionalPlayer: {}, teamPlayStatus: {}, nextOpponent: {} },
      viewEnterResults: false,
      results: [],
      bracketMatches: [],
      resultsEntry: defaultResultsEntry(),
      clubs: [],
      resultsSubmissionLoading: false,
      isLoading: true,
      bracketHash: '',
      rounds: []
    };

    this._competitionService = new CompetitionService();
    this._clubService = new ClubService();
  }

  intervalIteration = 0;

  handleTabChange = (e, tab) => {
    this.setState({tab});
  };

  handleShowEnterResults = (viewEnterResults) => {
    this.setState({ viewEnterResults });
  };

  handleDateChange = (momentDate) => this.setState({resultsEntry: Object.assign({}, this.state.resultsEntry, {matchDate: momentDate ? momentDate.toDate() : ''})});

  handleClubChange = (clubId) => this.setState({resultsEntry: Object.assign({}, this.state.resultsEntry, {clubId})});

  handleResultChange = (e) => this.setState({resultsEntry: Object.assign({}, this.state.resultsEntry, {[e.target.name]: e.target.value})});

  setLoading = (isLoading) => this.setState({ isLoading});

  componentDidMount () {
    this._isMounted = true;
    return this.getCompetition();
  }

  componentWillUnmount () {
    this._isMounted = false;
    if (this.interval)
      clearInterval(this.interval);
  }

  getCompetition = async () => {
    try {
      await Promise.all([await this.getCompetitionDetails(), await this.getResults(), await this.getRounds()]);

      let bracket = null;
      if (this.state.details.bracketId && !this.state.details.hideBracketsInMatchplay && this.state.details.isDrawn)
        bracket = (await this._competitionService.getCompetitionBracket(this.props.competitionId, this.state.details.bracketId, this.state.details.competitionTeamId));

      this.setState({ bracketMatches: bracket ? bracket.matches : [], bracketHash: bracket ? bracket.hash : '', isLoading: false });
    } catch (err) {
      console.log(err);
      this.setState({isError: true});
    }
  };

  getCompetitionDetails = async () => {
    try {
      let details = await this._competitionService.getUserCompetitionDetails(this.props.competitionId, this.props.competitionPlayerId);

      if (!this._isMounted)
        return;

      this.setState({details, isError: false});
    } catch (err) {
      this.setState({isError: true});
    }
  };

  getResults = async () => {
    try {
      let results = await this._competitionService.getUserCompetitionResults(this.props.competitionId, this.props.competitionPlayerId);

      if (!this._isMounted)
        return;

      this.setState({results, isError: false});
    } catch (err) {
      this.setState({isError: true});
    }
  };

  getRounds = async () => {
    try {
      let rounds = await this._competitionService.getCompetitionRounds(this.props.competitionId);

      if (!this._isMounted)
        return;

      this.setState({rounds, isError: false});
    } catch (err) {
      this.setState({isError: true});
    }
  }

  getClubsMatchingQuery = async (queryString) => {
    try {
      let clubs = await this._clubService.getAnonymousFilteredClubs(queryString);

      this.setState({ clubs });
    } catch (err) {
      console.log(err);
      //handle this!
    }
  };

  handleResultsSubmission = async () => {
    try {
      this.setState({ resultsSubmissionLoading: true });

      await this._competitionService.setMatchResults(this.props.competitionId, this.state.details.competitionMatchId, this.state.resultsEntry);

      await this.getCompetitionDetails();

      this.setState({ resultsSubmissionLoading: false, resultsEntry: defaultResultsEntry() });
      this.handleShowEnterResults(false);

      this.interval = setInterval(async () => {
        if (!this.state.details.newDrawPerRound) {
          let bracket = await this._competitionService.getCompetitionBracket(this.props.competitionId, this.state.details.bracketId, this.state.details.competitionTeamId);
          if (bracket.hash !== this.state.bracketHash) {
            clearInterval(this.interval);
            await this.getCompetition();
          } else {
            await this.getCompetition();
            this.intervalIteration++;

            if (this.intervalIteration > 1) {
              this.intervalIteration = 0;
              clearInterval(this.interval);
            }
          }
        }
      }, 3000);
    } catch (err) {
      console.log(err);
      this.props.history.push('/error?errorMessage=An unexpected error occurred when entering your results.\nPlease try again shortly.\nIf this error persists please Contact Us.');
    }
  };

  render () {
    return (
      <CompetitionView
        competitionId={this.props.competitionId}
        handleTabChange={this.handleTabChange}
        tab={this.state.tab}
        details={this.state.details}
        results={this.state.results}
        viewEnterResults={this.state.viewEnterResults}
        handleShowEnterResults={this.handleShowEnterResults}
        resultsEntry={this.state.resultsEntry}
        handleDateChange={this.handleDateChange}
        getClubsMatchingQuery={this.getClubsMatchingQuery}
        clubs={this.state.clubs}
        handleClubChange={this.handleClubChange}
        handleResultChange={this.handleResultChange}
        handleResultsSubmission={this.handleResultsSubmission}
        resultsSubmissionLoading={this.state.resultsSubmissionLoading}
        getCompetition={this.getCompetition}
        bracketMatches={this.state.bracketMatches}
        isLoading={this.state.isLoading}
        setLoading={this.setLoading}
        rounds={this.state.rounds}/>
    )
  }
}

const defaultResultsEntry = () => {
  return {
    matchDate: new Date(),
    clubId: '',
    winningTeamId: '',
    score: ''
  }
};

export default CompetitionContainer;