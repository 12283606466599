import React from 'react';
import styles from '../registration-styles';
//material
import { Card, CardContent, Grid, withStyles, Button } from '@material-ui/core';
import { CreditCard } from '@material-ui/icons';

function PaymentView (props) {
  const classes = props.classes;

  return (
    <Card>
      <div className={classes.slideHeader}>
        Payment
      </div>

      <CardContent>
        <p className={classes.slideInfo}>
          We use Stripe to process our payments. We do not store your card details on our system.
        </p>

        <Grid container>
          {/*{*/}
            {/*props.stripeIntent &&*/}
            {/*<Grid item xs={12} className={classes.marginBottom20}>*/}
              {/*Amount to Pay { getCurrencyDisplay(props.stripeIntent.currency) }{ (props.stripeIntent.amount / 100).toFixed(2) }*/}
            {/*</Grid>*/}

          {/*}*/}

          <Grid item xs={12} sm={6} md={4} className={classes.textCenter}>
            <Button classes={{ label: classes.paymentButton }} onClick={props.handleStripeCheckout}>
              <div>
                <CreditCard className={classes.paymentIcon}/>
              </div>
              <div>Credit/ Debit Card</div>
            </Button>
          </Grid>
        </Grid>

        <Grid container>
          <div className={classes.paymentError}>
            { props.paymentError }
          </div>
        </Grid>

        {/*{*/}
          {/*props.showStripe &&*/}
            {/*<Elements stripe={stripePromise}>*/}
              {/*<Stripe*/}
                {/*showStripe={props.showStripe}*/}
                {/*handleSetShowStripe={props.handleSetShowStripe}*/}
                {/*stripeIntent={props.stripeIntent}/>*/}
            {/*</Elements>*/}
        {/*}*/}
      </CardContent>
    </Card>
  )
}

export default withStyles(styles) (PaymentView);