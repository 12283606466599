import React, {useEffect} from 'react';
import styles from '../registration-styles';
//material
import { withStyles, Card, CardContent, Grid, TextField, Button, MenuItem, Avatar, Tooltip } from '@material-ui/core';

let helpLink = process.env.REACT_APP_COMPETITIONGOLF_HELP_URL;
helpLink = (!helpLink.startsWith('#{') && !helpLink.endsWith('}#')) ? helpLink : '';

function LeadPlayerView (props) {
  const classes = props.classes;

  useEffect(() => {
    const isStepComplete = () => props.registration.leadPlayerFirstName && props.registration.leadPlayerLastName && props.registration.leadPlayerEmail && props.registration.leadPlayerEmail.match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/) && props.registration.leadPlayerHandicap !== '' &&
      (!props.registration.addressId || (props.registration.addressLine1 && props.registration.town && props.registration.countyId !== '' && props.registration.postCode)) &&
      (!props.registrationSettings.leadPlayerTelephoneRequired || props.registration.leadPlayerPhone);
    const stepCompleted = isStepComplete();

    if (stepCompleted && !props.isStepValid)
      props.setStepValid(true);

    if (!stepCompleted && props.isStepValid)
      props.setStepValid(false);
  });

  return (
    <Card>
      <div className={classes.slideHeader}>
        Lead Player
      </div>

      <CardContent>
        {
          helpLink &&
          <a
            className={classes.helpButton}
            href={helpLink}
            target={'_blank'}
            rel={'noopener'}>
            need help?
          </a>
        }

        <p className={classes.slideInfo}>
          Please enter the details of the lead player. The Lead Player is the main point of contact.
        </p>

        <Grid container className={classes.componentGrid} justify="center">
          <Grid item xs={12} md={10}>
            <Grid container spacing={2}>
              <Grid className={classes.textFieldSpacing} item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label={'First Name'}
                  value={props.registration.leadPlayerFirstName}
                  name={'leadPlayerFirstName'}
                  onChange={props.handleUpdateRegistration}
                  variant={'outlined'}/>
              </Grid>

              <Grid className={classes.textFieldSpacing} item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label={'Last Name'}
                  value={props.registration.leadPlayerLastName}
                  name={'leadPlayerLastName'}
                  onChange={props.handleUpdateRegistration}
                  variant={'outlined'}/>
              </Grid>

              <Grid className={classes.textFieldSpacing} item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label={'Email Address'}
                  value={props.registration.leadPlayerEmail}
                  name={'leadPlayerEmail'}
                  onChange={props.handleUpdateRegistration}
                  variant={'outlined'}
                  type={'email'}/>
              </Grid>

              <Grid className={classes.textFieldSpacing} item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label={'Telephone ' + ( props.registrationSettings.leadPlayerTelephoneRequired ? '' : '(Optional)')}
                  value={props.registration.leadPlayerPhone}
                  name={'leadPlayerPhone'}
                  onChange={props.handleUpdateRegistration}
                  variant={'outlined'}
                  type={'tel'}/>
              </Grid>

              <Grid className={classes.textFieldSpacing} item xs={12} sm={6}>
                <Tooltip title={'Please enter your WHS handicap index.'}>
                  <TextField
                    fullWidth
                    label={'Handicap Index'}
                    placeholder={'(ex: 10.7)'}
                    value={props.registration.leadPlayerHandicap}
                    name={'leadPlayerHandicap'}
                    onChange={props.handleUpdateRegistration}
                    variant={'outlined'}
                    type={'number'}/>
                </Tooltip>
              </Grid>

              <Grid className={classes.textFieldSpacing} item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label={'Handicap Number (Optional)'}
                  value={props.registration.leadPlayerHandicapNumber}
                  name={'leadPlayerHandicapNumber'}
                  onChange={props.handleUpdateRegistration}
                  variant={'outlined'}/>
              </Grid>

              {
                props.registration.addressId &&
                <Grid className={classes.textFieldSpacing} item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label={'Address Line 1'}
                    value={props.registration.addressLine1}
                    name={'addressLine1'}
                    onChange={props.handleUpdateRegistration}
                    variant={'outlined'}/>
                </Grid>
              }

              {
                props.registration.addressId &&
                <Grid className={classes.textFieldSpacing} item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label={'Address Line 2 (Optional)'}
                    value={props.registration.addressLine2}
                    name={'addressLine2'}
                    onChange={props.handleUpdateRegistration}
                    variant={'outlined'}/>
                </Grid>
              }

              {
                props.registration.addressId &&
                <Grid className={classes.textFieldSpacing} item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label={'Town'}
                    value={props.registration.town}
                    name={'town'}
                    onChange={props.handleUpdateRegistration}
                    variant={'outlined'}/>
                </Grid>
              }

              {
                props.registration.addressId &&
                <Grid className={classes.textFieldSpacing} item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label={'County'}
                    value={props.registration.countyId}
                    name={'countyId'}
                    onChange={props.handleUpdateRegistration}
                    variant={'outlined'}
                    select>
                    {
                      props.counties.map((county, index) => (<MenuItem key={index} value={county.id}>{county.displayName}</MenuItem>))
                    }
                    <MenuItem style={{display: 'none'}}/>
                  </TextField>
                </Grid>
              }

              {
                props.registration.addressId &&
                <Grid className={classes.textFieldSpacing} item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label={'Post Code'}
                    value={props.registration.postCode}
                    name={'postCode'}
                    onChange={props.handleUpdateRegistration}
                    variant={'outlined'}/>
                </Grid>
              }

            </Grid>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  )
}

export default withStyles(styles) (LeadPlayerView);
