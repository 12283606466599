import React from 'react';
//material
import {
  Container,
  Grid,
  Tabs,
  Tab,
  withStyles,
  Card,
  CardContent,
  Avatar,
} from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import { Phone, Email, Facebook, LinkedIn, Language, Twitter } from '@material-ui/icons';
//styles
import styles from './show-member-styles'

const ShowMemberView = (props) => {
  const {classes, member, isLoading, tab, handleTabChange} = props;

  return(
    <Container maxWidth={'md'} classes={{ root: classes.containerPadding }}>
      <Card>
        <CardContent>
          <Grid container>
            <Grid item xs={12} sm={4} className={classes.grid}>
              {
                isLoading ?
                  <Skeleton height={125} width={125} variant={'circle'} className={classes.avatarSkeleton} /> :
                  <Avatar src={member.photoUrl} className={classes.avatar} />
              }
            </Grid>

            <Grid item xs={12} sm={4} className={classes.grid}>
              {
                isLoading ?
                  <Skeleton width={'90%'} height={40} /> :
                  <div className={classes.memberName}>{member.firstName + ' ' + member.lastName}</div>
              }

              {
                isLoading ?
                  <Skeleton width={'70%'} height={30} /> :
                  <div className={classes.memberDetails}>{member.companyName}</div>
              }

              {
                isLoading ?
                  <Skeleton width={'70%'} height={30} /> :
                  <div className={classes.memberDetails}>{'HCP Index: ' + member.handicap}</div>
              }
            </Grid>

            <Grid item xs={12} sm={4} className={classes.grid}>
              {
                isLoading ?
                  <Skeleton height={25} width={'90%'}/> :
                  (
                    member.phoneNumber &&
                    <div className={classes.socialDetails}>
                      <div className={classes.socialIconContainer}><Phone className={classes.socialIcon}/>&nbsp;</div>
                      <div className={classes.socialDetailsContainer}><a href={'tel:' + member.phoneNumber}>{member.phoneNumber}</a></div>
                    </div>
                  )
              }

              {
                isLoading ?
                  <Skeleton height={25} width={'90%'}/> :
                  (
                    member.emailAddress &&
                    <div className={classes.socialDetails}>
                      <div className={classes.socialIconContainer}><Email className={classes.socialIcon}/>&nbsp;</div>
                      <div className={classes.socialDetailsContainer}><a href={'mailto:' + member.emailAddress}>{member.emailAddress}</a></div>
                    </div>
                  )
              }

              {
                isLoading ?
                  <Skeleton height={25} width={'90%'}/> :
                  (
                    member.facebookProfileUrl &&
                    <div className={classes.socialDetails}>
                      <div className={classes.socialIconContainer}><Facebook className={classes.socialIcon}/>&nbsp;</div>
                      <div className={classes.socialDetailsContainer}><a href={member.facebookProfileUrl} target={'_blank'} rel={'noopener'}>View on Facebook</a></div>
                    </div>
                  )
              }

              {
                isLoading ?
                  <Skeleton height={25} width={'90%'}/> :
                  (
                    member.linkedInProfileUrl &&
                    <div className={classes.socialDetails}>
                      <div className={classes.socialIconContainer}><LinkedIn className={classes.socialIcon}/>&nbsp;</div>
                      <div className={classes.socialDetailsContainer}><a href={member.linkedInProfileUrl} target={'_blank'} rel={'noopener'}>View on LinkedIn</a></div>
                    </div>
                  )
              }

              {
                isLoading ?
                  <Skeleton height={25} width={'90%'}/> :
                  (
                    member.twitterProfileUrl &&
                    <div className={classes.socialDetails}>
                      <div className={classes.socialIconContainer}><Twitter className={classes.socialIcon}/>&nbsp;</div>
                      <div className={classes.socialDetailsContainer}><a href={member.twitterProfileUrl} target={'_blank'} rel={'noopener'}>View on Twitter</a></div>
                    </div>
                  )
              }

              {
                isLoading ?
                  <Skeleton height={25} width={'90%'}/> :
                  (
                    member.website &&
                    <div className={classes.socialDetails}>
                      <div className={classes.socialIconContainer}><Language className={classes.socialIcon}/>&nbsp;</div>
                      <div className={classes.socialDetailsContainer}><a href={member.website} target={'_blank'} rel={'noopener'}>{member.website}</a></div>
                    </div>
                  )
              }
            </Grid>

          </Grid>

          <Tabs
            value={tab}
            onChange={handleTabChange}
            indicatorColor={'primary'}
            textColor={'primary'}>
            <Tab label={'About ' + (member ? member.firstName : '') } value={0} disabled={isLoading} />
          </Tabs>

          <Grid container className={classes.tabContainer}>
            {
              tab === 0 &&
              (
                isLoading ?
                  <Grid item xs={12}>
                    <Skeleton width={'100%'} height={30}/>
                    <Skeleton width={'100%'} height={30}/>
                    <Skeleton width={'100%'} height={30}/>
                    <Skeleton width={'100%'} height={30}/>
                    <Skeleton width={'60%'} height={30}/>
                  </Grid> :
                  <div className={classes.profileDescription}>
                    {
                      member.profileDescription ?
                        member.profileDescription :
                        'The member has not set their profile about information'}
                  </div>
              )
            }

          </Grid>
        </CardContent>
      </Card>
    </Container>
  );
};

export default withStyles(styles) (ShowMemberView);
