import React from 'react';
import _ from 'lodash';
import styles from '../bracket-styles';
import Moment from 'moment';
//material
import { Grid, Typography, withStyles, List, ListItem, ListItemText, Paper } from '@material-ui/core';

function DrawnPerRoundView (props) {
  const classes = props.classes;
  const rounds = _.uniq(props.bracketMatches.map(b => b.round));
  const bracketRounds = rounds.map(br => {
    let round = _.find(props.rounds, r => r.roundNumber === br);

    return {
      round: br,
      matches: props.bracketMatches.filter(bm => bm.round === br && _.find(bm.teams, t => t.name !== '' && t.name !== null)),
      deadline: round.endDate ? Moment(new Date(round.endDate)).format('Do MMMM YYYY') : ''
    }
  });

  const getMyMatchHighlight = (teams, played, bye) => {
    let myTeam = _.find(teams, team => team.id === props.details.competitionTeamId);
    if (!myTeam)
      return '';

    if (!played && !bye)
      return classes.newDrawUnplayed;

    if ((played || bye) && myTeam.winner)
      return classes.newDrawWon;

    return classes.newDrawLost;
  };

  const getResultHighlight = (match, team) => {
    if ((!match.played && !match.bye) || (match.bye && (!team.name || team.name === '-')))
      return '';

    if (team.winner)
      return classes.newDrawTeamWon;

    return classes.newDrawTeamLost;
  };

  const getHomeOrAwayIdentifier = (match, team) => {
    if (!team.name || team.name === '-' || match.bye)
      return '';

    return team.home ? '(H)' : '(A)';
  };

  const getByeDisplayValue = (match) => {
    if (!match.bye)
      return false;

    return _.find(match.teams, team => team.name);
  };

  const hasTeam = (match) => _.find(match.teams, team => team.name);

  return (
    <Grid container>
      {
        bracketRounds.filter(br => !props.hideEmptyRounds || br.matches.length).map((br, key) =>
          <Grid className={classes.roundPadding} item xs={12} key={key}>
            <Typography display={'block'} variant={'h6'} className={'dark-mode-text'}>Round&nbsp;#{br.round}</Typography>
            {
              !JSON.parse(process.env.REACT_APP_COMPETITIONGOLF_HIDE_ROUND_DATES) &&
              <div className={classes.marginBottom10 + ' dark-mode-text'}>{ br.deadline }</div>
            }

            <Grid container spacing={1}>
              {
                br.matches.length > 0 && br.matches.map((match, key2) =>
                  <Grid item xs={12} sm={6} md={3} key={key2}>
                    <Paper classes={{ root: getMyMatchHighlight(match.teams, match.played, match.bye) }}>
                      <div className={classes.newDrawBracketHeader}>
                        Match&nbsp;{match.position}
                      </div>

                      <Grid container className={classes.newDrawBodyContainer + ' ' + (hasTeam(match) ? classes.pointer : '')} onClick={() => props.viewMatch(match.position, match)}>
                        <Grid item xs={8}>
                          <List className={classes.noPadding}>
                            {
                              match.teams.map((team, key3) =>
                                <ListItem
                                  key={key3}
                                  className={classes.newDrawTeam + ' ' + (key3 > 0 ? classes.newDrawBottomTeam : '') + ' ' + getResultHighlight(match, team)}>
                                  <ListItemText classes={{primary: classes.newDrawTeamName}} primary={
                                    <div className={(match.played || match.bye) && !team.winner ? classes.newDrawEliminated : '' }>
                                      <div className={classes.teamName}>{team.name ? team.name : (getByeDisplayValue(match) ? '' : '-')}</div>
                                      <div className={classes.teamType}>{ getHomeOrAwayIdentifier(match, team) }</div>
                                    </div>}/>
                                </ListItem>)
                            }
                          </List>
                        </Grid>
                        <Grid item xs={4} className={classes.newDrawResult}>
                          { match.result ? match.result : ( match.bye ? 'BYE' : '-' ) }
                        </Grid>
                      </Grid>
                    </Paper>
                  </Grid>)
              }

              {
                br.matches.length === 0 &&
                <Grid item xs={12} className={classes.newDrawNoMatches}>
                  No matches ready
                </Grid>
              }
            </Grid>
          </Grid>)
      }
    </Grid>
  )
}

export default withStyles(styles) (DrawnPerRoundView);