import React from 'react';
//component
import RegistrationPage from '../../components/registration';

class Registration extends React.Component {
  render () {
    return (
      <RegistrationPage inviteCode={this.props.match.params.inviteCode} teamCode={this.props.match.params.teamCode}/>
    );
  }
}

export default Registration;