
const EventItemStyles = theme => ({
  img: {
    height: 165
  },
  name: {
    fontSize: '18px',
    fontWeight: 500,
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  },
  cardContent: {
    padding: ['10px', '!important'],
    position: 'relative'
  },
  subtitle: {
    fontSize: '12px',
    color: '#646262',
    marginTop: 5,
    lineHeight: '12px'
  },
  bookButton: {
    position: 'absolute',
    bottom: '10px'
  },
  actions: {
    position: 'relative'
  },
  currency: {
    marginTop: 12,
    paddingBottom: 5,
    textAlign: 'right',
    fontSize: '14px',
    //color: '#646262',
    minHeight: 18,
    width: '100%',
  },
  skeletonCurrency: {
    float: 'right',
    marginTop: 25,
    marginBottom: 20,
  }
});

export default EventItemStyles;
