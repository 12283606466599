import React from 'react';
//material
import {Card, CardContent, withStyles, Divider} from '@material-ui/core';
import {Skeleton} from '@material-ui/lab';
//styles
import styles from './prizes-card-styles';

const PrizesCardView = (props) => {
  const {classes, isLoading, prizes} = props;
  return (
    <Card>
      <CardContent>
        {
          isLoading ?
            <Skeleton height={40} width={'60%'} /> :
            <div className={classes.title}>
              Prizes
            </div>
        }

        {
          isLoading ?
            <Skeleton height={22} width={'40%'} /> :
            <div className={classes.subheader}>
              {prizes.description}
            </div>
        }

        <Divider className={classes.divider} />

        {
          isLoading ?
            <div className={classes.skeletonTable}>
              <Skeleton height={23} className={classes.skeletonRow}/>
              <Skeleton height={23} className={classes.skeletonRow}/>
              <Skeleton height={23} className={classes.skeletonRow}/>
              <Skeleton height={23} className={classes.skeletonRow}/>
              <Skeleton height={23} className={classes.skeletonRow}/>
            </div> :
            <table className={classes.table}>
              <tbody>
              {
                prizes.prizes && prizes.prizes.map((p, key) =>
                  (
                    <tr key={key}>
                      <td className={classes.column}>{p.position}</td>
                      <td className={classes.data}>{p.title}</td>
                    </tr>
                  ))
              }
              </tbody>
            </table>
        }

        {
          !isLoading && (prizes.prizes.length === 0 || !prizes.prizes) &&
            <div className={classes.defaultText}>
              Prizes coming soon
            </div>
        }

      </CardContent>
    </Card>
  );
};

export default withStyles(styles) (PrizesCardView);
