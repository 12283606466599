import React from 'react';
//components
import ExpressCheckoutComponent from '../../components/express-checkout';

class ExpressCheckout extends React.Component {
  render () {
    return (
      <ExpressCheckoutComponent discountCode={this.props.match.params.discountCode}/>
    );
  }
}

export default ExpressCheckout;
