import CoreStyles from '../../../utils/styles/core-styles';

const BracketStyles = theme => ({
  emptyListView: {
    fontSize: '20px',
    textAlign: 'center',
    marginTop: 60
  },
  roundPadding: {
    padding: 10
  },
  newDrawBracketHeader: {
    height: 35,
    lineHeight: '35px',
    textAlign: 'center',
    backgroundColor: theme.palette.primary.main,
    borderTopRightRadius: 4,
    borderTopLeftRadius: 4,
    color: theme.palette.primary.contrastText
  },
  newDrawBodyContainer: {
    height: 70
  },
  newDrawTeam: {
    padding: '0 10px',
    lineHeight: '35px',
    height: '35px',
    borderLeft: '3px solid transparent'
  },
  newDrawBottomTeam: {
    borderBottomLeftRadius: 4
  },
  newDrawTeamName: {
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    fontSize: '14px'
  },
  newDrawTeamWon: {
    borderLeftColor: process.env.REACT_APP_COMPETITIONGOLF_WINNING_TEAM_COLOUR
  },
  newDrawTeamLost: {
    borderLeftColor: process.env.REACT_APP_COMPETITIONGOLF_LOSS_TEAM_COLOUR,
    color: '#a0a0a0'
  },
  newDrawResult: {
    lineHeight: '70px',
    textAlign: 'center',
    fontSize: '16px'
  },
  newDrawEliminated: {
    textDecoration: 'line-through'
  },
  newDrawUnplayed: {
    backgroundColor: theme.palette.cgNeutral.main
  },
  newDrawWon: {
    backgroundColor: theme.palette.cgPositive.main
  },
  newDrawLost: {
    backgroundColor: theme.palette.cgWarning.main
  },
  newDrawNoMatches: {
    textAlign: 'center',
    padding: ['20px 0', '!important']
  },
  teamName: {
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    width: 'calc(100% - 30px)',
    maxWidth: 'calc(100% - 30px)',
    display: 'inline-block'
  },
  teamType: {
    width: 30,
    float: 'right',
    display: 'inline-block',
    fontSize: '12px'
  },
  matchDate: {
    paddingBottom: 10
  },
  matchResult: {
    fontWeight: 'bold',
    fontSize: '24px',
    marginTop: '40px',
    [theme.breakpoints.down('sm')]: {
      marginTop: 0
    }
  },
  viewMatchWinner: {
    backgroundColor: '#3ea410',
    color: '#ffffff',
    padding: '4px 0',
    borderRadius: '4px'
  },
  viewMatchLoser: {
    backgroundColor: '#ff5252',
    color: '#ffffff',
    padding: '4px 0',
    borderRadius: '4px'
  },
  viewMatchDefault: {
    backgroundColor: '#d7d7d7',
    padding: '4px 0',
    borderRadius: '4px'
  },
  viewMatchDate: {
    float: 'left',
    fontSize: '14px'
  },
  viewMatchClub: {
    float: 'right',
    fontSize: '14px'
  },
  sliderDialogContent: {
    overflowX: 'hidden',
    overflowY: 'hidden',
    minHeight: 194,
    [theme.breakpoints.down('sm')]: {
      minHeight: 384
    }
  },
  editLeft: {
    paddingRight: 5,
    [theme.breakpoints.down('sm')]: {
      paddingRight: 0
    }
  },
  editRight: {
    paddingLeft: 5,
    [theme.breakpoints.down('sm')]: {
      paddingLeft: 0
    }
  },
  deadlineContainer: {
    height: '30px'
  },
  deadlineText: {
    display: 'inline-block',
    fontSize: '14px'
  },
  deadlinePickerButton: {
    marginLeft: 5,
    padding: '5px'
  },
  deadlinePicker: {
    '& input': {
      display: 'none'
    }
  },
  deadlineIcon: {
    height: '20px'
  },
  dialogTitleContainer: {
    position: 'relative',
    minHeight: '30px',
    paddingBottom: 0
  },
  dialogTitle: {
    position: 'absolute',
    width: '100%',
    left: 0,
    textAlign: 'center'
  },
  swapButton: {
    width: '80px'
  },
  ...CoreStyles(theme)
});

export default BracketStyles;