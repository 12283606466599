import React, { useEffect } from 'react';
import styles from '../registration-styles';
import _ from 'lodash';
//material
import { Card, CardContent, TextField, Grid, withStyles, Checkbox, IconButton, Tooltip } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import { HelpOutline } from '@material-ui/icons';
//components
import AsyncSelect from '../../async-select';
//routing
import { withRouter } from 'react-router-dom';

let helpLink = process.env.REACT_APP_COMPETITIONGOLF_HELP_URL;
helpLink = (!helpLink.startsWith('#{') && !helpLink.endsWith('}#')) ? helpLink : '';

function TeamDetailsView (props) {
  const classes = props.classes;
  let collection = props.clubs.map(club => { return {id: club.clubId, name: club.name + ' (' + club.county +')'}; });
  let club = _.find(collection, c => c.id === props.registration.clubId);

  useEffect(() => {
    const isStepComplete = () => (props.registration.pairName || props.isSinglePlayerGame) && props.registration.clubId && props.registration.hasCheckedTerms && props.registration.hasCheckedRules;
    const stepCompleted = isStepComplete();

    if (stepCompleted && !props.isStepValid)
      props.setStepValid(true);

    if (!stepCompleted && props.isStepValid)
      props.setStepValid(false);
  });

  return (
    <Card>
      <div className={classes.slideHeader}>
        Registration
      </div>

      <CardContent>
        {
          helpLink &&
          <a
            className={classes.helpButton}
            href={helpLink}
            target={'_blank'}
            rel={'noopener'}> 
            need help?
          </a> 
        } 

        <p className={classes.slideInfo}>
          {
            !props.isSinglePlayerGame ?
              'Please enter your team name and your home golf course.' :
              'Please enter your home golf course.'
          }
        </p>

        {
          !props.isSinglePlayerGame &&
          <Grid container className={classes.componentGrid}>
            <Grid item xs={11} sm={8}>
              {
                props.isLoading ?
                    <Skeleton width={'100%'} height={56}/> :
                    <TextField
                        fullWidth
                        variant={'outlined'}
                        label={'Team Name'}
                        value={props.registration.pairName}
                        name={'pairName'}
                        onChange={props.handleUpdateRegistration}/>
              }
            </Grid>
          </Grid>
        }

        <Grid container className={classes.componentGrid}>
          <Grid item xs={11} sm={8}>
            {
              props.isLoading ?
                <Skeleton width={'100%'} height={56}/> :
                <AsyncSelect
                  asyncCall={props.getClubsMatchingQuery}
                  collection={collection}
                  handleAssignId={props.handleClubSelection}
                  label={'Home Club'}
                  placeholder={'Search for a club by name'}
                  defaultText={club ? club.name : ''}
                  helperText={'Start typing your club name and select it from the list'}/>
            }
          </Grid>
          <Grid item xs={1} sm={4} className={classes.helpButtonContainer}>
            {
              props.isLoading ?
                <Skeleton variant={'circle'} width={24} height={24} className={classes.helpButtonSkeleton}/> :
                <Tooltip disableFocusListener disableTouchListener title={'The home golf club is the club you nominate as your home club.\nYou need to state a home club, but you do not need to be a member of the club.'}>
                  <IconButton color={'primary'} size={'small'}><HelpOutline/></IconButton>
                </Tooltip>
            }
          </Grid>
        </Grid>

        <Grid container className={classes.componentGrid + ' ' + classes.acceptConditions}>
          {
            props.isLoading ?
              <Grid item xs={12}>
                <Skeleton className={classes.displayInlineBlock} width={13}/>&nbsp;
                <Skeleton className={classes.displayInlineBlock} width={'50%'}/>
              </Grid> :
              <Grid item xs={12}>
                <Checkbox
                  color={'primary'}
                  name='hasCheckedTerms'
                  checked={props.registration.hasCheckedTerms}
                  onChange={props.handleUpdateRegistrationCheck}/>
                I have read and accept the <a className={classes.termsButton} href={'/terms-and-conditions'} target={'_blank'} rel={'noopener'}>Terms &amp; Conditions</a> of entry
              </Grid>
          }
        </Grid>

        <Grid container className={classes.componentGrid + ' ' + classes.acceptRules}>
          {
            props.isLoading ?
              <Grid item xs={12}>
                <Skeleton className={classes.displayInlineBlock} width={13}/>&nbsp;
                <Skeleton className={classes.displayInlineBlock} width={'50%'}/>
              </Grid> :
              <Grid item xs={12}>
                <Checkbox
                  color={'primary'}
                  name='hasCheckedRules'
                  checked={props.registration.hasCheckedRules}
                  onChange={props.handleUpdateRegistrationCheck}/>
                I have read and accept the <a className={classes.termsButton} href={'/the-rules'} target={'_blank'} rel={'noopener'}>Rules</a> of the competition
              </Grid>
          }
        </Grid>
      </CardContent>
    </Card>
  )
}

export default withRouter(withStyles(styles) (TeamDetailsView));
