const AppBarStyles = theme => ({
  menuContainer: {
    textAlign: 'center',
    zIndex: '1400'
  },
  menuPaper: {
    padding: '5px 0',
    minHeight: 44,
    overflowY: 'auto',
    maxHeight: 190
  },
  noResults: {
    lineHeight: '44px'
  }
});


export default AppBarStyles
