import React from 'react';
import styles from './invalid-user-styles';
//material
import { Container, Card, CardContent, withStyles, Button } from '@material-ui/core';
//classes
import AuthorisationService from '../../utils/authorisation/authorisationService';
import { OrganisationService } from '../../utils/data-transfer';
//Routing
import { withRouter } from 'react-router-dom';

class InvalidUser extends React.Component {
  constructor (props) {
    super(props);

    this.state = {
      user: null,
      showRegistration: false
    };

    this._organisationService = new OrganisationService();
  }

  componentDidMount () {
    this.getUser();
    this.checkForOpenCompetitions();
  }

  getUser = async () =>{
    try {
      let user = await AuthorisationService.getUser();
      this.setState({ user });
    } catch (err) {
      console.log(err);
    }
  };

  checkForOpenCompetitions = async () => {
    try {
      let competitions = await this._organisationService.getOpenCompetitions();

      this.setState({showRegistration: competitions.length > 0});
    } catch (err) {
      this.setState({showRegistration: false});
      console.log(err)
    }
  };

  navigate = (path) => {
    this.props.history.push(path);
  };

  render () {
    return (
      <Container maxWidth={'sm'}>
        <Card className={this.props.classes.marginTop20 + ' ' + this.props.classes.marginBottom40}>
          <div className={this.props.classes.header}>
            Missing Permissions
          </div>

          <CardContent >
            <p>
              You are logged in with { this.state.user ? this.state.user.profile.email : '' } but you do not have permission to access this website.
            </p>

            {
              this.state.showRegistration &&
                <div>
                  <p>
                    Please click the register button below if you would like to join any open competitions.
                  </p>

                  <div className={this.props.classes.textCenter}>
                    <Button color={'primary'} onClick={() => this.navigate('/registration')}>Register</Button>
                  </div>
                </div>
            }

            <p>
              If you would like to try a different account, please sign out.
            </p>
            <div className={this.props.classes.textCenter}>
              <Button color={'primary'} onClick={AuthorisationService.signoutRedirect}>Sign Out</Button>
            </div>
          </CardContent>
        </Card>

      </Container>
    )
  }
}

export default withRouter(withStyles(styles) (InvalidUser));