import React from 'react';
import styles from '../bracket-styles';
import Moment from 'moment';
//material
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  Grid,
  withStyles,
  Hidden
} from '@material-ui/core';
import {Skeleton} from '@material-ui/lab';

function ViewMatchView(props) {
  const classes = props.classes;
  const getMatchColours = (team) => {
    if (props.matchLoading)
      return '';

    if (!props.match.winningTeamId || props.match.isBye)
      return classes.viewMatchDefault;

    return props.match.winningTeamId === team.competitionPairId ? classes.viewMatchWinner : classes.viewMatchLoser;
  };

  return (
    <Dialog
      maxWidth={'md'}
      open={props.showDialog}
      onClose={props.handleCloseDialog}
      fullWidth>
      <DialogTitle className={classes.textCenter + ' ' + classes.dialogTitleContainer}>
        <Hidden smDown>
          <span className={classes.viewMatchDate}>
            {
              props.matchLoading ?
                <Skeleton width={'40%'} className={classes.alignSelfCenter}/> :
                (props.match.matchDate &&
                  <Grid item xs={12} className={classes.textCenter + ' ' + classes.matchDate}>
                    {Moment(new Date(props.match.matchDate)).format('Do MMMM YYYY')}
                  </Grid>)
            }
          </span>
        </Hidden>

        <span className={classes.dialogTitle}>
          Match Details
          <br/>

        </span>

        <Hidden smDown>
          <span className={classes.viewMatchClub}>
            {
              props.matchLoading ?
                <Skeleton width={'40%'} className={classes.alignSelfCenter}/> :
                (props.match.clubName &&
                  <Grid item xs={12} className={classes.textCenter}>
                    {props.match.clubName}
                  </Grid>)
            }
          </span>
        </Hidden>
      </DialogTitle>
      <DialogContent className={classes.sliderDialogContent}>
        <Grid container>
          <Hidden mdUp>
            {
              props.matchLoading ?
                <Skeleton width={'40%'} className={classes.alignSelfCenter}/> :
                (props.match.matchDate &&
                  <Grid item xs={12} className={classes.textCenter + ' ' + classes.matchDate}>
                    {Moment(new Date(props.match.matchDate)).format('Do MMMM YYYY')}
                  </Grid>)
            }

            {
              props.matchLoading ?
                <Skeleton width={'40%'} className={classes.alignSelfCenter}/> :
                (props.match.clubName &&
                  <Grid item xs={12} className={classes.textCenter + ' ' + classes.marginBottom10}>
                    {props.match.clubName}
                  </Grid>)
            }
          </Hidden>

          {
            !props.details.newDrawPerRound && props.match && !props.match.winningTeamId &&
            <Grid item xs={12} className={classes.textCenter + ' ' + classes.deadlineContainer}>
              {
                props.matchLoading ?
                  <Skeleton width={'70%'} className={classes.alignSelfCenter}/> :
                  <div>
                    <div className={classes.deadlineText}>Deadline: {Moment(new Date(props.match.deadline)).format('Do MMMM YYYY')}</div>
                  </div>
              }
            </Grid>
          }

          <Grid item xs={12} md={5} className={classes.marginTop20}>
            <Grid item xs={12}
                  className={classes.textCenter + ' ' + (props.match ? getMatchColours(props.match.homeTeam) : '')}>
              {
                props.matchLoading ?
                  <Skeleton width={'100%'} className={classes.alignSelfCenter}/> :
                  <strong>Home</strong>
              }
            </Grid>

            {
              (props.matchLoading || props.match.homeTeam) &&
              <Grid container>
                {
                  props.details.tournamentFormatId !== 1 &&
                  <Grid container>
                    <Grid container className={classes.marginTop10 + ' ' + classes.textCenter}>
                      <Grid item xs={12}>{props.matchLoading ?
                        <Skeleton width={'60%'}/> : props.match.homeTeam.name}</Grid>
                    </Grid>
                  </Grid>
                }

                <Grid container className={classes.marginTop10 + ' ' + classes.textCenter}>
                  <Grid item xs={12}>{props.matchLoading ?
                    <Skeleton width={'60%'}/> : props.match.homeTeam.homeClubName}</Grid>
                </Grid>

                <Grid container className={classes.marginTop10 + ' ' + classes.textCenter}>
                  <Grid item xs={7}>{props.matchLoading ?
                    <Skeleton width={'60%'}/> : props.match.homeTeam.leadPlayerName}</Grid>
                  <Grid item xs={4}>{props.matchLoading ? <Skeleton
                    width={'30%'}/> : (props.match.homeTeam.leadPlayerHandicap !== null ? ('hcp: ' + props.match.homeTeam.leadPlayerHandicap) : '')}</Grid>
                </Grid>

                <Grid container className={classes.marginTop10 + ' ' + classes.textCenter}>
                  <Grid item xs={7}>{props.matchLoading ?
                    <Skeleton width={'60%'}/> : props.match.homeTeam.additionalPlayerName}</Grid>
                  <Grid item xs={4}>{props.matchLoading ? <Skeleton
                    width={'30%'}/> : (props.match.homeTeam.additionalPlayerHandicap !== null ? ('hcp: ' + props.match.homeTeam.additionalPlayerHandicap) : '')}</Grid>
                </Grid>
              </Grid>
            }

            {
              (!props.matchLoading && !props.match.homeTeam) &&
              <Grid container>
                <Grid item xs={12} className={classes.marginTop30 + ' ' + classes.textCenter}>
                  Waiting for Opponent
                </Grid>
              </Grid>
            }
          </Grid>

          <Grid item xs={12} md={2} className={classes.marginTop20}>
            <Grid item xs={12} className={classes.textCenter + ' ' + classes.matchResult}>
              {
                props.matchLoading ?
                  <Skeleton width={'50%'} variant={'rect'} className={classes.alignSelfCenter}/> :
                  (props.match.isBye ? 'Bye' : props.match.result)
              }
            </Grid>
          </Grid>

          <Grid item xs={12} md={5} className={classes.marginTop20}>
            <Grid item xs={12}
                  className={classes.textCenter + ' ' + (props.match ? getMatchColours(props.match.awayTeam) : '')}>
              {
                props.matchLoading ?
                  <Skeleton width={'100%'} className={classes.alignSelfCenter}/> :
                  <strong>Away</strong>
              }
            </Grid>

            {
              (props.matchLoading || props.match.awayTeam) &&
              <Grid container>
                {
                  props.details.tournamentFormatId !== 1 &&
                  <Grid container>
                    <Grid container className={classes.marginTop10 + ' ' + classes.textCenter}>
                      <Grid item xs={12}>{props.matchLoading ?
                        <Skeleton width={'60%'}/> : props.match.awayTeam.name}</Grid>
                    </Grid>
                  </Grid>
                }

                <Grid container className={classes.marginTop10 + ' ' + classes.textCenter}>
                  <Grid item xs={12}>{props.matchLoading ?
                    <Skeleton width={'60%'}/> : props.match.awayTeam.homeClubName}</Grid>
                </Grid>

                <Grid container className={classes.marginTop10 + ' ' + classes.textCenter}>
                  <Grid item xs={7}>{props.matchLoading ?
                    <Skeleton width={'60%'}/> : props.match.awayTeam.leadPlayerName}</Grid>
                  <Grid item xs={4}>{props.matchLoading ? <Skeleton
                    width={'30%'}/> : (props.match.awayTeam.leadPlayerHandicap !== null ? ('hcp: ' + props.match.awayTeam.leadPlayerHandicap) : '')}</Grid>
                </Grid>

                <Grid container className={classes.marginTop10 + ' ' + classes.textCenter}>
                  <Grid item xs={7}>{props.matchLoading ?
                    <Skeleton width={'60%'}/> : props.match.awayTeam.additionalPlayerName}</Grid>
                  <Grid item xs={4}>{props.matchLoading ? <Skeleton
                    width={'30%'}/> : (props.match.awayTeam.additionalPlayerHandicap !== null ? ('hcp: ' + props.match.awayTeam.additionalPlayerHandicap) : '')}</Grid>
                </Grid>
              </Grid>
            }

            {
              (!props.matchLoading && !props.match.awayTeam) &&
              <Grid container>
                <Grid item xs={12} className={classes.marginTop30 + ' ' + classes.textCenter}>
                  Waiting for Opponent
                </Grid>
              </Grid>
            }
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={props.handleCloseDialog}>
          Close
        </Button>
      </DialogActions>
    </Dialog>);
}

export default withStyles(styles)(ViewMatchView);