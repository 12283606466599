import React from 'react';
//components
import View from './show-member-view';
//utils
import {OrganisationService} from '../../utils/data-transfer';

class ShowMemberContainer extends React.Component{

  constructor(props) {
    super(props);

    this._organisationService = new OrganisationService();

    this.state = {
      member: null,
      isLoading: true,
      memberTypes: [],
      tab: 0,
    };
  }

  componentDidMount() {
    this.getMember();
  }

  getMember = async () => {
    try {
      const member = await this._organisationService.getMember(this.props.organisationMemberId);
      this.setState({member, isLoading: false});
    } catch (ex) {
      this.setState({isLoading: false});
    }
  }

  handleTabChange = (e, tab) => this.setState({tab});

  render() {
    return (
      <View {...this.state}
            handleTabChange={this.handleTabChange}/>
    );
  }
}

export default ShowMemberContainer;
