import React from 'react';
import styles from './cookie-consent-styles';
//material
import { Snackbar, SnackbarContent, Button, withStyles } from '@material-ui/core';

function CookieConsentView (props) {
  const classes = props.classes;

  return (
    <Snackbar
      open={ !props.cookieConsent }
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      onClose={ (event, reason) => { props.handleClick(event, reason) } }>
      <SnackbarContent
        className={classes.cookieConsentBar}
        message={'We use cookies to ensure you get the best experience on our website.'}
        action={<Button classes={{ text: classes.consentButton }} onClick={ (event, reason) => { props.handleClick(event, reason) } }>Got It!</Button>}/>
    </Snackbar>
  );
}

export default withStyles(styles) (CookieConsentView);
