import React from 'react';
//components
import ResultsView from './results-view';

class ResultsContainer extends React.Component {
  render () {
    return (
      <ResultsView
        results={this.props.results}/>
    );
  }
}

export default ResultsContainer;