import CoreStyles from '../../utils/styles/core-styles';

const TeamPlayerStyles = theme => ({
  cardBodyPadding: {
    padding: '24px 26px 0'
  },
  cardAvatarBodyPadding: {
    padding: '24px 10px 0'
  },
  cardHeader: {
    fontSize: 'h6',
    fontWeight: 'bold',
    color: theme.palette.primary.contrastText,
    backgroundColor: theme.palette.primary.main,
    textAlign: 'center',
    padding: '10px 0'
  },
  cardBar: {
    fontSize: 15,
    marginBottom: 5,
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden'
  },
  playerCardSpacer: {
    marginTop: 10,
    minHeight: 201
  },
  avatarRoot: {
    margin: '0 auto',
    height: 80,
    width: 80,
    [theme.breakpoints.down('sm')]: {
      marginBottom: 15
    }
  },
  avatarLarge: {
    height: 100,
    width: 100
  },
  editAvatar: {
    fontSize: 12,
    marginTop: 5,
    textDecoration: 'underline',
    cursor: 'pointer',
    '&:hover' : {
      color: '#008CBA'
    }
  },
  cardContent: {
    position: 'relative',
    padding: 0
  },
  wizardEdit: {
    width: 'fit-content',
    position: 'absolute',
    right: 5,
    top: 8,
    fontSize: '12px',
    cursor: 'pointer'
  },
  playerTextSmallPadding: {
    [theme.breakpoints.down('sm')]: {
      paddingLeft: 26
    }
  },
  ...CoreStyles(theme)
});

export default TeamPlayerStyles;