import Axios from 'axios';

const competitionGolfApi = process.env.REACT_APP_COMPETITIONGOLF_PUBLIC_API_ENDPOINT;

const Event = {
  getEventDetails: (eventId) =>
    Axios.get(competitionGolfApi + 'events/' + eventId)
    .then(resp => resp.data)
    .catch(err => {
      window.rg4js('send', {
        error: err
      });
      throw err;
    }),
  getAgenda: (eventId) => Axios.get(competitionGolfApi + 'events/' + eventId + '/agendaitems')
    .then(resp => resp.data)
    .catch(err => {
      if (err.response.status === 404)
        return [];

      window.rg4js('send', {
        error: err
      });

      throw err;
    }),
  getPrizes: (eventId) => Axios.get(competitionGolfApi + 'events/' + eventId + '/prizes')
    .then(resp => resp.data)
    .catch(err => {
      if (err.response.status === 404)
        return {prizes: []};

      window.rg4js('send', {
        error: err
      });

      throw err;
    }),
}

export default Event;
