import React from 'react';
//Components
import Dashboard from '../../components/dashboard';
//Material

class MyDashboard extends React.Component {
  render () {
    return (
      <Dashboard/>
    );
  }
}

export default MyDashboard;