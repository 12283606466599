import CoreStyles from '../../../../utils/styles/core-styles';

const ResultStyles = theme => ({
  gridContainer: {
    position: 'relative',
  },
  result: {
    position: 'absolute',
    right: 0,
    top: 0,
    padding: 10,
    color: '#ffffff',
    borderRadius: 3,
    minWidth: '47px',
    textAlign: 'center'
  },
  resultWin: {
    backgroundColor: '#3EA410'
  },
  resultLoss: {
    backgroundColor: '#ef4e3a'
  },
  resultText: {
    fontSize: '10px'
  },
  textContainer: {
    width: '100%'
  },
  roundText: {
    marginBottom: 30,
    fontSize: '24px',
    fontWeight: 400,
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    width: 'calc(100% - 70px)',
    [theme.breakpoints.down('xs')]: {
      fontSize: '18px'
    }
  },
  matchText: {
    width: '50%',
    fontSize: '16px',
    display: 'inline-block',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    [theme.breakpoints.down('xs')]: {
      fontSize: '12px',
      display: 'block',
      width: '100%'
    }
  },
  ...CoreStyles(theme)
});

export default ResultStyles;