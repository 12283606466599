
const PayPalButtonStyles = theme => ({
  disabledButton: {
    width: '100%',
    borderRadius: '4px',
    //backgroundColor: 'rgb(255, 196, 57)',
    backgroundColor: 'transparent',
    border: 'none',
    display: 'inline-block',
    position: 'relative'
  },
  imageContainer: {
    backgroundColor: 'rgba(0, 0, 0, 0.12)',
    height: '100%',
    width: '100%',
    borderRadius: '4px',
    position: 'absolute',
    top: 0,
    left: 0
  },
  disabledImage: {
    width: 56,
    height: 18
  }
});

export default PayPalButtonStyles;