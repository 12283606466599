import React from 'react';
import styles from '../details-styles';
//material
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Grid,
  withStyles,
  TextField,
} from '@material-ui/core';
//components
import AsyncSelect from '../../../async-select';

function EditTeamView (props) {
  const classes = props.classes;
  let collection = props.clubs.map(club => { return {id: club.clubId, name: club.name + ' (' + club.county +')'}; });

  return (
    <Dialog
      maxWidth={'sm'}
      open={props.showDialog}
      onClose={() => props.handleShowDialog(false)}
      fullWidth>
      <DialogTitle>Edit Team</DialogTitle>
      <DialogContent>
        <Grid container>
          <Grid item xs={12} sm={11} className={classes.marginBottom10}>
            <TextField
              fullWidth
              label={'Team Name'}
              value={props.team.pairName ? props.team.pairName : ''}
              name={'pairName'}
              onChange={props.handleChange}
              variant={'outlined'}/>
          </Grid>

          {
            props.details.allowHomeClubChanges &&
            <Grid item xs={12} sm={11} className={classes.marginBottom10}>
              <AsyncSelect
                asyncCall={props.getClubsMatchingQuery}
                collection={collection}
                label={'Golf Club'}
                placeholder={'Search for a club by name'}
                handleAssignId={props.handleClubChange}
                defaultText={props.details.clubName}/>
            </Grid>
          }
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button color={'primary'} onClick={() => props.handleShowDialog(false)}>Cancel</Button>
        <Button
          color={'primary'}
          onClick={props.handleEditTeamSubmit}
          disabled={!props.team.pairName}>
          Submit
        </Button>
      </DialogActions>

    </Dialog>
  )
}

export default withStyles(styles) (EditTeamView)