import React from 'react';
import styles from './express-checkout-styles';
//material
import {Container, Card, CardContent, Grid, TextField, Icon, withStyles, Button} from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
//components
import SelectMultipleCompetitions from './select-multiple-competitions';
import PaypalButton from '../paypal-button';

const payPalClientId = process.env.REACT_APP_COMPETITIONGOLF_PAYPAL_CLIENT_ID;

function ExpressCheckoutView (props) {
  const classes = props.classes;
  let isSubmitDisabled = () => {
    return (!props.registration.firstName ||
      !props.registration.lastName ||
      !props.registration.email ||
      !props.registration.email.match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/));
  };

  return (
    <Container maxWidth={'md'} classes={{ root: classes.containerPadding }}>
      <Card>
        <CardContent>
          {
            props.isLoading ?
              <Skeleton width={'70%'} height={56}/> :
              <h2>
                Matchplay Checkout
              </h2>
          }

          {
            props.isLoading ?
              <Skeleton width={'70%'} height={56}/> :
              <p>
                Please enter the details of the lead player.
              </p>
          }

          <Grid container spacing={2}>
            <Grid item xs={8} md={5}>
              {
                props.isLoading ?
                  <Skeleton width={'100%'} height={56}/> :
                  <TextField
                    fullWidth
                    label={'First Name'}
                    value={props.registration.firstName}
                    name={'firstName'}
                    onChange={props.handleChange}
                    variant={'outlined'}/>
              }
            </Grid>

            <Grid item xs={8} md={5}>
              {
                props.isLoading ?
                  <Skeleton width={'100%'} height={56}/> :
                  <TextField
                    fullWidth
                    label={'Last Name'}
                    value={props.registration.lastName}
                    name={'lastName'}
                    onChange={props.handleChange}
                    variant={'outlined'}/>
              }
            </Grid>

            <Grid item xs={10} md={8}>
              {
                props.isLoading ?
                  <Skeleton width={'100%'} height={56}/> :
                  <TextField
                    fullWidth
                    label={'Email Address'}
                    value={props.registration.email}
                    name={'email'}
                    onChange={props.handleChange}
                    variant={'outlined'}
                    type={'email'}/>
              }
            </Grid>

            <Grid item xs={9} md={6}>
              {
                props.isLoading ?
                  <Skeleton width={'100%'} height={56}/> :
                  <TextField
                    fullWidth
                    label={'Voucher Code'}
                    placeholder={'Optional'}
                    value={props.registration.discountCode}
                    name={'discountCode'}
                    onChange={props.handleChange}
                    variant={'outlined'}
                    error={props.errorMessage !== ''}
                    helperText={props.errorMessage + (props.discountApplied ? 'Discount voucher applied.' : '')}
                    disabled={props.discountCodeLocked}/>
              }
            </Grid>

            <Grid item xs={3} md={6} className={classes.voucherButtonContainer}>
              {
                props.isLoading ?
                  <Skeleton variant={'rect'} height={20} width={64} className={classes.voucherSkeleton}/>:
                  (!props.discountCodeLocked &&
                    <Button
                      color={'primary'}
                      size={'small'}
                      aria-label={'small'}
                      variant={'contained'}
                      onClick={props.applyVoucherCode}
                      disabled={!props.registration.discountCode}>
                      Apply
                    </Button>)
              }
            </Grid>
          </Grid>

          <Grid container className={classes.buttonOuterContainer}>
            <Grid container>
              <Grid item xs={12} sm={8} md={6} className={classes.buttonContainer}>
                {
                  props.isLoading ?
                    <Skeleton className={classes.submitButton} width={'100%'} height={36} variant={'rect'}/> :
                    <Button
                      className={classes.submitButton}
                      variant={'contained'}
                      classes={{ root: classes.stripePaymentButton }}
                      disableRipple
                      disabled={isSubmitDisabled()}
                      fullWidth
                      onClick={props.submitExpressCheckout}>
                      <Icon className='fab fa-apple-pay' classes={{ root: classes.faIcon }}/>
                      <Icon className='fab fa-google-pay' classes={{ root: classes.faIcon }}/>
                      Pay&nbsp;by&nbsp;Card
                      {/*<Icon className='far fa-credit-card' classes={{ root: classes.faIcon }}/>*/}

                    </Button>
                }
              </Grid>
            </Grid>

            <Grid container>
              {
                payPalClientId && !payPalClientId.startsWith('#{') && !payPalClientId.endsWith('}#') &&
                <Grid item xs={12} sm={8} md={6} className={classes.buttonContainer}>
                  {
                    props.isLoading ?
                      <Skeleton className={classes.submitButton} width={'100%'} height={36} variant={'rect'}/> :
                      <PaypalButton
                        selectedCompetitions={props.selectedCompetitions}
                        discountedCompetitions={props.discountedCompetitions}
                        payPalCheckoutComplete={props.payPalCheckoutComplete}
                        height={37}
                        disabled={isSubmitDisabled()}/>
                  }
                </Grid>
              }
            </Grid>
          </Grid>

        </CardContent>
      </Card>

      {
        props.showSelectCompetitionsDialog &&
          <SelectMultipleCompetitions
            showDialog={props.showSelectCompetitionsDialog}
            competitions={props.competitions}
            selectCompetitions={props.selectCompetitions}/>
      }
    </Container>
  );
}

export default withStyles(styles) (ExpressCheckoutView);
