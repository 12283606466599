import React from 'react';
import styles from './members-styles';
//material
import { Container, Grid, TextField, withStyles, } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
//components
import Member from '../../../components/member';

function MembersView (props) {
  const classes = props.classes;

  return (
    <Container maxWidth={'md'}>
      <Grid container>
        <Grid item xs={12} sm={8} md={6} className={classes.searchContainer}>
          {
            props.isLoading ?
              <Skeleton height={56}/> :
              <TextField
                fullWidth
                label={'Search Members'}
                placeholder={'Search members by name, email address or phone number'}
                value={props.filterText}
                name={'filterText'}
                onChange={props.handleSearchChange}
                variant={'outlined'}/>
          }
        </Grid>

        {
          props.isLoading &&
          <Grid container spacing={2}>
            <Grid item xs={12} sm={4} md={3}><Member isLoading /></Grid>
            <Grid item xs={12} sm={4} md={3}><Member isLoading /></Grid>
            <Grid item xs={12} sm={4} md={3}><Member isLoading /></Grid>
            <Grid item xs={12} sm={4} md={3}><Member isLoading /></Grid>
            <Grid item xs={12} sm={4} md={3}><Member isLoading /></Grid>
            <Grid item xs={12} sm={4} md={3}><Member isLoading /></Grid>
            <Grid item xs={12} sm={4} md={3}><Member isLoading /></Grid>
            <Grid item xs={12} sm={4} md={3}><Member isLoading /></Grid>
          </Grid>
        }

        {
          !props.isLoading && props.pinnedMembers.length > 0 &&
            <Grid container spacing={2} className={classes.pinnedMembers}>
              {
                props.pinnedMembers.map((m, i) =>
                  <Grid key={i} item xs={12} sm={4} md={3}>
                    <Member
                      member={m}/>
                  </Grid>)
              }
            </Grid>
        }

        <Grid container spacing={2}>
          {
            !props.isLoading && props.members.length > 0 && props.members.map((m, i) =>
              <Grid key={i} item xs={12} sm={4} md={3}>
                <Member
                  member={m}/>
              </Grid>)
          }
        </Grid>

        {
          !props.isLoading && props.members.length === 0 &&
          <div className={classes.emptyListView}>
            No Members to show
          </div>
        }
      </Grid>
    </Container>
  )
}

export default withStyles(styles) (MembersView);
