const TellAFriendStyles = theme => ({
  header: {
    fontSize: '16px',
    fontWeight: '500',
    textAlign: 'center',
    marginTop: '20px'
    // color: theme.palette.primary.contrastText,
    // backgroundColor: theme.palette.primary.main,
    // textAlign: 'center',
    // padding: '10px 0'
  },
  buttonContainer: {
    textAlign: 'center'
  },
  facebookButton: {
    color: '#4267B2'
  },
  twitterButton: {
    color: '#1DA1F2'
  },
  instagramButton: {
    color: '#c13584'
  },
  linkedInButton: {
    color: '#2867B2'
  }

});

export default TellAFriendStyles;
