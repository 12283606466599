const AppContentStyles = theme => ({
  siteContent: {
    minHeight: 'calc(100vh - 178px)',
    width: '100%',
    padding: 20,
    boxSizing: 'border-box',
    [theme.breakpoints.down('sm')]: {
      minHeight: 'calc(100vh - 216px)'
    },
    [theme.breakpoints.down('xs')]: {
      minHeight: 'calc(100vh - 208px)'
    }
  },
  noPadding: {
    padding: 0
  }
});

export default AppContentStyles;
