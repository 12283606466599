import React from 'react';
import styles from './app-bar-styles.js';
//Material
import { AppBar, Toolbar, withStyles, Button, Hidden, IconButton, Drawer, List, ListItem, ListItemText, Menu, MenuItem, createMuiTheme, MuiThemeProvider } from '@material-ui/core';
import { AccountCircle, Menu as MenuIcon } from '@material-ui/icons';
//Routing
import { withRouter } from 'react-router-dom';
//Utils
import AuthorisationService from '../../utils/authorisation/authorisationService';

const tempTheme = createMuiTheme({
  typography: {
    useNextVariants: true,
  },
  palette: {
    primary: {
      main: process.env.REACT_APP_COMPETITIONGOLF_HEADER_COLOUR
    }
  }
});

const getSetting = (value) =>
  (!value.startsWith('#{') && !value.endsWith('}#')) ? JSON.parse(value) : false;

const showRedeemCode = process.env.REACT_APP_COMPETITIONGOLF_SHOW_REDEEM_CODE;
const hidePublicPages = process.env.REACT_APP_HIDE_PUBLIC_PAGES;
const showMembers = process.env.REACT_APP_COMPETITIONGOLF_SHOW_MEMBERS;
const showMatchplay = process.env.REACT_APP_COMPETITIONGOLF_SHOW_MATCHPLAY;
const showEvents = process.env.REACT_APP_COMPETITIONGOLF_SHOW_EVENTS;
const showTopMenu = process.env.REACT_APP_COMPETITIONGOLF_SHOW_TOP_MENU;
const showDashboard = process.env.REACT_APP_COMPETITIONGOLF_SHOW_DASHBOARD;

function AppBarView (props) {
  const {classes, history, showRegistration, user, userHasPermissions} = props;

  const navigate = (path) => {
    history.push(path);
  };

  const topMenuItems = [
    {name: 'Redeem Code', path: '/redeem-code', show: getSetting(showRedeemCode) && getSetting(showTopMenu) && showRegistration },
    {name: 'Register', path: '/registration', show: getSetting(showTopMenu) && showRegistration },
    {name: 'My Dashboard', path: '/dashboard', show: getSetting(showTopMenu) && user && userHasPermissions },
  ].filter(x => x.show);

  const mainMenuItems = [
    {name: 'Welcome', path: '/welcome', show: !getSetting(hidePublicPages) },
    {name: 'The Rules', path: '/the-rules', show: !getSetting(hidePublicPages) },
    {name: 'FAQS', path: '/faqs', show: !getSetting(hidePublicPages) },
    {name: 'Dashboard', path: '/home', show: getSetting(showDashboard) && user },
    {name: 'Events', path: '/events', show: getSetting(showEvents) && user },
    {name: 'Matchplay', path: '/dashboard', show: getSetting(showMatchplay) && user },
    {name: 'Members', path: '/members', show: getSetting(showMembers) && user },
  ].filter(x => x.show);

  return (
    <div>
      <MuiThemeProvider theme={tempTheme}>
        <AppBar position={'static'} color={'primary'}>
          <div className={classes.headerDense}>

            {
              !props.user &&
              <Button className={classes.headerDenseButton} onClick={AuthorisationService.signinRedirect}>
                Sign&nbsp;In&nbsp;
                <AccountCircle/>
              </Button>
            }

            {
              props.user &&
              <Button
                className={classes.headerDenseButton}
                aria-controls='user-menu'
                aria-haspopup={true}
                onClick={props.handleShowUserMenu}>
                {props.user.name}&nbsp;
                <AccountCircle/>
              </Button>
            }

            {
              props.user &&
              <Menu
                id='user-menu'
                open={props.showUserMenu}
                anchorEl={props.userMenuAnchor}
                onClose={() => props.handleToggleUserMenu(false)}
                PaperProps={{ style: { minWidth: props.userMenuAnchor ? props.userMenuAnchor.offsetWidth : 0}}}>
                <MenuItem onClick={AuthorisationService.changePassword}>Change Password</MenuItem>
                <MenuItem onClick={AuthorisationService.signoutRedirect}>Sign Out</MenuItem>
              </Menu>
            }

            {
              topMenuItems.map((item, key) =>
                (
                  <Hidden smDown key={key}>
                    <Button className={classes.headerDenseButton + ' ' + classes.marginRight10} onClick={() => navigate(item.path)}>
                      {item.name}
                    </Button>
                  </Hidden>
                ))
            }
          </div>

          <Toolbar>
            <img className={classes.logo + ' custom-logo'} src={process.env.REACT_APP_COMPETITIONGOLF_HEADER_LOGO_URL} alt={process.env.REACT_APP_COMPETITIONGOLF_HEADER_LOGO_ALT}/>

            <Hidden smDown>
              <div className={classes.textCenter + ' ' + classes.menuButtonContainer}>
                {
                  mainMenuItems.map((item, key) => (
                    <Button
                      className={classes.headerButton}
                      color={'inherit'}
                      onClick={() => navigate(item.path)}
                      key={key}>
                      {item.name}
                    </Button>
                  ))
                }
              </div>
            </Hidden>
            <Hidden mdUp>
              <IconButton className={classes.menuButton} color={'inherit'} onClick={() => props.setMenu(true)}>
                <MenuIcon/>
              </IconButton>
            </Hidden>
          </Toolbar>
        </AppBar>
      </MuiThemeProvider>

      <Hidden mdUp>
        <Drawer
          open={props.showMenu}
          anchor={'right'}
          onClick={() => props.setMenu(false)}
          variant='temporary'
          classes={{ paper: classes.paper }}>
          <List className={classes.list}>

            {
              [...topMenuItems, ...mainMenuItems].map((item, key) => (
                <ListItem
                  className={classes.listItem}
                  button
                  onClick={() => navigate(item.path)}
                  key={key}>
                  <ListItemText primary={item.name}/>
                </ListItem>
              ))
            }
          </List>
        </Drawer>
      </Hidden>
    </div>
  );
}

export default withRouter(withStyles(styles) (AppBarView));
