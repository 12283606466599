import React from 'react';
import { withRouter } from 'react-router-dom';
//components
import AccountRegistrationView from './account-registration-view';
//utils
import { OrganisationService } from '../../utils/data-transfer';

class AccountRegistrationContainer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      user: {},
      password: '',
      verifyPassword: '',
      newsletter: null,
      showPassword: false,
      registrationComplete: false
    };
    this._organisationService = new OrganisationService();
  }

  componentDidMount() {
    return this.getUserRegistrationData();
  }

  getUserRegistrationData = async () => {
    try {
      let response = await this._organisationService.getUserRegistrationData(this.props.accountCode);
      if (response.errorMessage)
        return this.props.history.push('/error?errorMessage=' + response.errorMessage);

      this.setState({ user: response, isLoading: false });
    } catch (err) {
      console.log(err);
      let errorMessage = 'An error has occurred, please try again later.';
      return this.props.history.push('/error?errorMessage=' + errorMessage);
    }
  };

  registerUser = async () => {
    try {
      this.setState({ isLoading: true })
      let response = await this._organisationService.registerUser({
        organisationMemberId: this.state.user.organisationMemberId,
        password: this.state.password,
        newsletterOptIn: this.state.newsletter
      });
      if (response.errorMessage)
        return this.props.history.push('/error?errorMessage=' + response.errorMessage);

      this.setState({ isLoading: false, registrationComplete: true });
    } catch (err) {
      console.log(err);
      let errorMessage = 'An error has occurred, please try again later.';
      return this.props.history.push('/error?errorMessage=' + errorMessage);
    }
  };

  handleChange = (e) => this.setState({ [e.target.name]: e.target.value });

  handleSetShowPassword = () => this.setState({ showPassword: !this.state.showPassword });

  handleSetNewsletter = newsletter => this.setState({ newsletter });

  render () {
    return (
        <AccountRegistrationView
          user={this.state.user}
          isLoading={this.state.isLoading}
          password={this.state.password}
          verifyPassword={this.state.verifyPassword}
          newsletter={this.state.newsletter}
          handleChange={this.handleChange}
          showPassword={this.state.showPassword}
          handleSetShowPassword={this.handleSetShowPassword}
          handleSetNewsletter={this.handleSetNewsletter}
          registerUser={this.registerUser}
          registrationComplete={this.state.registrationComplete}/>
    );
  }
}

export default withRouter(AccountRegistrationContainer);