import React from 'react';
import styles from './competition-styles';
//material
import { Container, Paper, Tabs, Tab, Typography, withStyles, Button, Grid } from '@material-ui/core';
//components
import Details from './details';
import Results from './results';
import Bracket from './bracket';
import EnterResults from './enter-results';
//utils
import { TeamPlayStatus } from '../../utils/constants';

function CompetitionView (props) {
  const classes = props.classes;

  return (
    <Container maxWidth={'md'}>
      <Grid container>
        <Grid className={classes.competitionHeaderGrid} item xs={12} sm={6}>
          <Typography variant={'h5'} className={'dark-mode-text'}>
            {props.details.competitionName ? props.details.competitionName : 'Competition Details' }
          </Typography>
        </Grid>

        <Grid className={classes.addResultsGrid + ' ' + classes.competitionHeaderGrid} item xs={12} sm={6}>
          <Button color={'primary'} variant={'contained'} disabled={props.details.teamPlayStatus.id !== TeamPlayStatus.Playing || !props.details.canScore} onClick={() => props.handleShowEnterResults(true)}>
            Enter Results
          </Button>
        </Grid>
      </Grid>

      <Paper>
        <Tabs
          value={props.tab}
          onChange={props.handleTabChange}
          indicatorColor={'primary'}
          textColor={'primary'}>
          <Tab value={0} label={'Summary'}/>
          { !props.details.hideBracketsInMatchplay && <Tab value={1} label={'Bracket'} disabled={props.isLoading}/>}
          <Tab value={2} label={'Results'} disabled={props.isLoading}/>
        </Tabs>
      </Paper>

      {
        props.tab === 0 &&
          <Details
            details={props.details}
            getCompetition={props.getCompetition}
            isLoading={props.isLoading}
            results={props.results}
            setLoading={props.setLoading}
            getClubsMatchingQuery={props.getClubsMatchingQuery}
            clubs={props.clubs}/>
      }

      {
        props.tab === 1 &&
          <Bracket
            details={props.details}
            bracketMatches={props.bracketMatches}
            rounds={props.rounds}/>
      }

      {
        props.tab === 2 &&
          <Results
            results={props.results}/>
      }

      {
        props.viewEnterResults &&
        <EnterResults
          viewEnterResults={props.viewEnterResults}
          handleShowEnterResults={props.handleShowEnterResults}
          resultsEntry={props.resultsEntry}
          handleDateChange={props.handleDateChange}
          autocompleteOpen={props.autocompleteOpen}
          handleAutocompleteOpen={props.handleAutocompleteOpen}
          getClubsMatchingQuery={props.getClubsMatchingQuery}
          clubs={props.clubs}
          handleClubChange={props.handleClubChange}
          details={props.details}
          handleResultChange={props.handleResultChange}
          handleResultsSubmission={props.handleResultsSubmission}
          resultsSubmissionLoading={props.resultsSubmissionLoading}/>
      }
    </Container>
  )
}

export default withStyles(styles) (CompetitionView);