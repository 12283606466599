import Axios from 'axios';

const competitionGolfApi = process.env.REACT_APP_COMPETITIONGOLF_PUBLIC_API_ENDPOINT;
const organisationId = process.env.REACT_APP_COMPETITIONGOLF_ORGANISATION_ID;

class OrganisationService {

  getUserCompetitions () {
    return Axios.get(competitionGolfApi + 'organisations/' + organisationId + '/competitions')
      .then(resp => resp.data)
      .catch(err => {
        if (err.response.status === 404)
          return [];

        window.rg4js('send', {
          error: err
        });
        throw err;
      });
  }

  getInvitedUserData (inviteCode) {
    return Axios.get(competitionGolfApi + 'organisations/' + organisationId + '/competitioninvitations/' + inviteCode)
      .then(resp => resp.data)
      .catch(err => {
        if (err.response.status === 400)
          return { errorMessage: err.response.data };

        window.rg4js('send', {
          error: err
        });
        throw err;
      });
  }

  checkUserPermissions () {
    return Axios.get(competitionGolfApi + 'organisations/' + organisationId + '/checkpermissions')
      .then(resp => resp.data)
      .catch (err => {
        if (err.response.status === 401)
          return false;

        window.rg4js('send', {
          error: err
        });

        return false;
      });
  }

  getOpenCompetitions () {
    return Axios.get(competitionGolfApi + 'organisations/' + organisationId + '/competitions/registrationopen')
      .then(resp => resp.data)
      .catch(err => {
        if (err.response.status === 404)
          return [];

        window.rg4js('send', {
          error: err
        });
        throw err;
      });
  }

  getUserRegistrationData (accountCode) {
    return Axios.get(competitionGolfApi + 'organisations/' + organisationId + '/users/registration/' + accountCode)
        .then(resp => resp.data)
        .catch(err => {
          if (err.response.status === 400)
            return { errorMessage: err.response.data };

          if (err.response.status === 404)
            return { errorMessage: 'We were unable to find a user with the provided account code. Please check your code and try again.' };

          window.rg4js('send', {
            error: err
          });
          throw err;
        });
  }

  registerUser (user) {
    return Axios.post(competitionGolfApi + 'organisations/' + organisationId + '/users', user)
        .catch(err => {
          window.rg4js('send', {
            error: err
          });
          throw err;
        });
  }

  resumeRegistration (teamCode) {
    return Axios.get(competitionGolfApi + 'organisations/' + organisationId + '/competitions/' + teamCode + '/resumeregistration')
      .then(resp => resp.data)
      .catch(err => {
        if (err.response.status === 400)
          return { errorMessage: err.response.data };

        window.rg4js('send', {
          error: err
        });
        throw err;
      });
  }

  viewPublicBracket (bracketCode) {
    return Axios.get(competitionGolfApi + 'organisations/' + organisationId + '/brackets/' + bracketCode)
      .then(resp => resp.data)
      .catch(err => {
        window.rg4js('send', {
          error: err
        });
        throw err;
      });
  }

  getUserDetails () {
    return Axios.get(competitionGolfApi + 'organisations/' + organisationId + '/users/details')
      .then(resp => resp.data)
      .catch(err => {
        window.rg4js('send', {
          error: err
        });
      });
  }

  getOrganisationSettings () {
    return Axios.get(competitionGolfApi + 'organisations/' + organisationId + '/settings')
      .then(resp => resp.data)
      .catch(err => {
        window.rg4js('send', {
          error: err
        });
      });
  }

  setStripeCheckoutPayment (paymentDetails) {
    return Axios.post(competitionGolfApi + 'organisations/' + organisationId + '/competitions/stripecheckout', paymentDetails)
      .then(resp => resp.data)
      .catch(err => {
        window.rg4js('send', {
          error: err
        });

        throw err;
      });
  }

  setPayPalPayment (paymentDetails) {
    return Axios.post(competitionGolfApi + 'organisations/' + organisationId + '/competitions/paypalcheckout', paymentDetails)
      .then(resp => resp.data)
      .catch(err => {
        window.rg4js('send', {
          error: err
        });

        throw err;
      });
  }

  setCodeCheckout (checkoutDetails) {
    return Axios.post(competitionGolfApi + 'organisations/' + organisationId + '/competitions/codecheckout', checkoutDetails)
      .then(resp => resp.data)
      .catch(err => {
        if (err.response.status === 400)
          return { errorMessage: err.response.data };

        window.rg4js('send', {
          error: err
        });

        throw err;
      });
  }

  validateDiscountCode (code) {
    return Axios.post(competitionGolfApi + 'organisations/' + organisationId + '/competitions/validatecode', code)
      .then(resp => resp.data)
      .catch(err => {
        if (err.response.status === 400)
          return { errorMessage: err.response.data };

        window.rg4js('send', {
          error: err
        });

        throw err;
      });
  }

  getMembers (filterQueryString, organisationGroupId) {
    let params = [
      filterQueryString ? 'filterQueryString=' + filterQueryString : null,
      organisationGroupId ? 'organisationGroupId=' + organisationGroupId : null];
    params = params.filter(p => p);

    return Axios.get(competitionGolfApi + 'organisations/' + organisationId + '/members/society?' + params.join('&'))
      .then(resp => resp.data)
      .catch (err => {
        if (err.response.status === 404)
          return [];

        window.rg4js('send', {
          error: err
        });

        throw err;
      });
  }

  getMember (organisationMemberId) {
    return Axios.get(competitionGolfApi + 'organisations/' + organisationId + '/member/' + organisationMemberId)
      .then(resp => resp.data)
      .catch(err => {
        window.rg4js('send', {
          error: err
        });
        throw err;
      });
  }

  getUpcomingEvents (filter) {
    let filterParam = filter ? 'filterQueryString=' + filter : '';

    return Axios.get(competitionGolfApi + 'organisations/' + organisationId + '/events/upcoming?' + filterParam)
      .then(resp => resp.data)
      .catch(err => {
        if (err.response.status === 404)
          return {results: []};

        window.rg4js('send', {
          error: err
        });
        throw err;
      });
  }
}

export default OrganisationService;
