import React from 'react';
import styles from '../registration-styles';
//material
import { Dialog, DialogTitle, DialogContent, List, ListItem, ListItemText, withStyles } from '@material-ui/core';

function SelectCompetitionView (props) {
  const classes = props.classes;

  return (
    <Dialog
      maxWidth={'sm'}
      fullWidth
      open={props.showSelectCompetition}>
      <DialogTitle
        classes={{ root: classes.competitionDialogHeaderRoot }}>
        Please select a Competition to register for
      </DialogTitle>
      <DialogContent
        classes={{ root: classes.competitionDialogRoot }}>
        <List>
          {
            props.competitions.map((competition, key) =>
              <ListItem key={key} button onClick={() => props.setCompetition(competition)}>
                <ListItemText primary={competition.name}/>
              </ListItem>)
          }
        </List>
      </DialogContent>
    </Dialog>
  )
}

export default withStyles(styles) (SelectCompetitionView);