import React from 'react';
import styles from '../details-styles';
//material
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Grid,
  withStyles,
  TextField,
  Tooltip
} from '@material-ui/core';
//components
import AvatarEditor from '../../../avatar-editor';

function EditPlayerView (props) {
  const classes = props.classes;

  return (
    <Dialog
      maxWidth={'sm'}
      open={props.showEditPlayerDialog}
      onClose={() => props.handleShowDialog(false)}
      fullWidth>
      <DialogTitle>Edit Player</DialogTitle>
      <DialogContent>
        <Grid container justify={'center'}>
          <Grid item xs={12} sm={6} className={classes.marginBottom10}>
            <AvatarEditor
              image={props.image ? 'data:image/png;base64,' + props.image.data : props.photoUrl}
              onImageChange={props.handleChangedImage}
              height={150}
              width={150}
              commitOnChangeOnly/>

            <Grid item xs={12} className={classes.textCenter}>
              <input
                accept='image/jpeg'
                style={{ display: 'none' }}
                id='raised-button-file'
                multiple
                type='file'
                onChange={props.handleImageUpload}
              />
              <label htmlFor='raised-button-file'>
                <Button color={'primary'} size={'small'} aria-label={'small'} variant={'contained'} component='span'>
                  Upload
                </Button>
              </label>
            </Grid>

            {
              props.showImageSizeWarning &&
              <Grid item xs={12} className={classes.textCenter + ' ' + classes.errorText}>
                uploaded image must be less than 4MB
              </Grid>
            }
          </Grid>

          <Grid item xs={12} sm={6}>
            <Grid item xs={12} sm={11} className={classes.marginBottom10}>
              <TextField
                fullWidth
                label={'First Name'}
                value={props.player.firstName ? props.player.firstName : ''}
                name={'firstName'}
                onChange={props.handleChange}
                variant={'outlined'}/>
            </Grid>

            <Grid item xs={12} sm={11} className={classes.marginBottom10}>
              <TextField
                fullWidth
                label={'Last Name'}
                value={props.player.lastName ? props.player.lastName : ''}
                name={'lastName'}
                onChange={props.handleChange}
                variant={'outlined'}/>
            </Grid>

            <Grid item xs={12} sm={11} className={classes.marginBottom10}>
              <TextField
                fullWidth
                label={'Telephone'}
                value={props.player.phone ? props.player.phone : ''}
                name={'phone'}
                onChange={props.handleChange}
                variant={'outlined'}/>
            </Grid>

            <Grid item xs={5} className={classes.marginBottom10}>
              <Tooltip title={'Please enter your WHS handicap index.'}>
                <TextField
                  fullWidth
                  label={'Handicap Index'}
                  placeholder={'(ex: 10.7)'}
                  value={props.player.handicap}
                  name={'handicap'}
                  onChange={props.handleChange}
                  variant={'outlined'}
                  type={'number'}/>
              </Tooltip>
            </Grid>
          </Grid>

        </Grid>
      </DialogContent>
      <DialogActions>
        <Button color={'primary'} onClick={() => props.handleShowDialog(false)}>Cancel</Button>
        <Button
          color={'primary'}
          onClick={props.handleConfirmButton}
          disabled={props.showImageSizeWarning || props.player.handicap === '' || !props.player.lastName || !props.player.firstName}>
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default withStyles(styles) (EditPlayerView);
