import CoreStyles from '../../utils/styles/core-styles';

const TeamStyles = theme => ({
  cardBodyPadding: {
    padding: '24px 26px 0'
  },
  cardHeader: {
    fontSize: 'h6',
    fontWeight: 'bold',
    color: theme.palette.primary.contrastText,
    backgroundColor: theme.palette.primary.main,
    textAlign: 'center',
    padding: '10px 0'
  },
  cardBar: {
    fontSize: 15,
    marginBottom: 5,
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden'
  },
  cardContent: {
    position: 'relative',
    padding: 0
  },
  wizardEdit: {
    width: 'fit-content',
    position: 'absolute',
    right: 5,
    top: 8,
    fontSize: '12px',
    cursor: 'pointer'
  },
  ...CoreStyles(theme)
});

export default TeamStyles;