import React from 'react';
//components
import View from './edit-player-view';
//utils
import { DefaultGravatarUrl } from '../../../../utils/constants';

class EditPlayerContainer extends React.Component {
  constructor (props) {
    super (props);

    this.state = {
      image: '',
      showImageSizeWarning: false
    };
  }

  handleChangedImage = (changedImage) => this.changedImage = changedImage;

  handleImageUpload = async (e) => {
    if (!e.target.files.length)
      return;

    const size = e.target.files[0].size;
    const name = e.target.files[0].name;
    if (size > 4194304)
      return this.setState({ showImageSizeWarning: true });

    const fileData = await this.getBase64(e.target.files[0]);
    const file = {
      format: name.substr(name.lastIndexOf('.') + 1),
      data: fileData
    };

    this.setState({ image: file, showImageSizeWarning: false });
  };

  getBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsBinaryString(file);
      reader.onload = () => resolve(window.btoa(reader.result));
      reader.onerror = error => reject(error);
    });
  };

  handleConfirmButton = () => {
    this.props.handleEditPlayerSubmit(this.changedImage);

    this.props.handleShowDialog(false);
  };

  render () {
    return (
      <View
        details={this.props.details}
        photoUrl={this.props.player.photoUrl ? this.props.player.photoUrl : DefaultGravatarUrl}
        showEditPlayerDialog={this.props.showEditPlayerDialog}
        image={this.state.image}
        player={this.props.player}
        showImageSizeWarning={this.state.showImageSizeWarning}
        handleShowDialog={this.props.handleShowDialog}
        handleChangedImage={this.handleChangedImage}
        handleImageUpload={this.handleImageUpload}
        handleConfirmButton={this.handleConfirmButton}
        handleChange={this.props.handleEditPlayerChange}/>
    );
  }
}

export default EditPlayerContainer;