import React from 'react';
//material
import {Card, CardContent, withStyles, Divider, Button} from '@material-ui/core';
import {Skeleton} from '@material-ui/lab';
//styles
import styles from './event-card-styles';
//lib
import Moment from 'moment';

let bookingUrl = process.env.REACT_APP_COMPETITIONGOLF_TEMP_BOOKING_URL;
bookingUrl = (!bookingUrl.startsWith('#{') && !bookingUrl.endsWith('}#')) ? bookingUrl : null;

const EventCardView = (props) => {
  const {classes, isLoading, event} = props;

  return (
    <Card>
      <CardContent>
        {
          isLoading ?
            <Skeleton height={40} width={'60%'} /> :
            <div className={classes.eventName}>
              { event.eventName }
            </div>
        }

        {
          !isLoading &&
            <div className={classes.price}>
              {event.entryCost ? (event.currencySymbol + event.entryCost.toFixed(2)) : ''}
            </div>
        }

        {
          !isLoading && event.canBook &&
          <Button
            className={classes.bookButton}
            color={'primary'}
            size={'small'}
            aria-label={'small'}
            variant={'contained'}
            href={bookingUrl}
            target={'_blank'}
            rel={'noopener'}>
            Book
          </Button>
        }

        {
          isLoading ?
            <Skeleton height={22} width={'40%'} /> :
            <div className={classes.subheader}>
              {event.eventDate ? Moment(new Date(event.eventDate)).format('Do MMMM YYYY') : ''}
            </div>
        }

        {
          isLoading ?
            <Skeleton height={22} width={'40%'} /> :
            <div className={classes.subheader}>
              {event.club.name}
            </div>
        }

        <Divider className={classes.divider} />

        {
          isLoading ?
            <Skeleton height={52} width={'40%'} className={classes.description} /> :
            <div className={classes.description}>
              {event.eventDescription}
            </div>
        }
      </CardContent>
    </Card>
  );
};

export default withStyles(styles) (EventCardView);
