import React, {useEffect, useState} from 'react';
//components
import View from './event-view';
//utils
import {Event} from '../../../utils/data-transfer';

const EventContainer = (props) => {
  const {match} = props;
  const [state, setState] = useState({
    isLoading: true,
    event: null,
    agendaItems: [],
    prizes: null
  });

  useEffect(() => {
    const bootstrapComponent = async () => {
      const resp = await Promise.all([
        await Event.getEventDetails(match.params.eventId),
        await Event.getAgenda(match.params.eventId),
        await Event.getPrizes(match.params.eventId)
      ]);

      await setState((prevState => ({...prevState, isLoading: false, event: resp[0], agendaItems: resp[1], prizes: resp[2]})));
    };

    bootstrapComponent();
  }, [match.params.eventId]);

  return (
    <View
      {...state}/>
  );
};

export default EventContainer;
