import React from 'react';
import styles from './app-content-styles.js';
import _ from 'lodash';
//Routing
import { GetRoutes } from '../../routes';
import { Route, Redirect, Switch, withRouter } from 'react-router-dom';
//Material
import { withStyles } from '@material-ui/core';

let hidePublicPages = process.env.REACT_APP_HIDE_PUBLIC_PAGES;
hidePublicPages = (!hidePublicPages.startsWith('#{') && !hidePublicPages.endsWith('}#')) ? JSON.parse(hidePublicPages) : false;

function AppContentView (props) {
  const classes = props.classes;
  const publicDefaultRoute = hidePublicPages ? '/sign-in' : '/welcome'
  const noPaddingPaths = [
    'event/'
  ];
  const hidePadding = !props.location.pathname || _.find(noPaddingPaths, p => props.location.pathname.includes(p));

  return (
    <div className={classes.siteContent + ' ' + (hidePadding ? classes.noPadding : '')} id={'app-container'}>
      <Switch>
        {
          GetRoutes(props.user, props.settings.checkoutTypeId === 2).map((props, index) =>
            <Route path={props.path} key={index} render={p =>
              <props.component key={index} {...p}/>
            }/>)
        }
        <Route path={'/'} render={() => <Redirect to={props.user ? '/dashboard' : publicDefaultRoute}/>}/>
      </Switch>
    </div>
  )
}

export default withRouter(withStyles(styles) (AppContentView));
