import React, {useState, useEffect, useRef} from 'react';
//components
import View from './events-view';
//utils
import {OrganisationService} from '../../../utils/data-transfer';
//library utils
import _ from 'lodash';

const _organisationService = new OrganisationService();

const EventsContainer = () => {
  const [state, setState] = useState({
    isLoading: true,
    filterText: '',
    events: []
  });

  useEffect(() => {
    const bootstrapComponent = async () => {
      await getEvents();
      await setState((prevState => ({...prevState, isLoading: false})));
    };

    bootstrapComponent();
  }, []);

  const getEvents = async (filterText) => {
    try {
      let response = await _organisationService.getUpcomingEvents(filterText);
      setState((prevState => ({...prevState, events: response.results})));
    } catch (ex) {
      console.log(ex);
    }
  };

  const debounceGetEvents = useRef(_.debounce(getEvents, 1000));

  const handleSearchChange = (e) => {
    const filterText = e.target.value;
    setState(prevState => ({...prevState, filterText}));
    return debounceGetEvents.current(filterText);
  };

  return (
    <View
      {...state}
      handleSearchChange={handleSearchChange}/>
  );
}

export default EventsContainer;
