import React from 'react';
//material
import {Card, CardContent, withStyles} from '@material-ui/core';
import {Skeleton} from '@material-ui/lab';
//styles
import styles from './details-card-styles';

const DetailsCardView = (props) => {
  const {classes, isLoading, event} = props;
  let scoringFormatTypes = [];
  if (event) {
    if (event.hasGroupTeams)
      scoringFormatTypes.push('Groups');

    if (event.hasIndividuals)
      scoringFormatTypes.push('Individuals');
  }

  return (
    <Card>
      <CardContent>
        {
          isLoading ?
            <Skeleton height={40} width={'60%'} /> :
            <div className={classes.title}>
              Details
            </div>
        }

        {
          isLoading ?
            <div className={classes.skeletonTable}>
              <Skeleton height={23} className={classes.skeletonRow}/>
              <Skeleton height={23} className={classes.skeletonRow}/>
              <Skeleton height={23} className={classes.skeletonRow}/>
              <Skeleton height={23} className={classes.skeletonRow}/>
              <Skeleton height={23} className={classes.skeletonRow}/>
              <Skeleton height={23} className={classes.skeletonRow}/>
            </div> :
            <table className={classes.table}>
              <tbody>
              <tr>
                <td className={classes.column}>Format</td>
                <td className={classes.data}>{event.eventFormat.name}</td>
              </tr>

              <tr>
                <td className={classes.column}>Scoring Format</td>
                <td className={classes.data}>{scoringFormatTypes.join(' & ')}</td>
              </tr>

              {
                event.hasGroupTeams &&
                <tr>
                  <td className={classes.column}>Group Scoring</td>
                  <td className={classes.data}>{(event.groupSize.name + ' - ' + event.scoringType.name)}</td>
                </tr>
              }

              <tr>
                <td className={classes.column}>Handicap Allowance</td>
                <td className={classes.data}>{event.handicapAllowance.name}</td>
              </tr>

              <tr>
                <td className={classes.column}>Men's Tee</td>
                <td className={classes.data}>{event.teeTypeMale.name}</td>
              </tr>

              <tr>
                <td className={classes.column}>Ladies Tee</td>
                <td className={classes.data}>{event.teeTypeFemale.name}</td>
              </tr>
              </tbody>
            </table>
        }
      </CardContent>
    </Card>
  );
};

export default withStyles(styles) (DetailsCardView);
