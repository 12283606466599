import CoreStyles from '../../../utils/styles/core-styles';

const BracketStyles = theme =>(
  {
    bracket: {
      paddingTop: 10,
      display: 'inline-block',
      fontSize: props => props.elementSize + 'px'
    },
    round: {
      display: 'inline-block',
      verticalAlign: 'middle'
    },
    bracketMatches: {
      '& > div': {
        display: 'inline-block',
        verticalAlign: 'middle'
      }
    },
    matchups: {
      '& $match:first-child': {
        marginBottom: '2em'
      },
      display: 'inline-block',
      width: 125
    },
    match: {
      borderRadius: 4,
      overflow: 'hidden',
      backgroundColor: '#ffffff',
      '& $team:first-child': {
        borderBottom: 'thin solid #f0f2f2'
      }
    },
    team: {
      fontSize:  '10px',
      height: props => props.elementSize * 1.5,
      lineHeight: props => props.elementSize * 1.5 + 'px',
      borderLeft: '4px solid #858585',
      paddingLeft: 10,
      width: 'calc(100% - 16px)'
    },
    teamName: {
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      width: 'calc(100% - 30px)',
      maxWidth: 'calc(100% - 30px)',
      display: 'inline-block'
    },
    teamType: {
      width: 30,
      float: 'right',
      display: 'inline-block',
      fontSize: '12px'
    },
    winningTeam: {
      borderLeftColor: process.env.REACT_APP_COMPETITIONGOLF_WINNING_TEAM_COLOUR,
      color: process.env.REACT_APP_COMPETITIONGOLF_WINNING_TEAM_COLOUR
    },
    losingTeam: {
      borderLeftColor: process.env.REACT_APP_COMPETITIONGOLF_LOSS_TEAM_COLOUR,
      color: '#a0a0a0',
      textDecoration: 'line-through'
    },
    merger: {
      height: '5em',
      width: '2em',
      borderTop: 'thin solid #858585',
      borderRight: 'thin solid #858585',
      borderBottom: 'thin solid #858585',
      display: 'inline-block',
      verticalAlign: 'top'
    },
    line: {
      height: '2.5em',
      width: '2em',
      borderBottom: 'thin solid #858585',
      display: 'inline-block',
      verticalAlign: 'top'
    },
    headerContainer: {
      margin: '10px 0 20px',
      '& $columnHeader:not(:last-child)': {
        marginRight: '4em'
      }
    },
    columnHeader: {
      display: 'inline-block',
      fontSize: '18px',
      fontWeight: 500,
      width: 124,
      textAlign: 'center'
    },
    columnSubheader: {
      fontSize: '12px',
      fontWeight: 'normal'
    },
    round1: {
      '& $bracketMatches:not(:last-child)': {
        marginBottom: '2em'
      },
      '& $match:not(:last-child)': {
        marginBottom: '2em'
      }
    },
    round2: {
      '& $bracketMatches:not(:last-child)': {
        marginBottom: '7em'
      },
      '& $match:not(:last-child)': {
        marginBottom: '7em'
      },
      '& $merger': {
        height: '10em'
      },
      '& $line': {
        height: '5em'
      }
    },
    round3: {
      '& $bracketMatches:not(:last-child)': {
        marginBottom: '17em'
      },
      '& $match:not(:last-child)': {
        marginBottom: '17em'
      },
      '& $merger': {
        height: '20em'
      },
      '& $line': {
        height: '10em'
      }
    },
    round4: {
      '& $bracketMatches:not(:last-child)': {
        marginBottom: '37em'
      },
      '& $match:not(:last-child)': {
        marginBottom: '37em'
      },
      '& $merger': {
        height: '40em'
      },
      '& $line': {
        height: '20em'
      }
    },
    round5: {
      '& $bracketMatches:not(:last-child)': {
        marginBottom: '77em'
      },
      '& $match:not(:last-child)': {
        marginBottom: '77em'
      },
      '& $merger': {
        height: '80em'
      },
      '& $line': {
        height: '40em'
      }
    },
    bracket64: {
      '& $matchups': {
        width: 135
      },
      '& $team': {
        fontSize: '12px'
      },
      '& $teamType': {
        fontSize: '10px',
        width: 18,
        textAlign: 'center'
      },
      '& $teamName': {
        width: 'calc(100% - 18px)',
        maxWidth: 'calc(100% - 18px)',
      },
      '& $merger': {
        width: '0.5em'
      },
      '& $line': {
        width: '0.5em'
      },
      '& $columnHeader': {
        width: 134,
        fontSize: '16px'
      },
      '& $columnHeader:not(:last-child)': {
        marginRight: '1em'
      },
    },
    bracket16: {
      '& $matchups': {
        width: 160
      },
      '& $team': {
        fontSize: '14px'
      },
      '& $teamType': {
        fontSize: '12px'
      },
      '& $columnHeader': {
        width: 152
      }
    },
    bracket32: {
      '& $matchups': {
        width: 129
      },
      '& $team': {
        fontSize: '12px'
      },
      '& $teamType': {
        fontSize: '10px',
        width: 20,
        textAlign: 'center'
      },
      '& $teamName': {
        width: 'calc(100% - 20px)',
        maxWidth: 'calc(100% - 20px)',
      },
      '& $columnHeader': {
        width: 129,
        fontSize: '16px'
      },
      '& $columnHeader:not(:last-child)': {
        marginRight: '3em'
      }
    },
    ...CoreStyles(theme)
  });

export default BracketStyles;