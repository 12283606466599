import React from 'react';
//components
import Container from './registration-container';
//redux
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { setRegistration, updateRegistration } from '../../utils/redux/registration/registration-actions';

class RegistrationRedux extends React.Component {

  setRegistration = this.props.setRegistration;
  updateRegistration = this.props.updateRegistration;

  handleUpdateRegistration = (e) => this.props.updateRegistration({[e.target.name]: e.target.value});

  handleUpdateRegistrationCheck = (e) => this.props.updateRegistration({[e.target.name]: e.target.checked});

  handleClubSelection = (clubId) => this.props.updateRegistration({clubId});

  handleRegistrationChange = (name, value) => this.props.updateRegistration({[name]: value});

  render () {
    return (
      <Container
        inviteCode={this.props.inviteCode}
        teamCode={this.props.teamCode}
        setRegistration={this.setRegistration}
        updateRegistration={this.updateRegistration}
        registration={this.props.registration}
        handleUpdateRegistration={this.handleUpdateRegistration}
        handleUpdateRegistrationCheck={this.handleUpdateRegistrationCheck}
        handleClubSelection={this.handleClubSelection}
        handleRegistrationChange={this.handleRegistrationChange}/>
    );
  }
}

const mapStateToProps = state => {
  return {
    registration: state.registration
  }
};

function mapDispatchToProps (dispatch) {
  return {
    setRegistration: bindActionCreators(setRegistration, dispatch),
    updateRegistration: bindActionCreators(updateRegistration, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps) (RegistrationRedux);