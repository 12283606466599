import React from 'react';
//utils
import AuthorisationService from '../../utils/authorisation/authorisationService';

class SignIn extends React.Component {
  componentDidMount() {
    AuthorisationService.signinRedirect();
  }

  render() {
    return (
      <div/>
    );
  }
}

export default SignIn;
