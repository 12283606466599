import React from 'react';
import queryString from 'query-string';
import styles from './invalid-code-styles';
//material
import { Container, Card, CardContent, withStyles, Typography } from '@material-ui/core';
import { Error } from '@material-ui/icons';

class InvalidCode extends React.Component {
  render () {
    let errorMessage = queryString.parse(this.props.location.search).errorMessage;
    const classes = this.props.classes;

    return (
      <Container maxWidth={'sm'}>
        <Card className={this.props.classes.marginTop20 + ' ' + this.props.classes.marginBottom40}>
          <div className={this.props.classes.header}>
            Error
          </div>

          <CardContent className={classes.textCenter}>
            <Error className={classes.errorIcon}/>
            <Typography className={classes.errorBody} variant={'body1'}>{errorMessage}</Typography>
          </CardContent>
        </Card>
      </Container>
    )
  }
}

export default withStyles(styles) (InvalidCode);