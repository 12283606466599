import React from 'react';
import styles from './dashboard-styles';
import _ from 'lodash';
//material
import { Grid, withStyles, Container, Paper } from '@material-ui/core';
import { Skeleton, ToggleButtonGroup, ToggleButton } from '@material-ui/lab';
//components
import Competition from './competition';

function DashboardView (props) {
  const classes = props.classes;
  let competitions = props.competitions;
  let multipleDisplayModesRequired = (_.find(props.competitions, c => c.isPast) &&
    _.find(props.competitions, c => !c.isPast));

  if (multipleDisplayModesRequired)
    competitions = competitions.filter(c => {
      return (props.toggleState === '0' && !c.isPast) || (props.toggleState === '1' && c.isPast);
    });

  return (
    <Container maxWidth='md'>
      <Grid container className={classes.gridContainer}>
        {
          multipleDisplayModesRequired &&
          <Grid item xs={12} className={classes.textCenter + ' ' + classes.marginBottom20}>
            <ToggleButtonGroup value={props.toggleState} onChange={props.setToggleState} exclusive>
              <ToggleButton value={'0'} className={classes.toggleButton}>
                Current
              </ToggleButton>
              <ToggleButton value={'1'} className={classes.toggleButton}>
                Past
              </ToggleButton>
            </ToggleButtonGroup>
          </Grid>
        }

        {
          !props.isLoading && competitions.length > 0 &&
            competitions.map((competition, i) =>
              <Grid item xs={12} key={i}>
                <Competition
                  competition={competition}
                  viewCompetition={props.viewCompetition}
                  completeRegistration={props.completeRegistration}/>
              </Grid>)
        }

        {
          !props.isLoading && competitions.length === 0 &&
            <div className={classes.emptyListView}>
              No Competitions to show
            </div>
        }

        {
          props.isLoading &&
            <Grid item xs={12}>
              <SkeletonLoadingView classes={classes}/>
              <SkeletonLoadingView classes={classes}/>
              <SkeletonLoadingView classes={classes}/>
            </Grid>
        }
      </Grid>
    </Container>
  );
}

export default withStyles(styles) (DashboardView);

function SkeletonLoadingView (props) {
  const classes = props.classes;

  return (
    <Paper className={classes.skeletonContainer} elevation={0}>
      <Skeleton height={40} width={'100%'}/>
      <Skeleton height={25} width={'60%'}/>
      <Skeleton height={25} width={'50%'}/>
      <Skeleton className={classes.skeletonButton} variant={'rect'} height={40} width={70}/>
    </Paper>
  )
}