import CoreStyles from '../../../utils/styles/core-styles';

const CompetitionStyles = theme => ({
  contentSpace: {
    marginTop: 30
  },
  competition: {
    minHeight: 125,
    marginBottom: 20
  },
  gridContainer: {
    position: 'relative',
    height: 85
  },
  buttonContainer: {
    width: '100%',
    height: 'fit-content',
    marginTop: 18
  },
  buttonContainerEliminated: {
    marginTop: 5,
    [theme.breakpoints.down('xs')]: {
      marginTop: 17
    }
  },
  bottomFooterContainer: {
    width: '100%',
    height: 'fit-content',
    marginTop: 18,
    [theme.breakpoints.down('xs')]: {
      marginTop: 27
    }
  },
  buttonWinner: {
    float: 'right'
  },
  bodyTextContainer: {
    width: 'calc(100% - 64px)',
    display: 'inline-block'
  },
  bodyText: {
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    width: '100%',
    fontSize: '14px',
    display: 'inline-block',
    [theme.breakpoints.down('xs')]: {
      fontSize: '12px'
    }
  },
  warning: {
    fontSize: 12,
    padding: 5,
    marginTop: 10
  },
  contentRoot: {
    // paddingBottom: [16, '!important']
  },
  competitionName: {
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    fontSize: '25px',
    fontWeight: 400,
    [theme.breakpoints.down('xs')]: {
      fontSize: '18px'
    }
  },
  teamsRemaining: {
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    [theme.breakpoints.down('xs')]: {
      fontSize: '14px'
    }
  },
  competitionPlayStatus: {
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    fontSize: '20px',
    fontWeight: 400,
    [theme.breakpoints.down('xs')]: {
      fontSize: '16px'
    }
  },
  competitionPlaySubtitle: {
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    fontSize: '14px',
    marginTop: -6,
    [theme.breakpoints.down('xs')]: {
      fontSize: '12px'
    }
  },
  leftHeader: {
    width: '50%',
    textAlign: 'left',
    display: 'inline-block'
  },
  rightHeader: {
    width: '50%',
    textAlign: 'right',
    display: 'inline-block'
  },
  fullHeader: {
    width: '100%',
    textAlign: 'left',
    display: 'inline-block'
  },
  infoText: {
    fontWeight: 'bold',
    color: '#000000',
    backgroundColor: process.env.REACT_APP_COMPETITIONGOLF_NEUTRAL_COLOUR,
    textAlign: 'center',
    padding: '10px 0',
    fontSize: '12px',
    [theme.breakpoints.down('xs')]: {
      fontSize: '10px'
    }
  },
  warningText: {
    backgroundColor: process.env.REACT_APP_COMPETITIONGOLF_WARNING_COLOUR
  },
  matchText: {
    width: '50%',
    [theme.breakpoints.down('sm')]: {
      width: '100%'
    }
  },
  organisationText: {
    fontSize: '14px'
  },
  ...CoreStyles(theme)
});

export default CompetitionStyles;