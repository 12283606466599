import React from 'react';
//Components
import Card from '../../components/card';
//Material
import { CardHeader } from '@material-ui/core';

class SessionExpired extends React.Component {
  render () {
    return (
      <Card>
        <CardHeader
          title={'Session Expired'}/>
      </Card>
    );
  }
}

export default SessionExpired;
