import React from 'react';
//components
import View from './payment-view';
//stripe
import { loadStripe } from '@stripe/stripe-js';
//utils
import { CompetitionService } from '../../../utils/data-transfer';

const stripePublicKey = process.env.REACT_APP_COMPETITIONGOLF_STRIPE_PUBLIC_KEY;
const stripePromise = loadStripe(stripePublicKey);

class PaymentContainer extends React.Component {
  constructor (props) {
    super (props);

    let paymentError = localStorage.getItem('paymentError');
    if (paymentError)
      localStorage.setItem('paymentError', '');

    this.state = {
      showStripe: false,
      paymentError
    };

    this._competitionService = new CompetitionService();
  }

  handleStripeCheckout = async () => {
    try {
      const stripe = await stripePromise;
      const details = await this._competitionService.getCompetitionStripeSku(this.props.registration.competitionId, this.props.registration.competitionTeamId);

      const { error } = await stripe.redirectToCheckout({
        lineItems: [
          {price: details.stripeSku, quantity: 1}
        ],
        mode: 'payment',
        successUrl: 'https://' + window.location.host + '/registration-complete',
        cancelUrl: 'https://' + window.location.host + '/resume-registration/' + this.props.completeDetails.teamCode,
        clientReferenceId: details.clientReferenceId,
        customerEmail: this.props.registration.leadPlayerEmail
      });
    } catch (err) {
      console.log(err);
      this.setState({ paymentError: 'We were unable to process your Credit or Debit card payment at this time, please wait a short while and try again.'});
    }
  };

  render () {
    return (
      <View
        showStripe={this.state.showStripe}
        handleStripeCheckout={this.handleStripeCheckout}
        paymentError={this.state.paymentError}/>
    );
  }
}

export default PaymentContainer;