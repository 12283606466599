import React, { useEffect } from 'react';
import _ from 'lodash';
import styles from '../registration-styles';
//material
import { Grid, Container, withStyles, Card, CardContent } from '@material-ui/core';
//components
import Team from '../../team';
import TeamPlayer from '../../team-player';

function SummaryView (props) {
  const classes = props.classes;
  let club = _.find(props.clubs, c => c.clubId === props.registration.clubId);

  let team = { pairName: props.registration.pairName, clubName: club ? club.name : '' };
  let leadPlayer = {
    name: props.registration.leadPlayerFirstName + ' ' + props.registration.leadPlayerLastName,
    handicap: props.registration.leadPlayerHandicap,
    email: props.registration.leadPlayerEmail,
    phone: props.registration.leadPlayerPhone
  };

  let additionalPlayer = {
    name: props.registration.additionalPlayerFirstName + ' ' + props.registration.additionalPlayerLastName,
    handicap: props.registration.additionalPlayerHandicap,
    email: props.registration.additionalPlayerEmail,
    phone: props.registration.additionalPlayerPhone
  };

  useEffect(() => {
    if (!props.registration.pairName)
      props.handleRegistrationChange('pairName', leadPlayer.name);
  });

  return (
    <Container maxWidth={'sm'} classes={{ root: classes.containerPadding }}>
      <Card className={classes.marginBottom10}>
        <div className={classes.slideHeader}>
          Summary
        </div>

        <CardContent>
          <p className={classes.slideInfo + ' ' + classes.marginBottom0}>
            { props.validationError ? 'Please resolve any issues before you can complete registration' : 'Please check the following details are correct.'}
          </p>
        </CardContent>

        {
          props.validationError &&
          <div className={classes.warningText}>
            {props.validationError}
          </div>
        }
      </Card>

      <Grid container>
        <Grid item xs={12}>
          <Team
            isWizardView
            handleWizardEditClick={() => props.setActiveStep(0)}
            details={team}
            isLoading={props.isLoading}/>
        </Grid>

        <Grid item xs={12}>
          <TeamPlayer
            isLeadPlayer
            isWizardView
            handleWizardEditClick={() => props.setActiveStep(1)}
            player={leadPlayer}
            isLoading={props.isLoading}/>
        </Grid>

        {
          !props.isSinglePlayerGame &&
          <Grid item xs={12}>
            <TeamPlayer
                isWizardView
                handleWizardEditClick={() => props.setActiveStep(2)}
                player={additionalPlayer}
                isLoading={props.isLoading}/>
          </Grid>
        }
      </Grid>
    </Container>
  );
}

export default withStyles(styles) (SummaryView);