import React from 'react';
import ExpressCheckoutCompleteContainer from '../../components/express-checkout-complete';

class ExpressCheckoutComplete extends React.Component {
  render () {
    return (
      <ExpressCheckoutCompleteContainer/>
      );
  }
}

export default ExpressCheckoutComplete;