const EventStyles = theme => ({
  tabBar: {
    marginTop: 20
  },
  heroImage: {
    backgroundSize: '100%',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    height: 500,
    [theme.breakpoints.down('md')]: {
      height: 425,
    },
    [theme.breakpoints.down('xs')]: {
      height: 215,
    },
    position: 'relative'
  },
  container: {
    position: 'relative',
    marginTop: -60,
    paddingBottom: 24,
  }
});

export default EventStyles;
