import React from 'react';
import styles from './view-opponent-styles';
//material
import { Grid, withStyles, Dialog, DialogContent, DialogTitle, DialogActions, Button } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
//component
import TeamPlayer from '../../../team-player';
import Result from '../../results/result';

function ViewOpponentView (props) {
  const classes = props.classes;

  return (
    <Dialog
      maxWidth={'md'}
      fullWidth
      open={props.viewOpponent}
      onClose={() => props.handleSetViewOpponent(false)}
      classes={{ paper: classes.viewPaper }}>
      <DialogTitle className={classes.dialogHeader + ' dark-mode-text'}>
        { !props.isLoadingOpponent ? <div>{props.opponent.pairName}</div> : <Skeleton className={classes.alignSelfCenter} width={'50%'}/>}
        { !props.isLoadingOpponent ? <div className={classes.headerSubtitle}>{props.opponent.clubName}</div> : <Skeleton className={classes.alignSelfCenter} width={'40%'}/>}
      </DialogTitle>
      <DialogContent>
        <Grid container>
          <Grid item xs={12} md={props.opponent.additionalPlayer ? 6 : 12} className={classes.gridItemLeft}>
            <TeamPlayer
              isLeadPlayer
              player={props.opponent.leadPlayer}
              isLoading={props.isLoadingOpponent}
              emailLink
              displayAvatar/>
          </Grid>
          {
            props.opponent.additionalPlayer &&
            <Grid item xs={12} md={6} className={classes.gridItemRight}>
              <TeamPlayer
                  player={props.opponent.additionalPlayer}
                  isLoading={props.isLoadingOpponent}
                  emailLink
                  displayAvatar/>
            </Grid>
          }
        </Grid>

        <Grid container>
          <Grid item xs={12} className={classes.marginTop20}>
            {
              props.opponent.results.length > 0 && props.opponent.results.map((result, key) =>
                <Result key={key} result={result}/>)
            }

            {
              props.opponent.results.length === 0 &&
              <div className={classes.emptyListView + ' dark-mode-text'}>
                No Results to show
              </div>
            }
          </Grid>
        </Grid>
      </DialogContent>

      <DialogActions>
        <Button color={'primary'} onClick={() => props.handleSetViewOpponent(false)}>Close</Button>
      </DialogActions>

    </Dialog>
  );
}

export default withStyles(styles) (ViewOpponentView);