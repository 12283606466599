const CoreStyles = theme => ({
  alignRight: {
    float: 'right'
  },
  alignLeft: {
    float: 'left'
  },
  fullHeight: {
    height: '100%'
  },
  ellipsis: {
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden'
  },
  textRight: {
    textAlign: 'right'
  },
  textCenter: {
    textAlign: 'center'
  },
  marginTop0: {
    marginTop: 0
  },
  marginTop10: {
    marginTop: 10
  },
  marginTop20: {
    marginTop: 20
  },
  marginTop30: {
    marginTop: 30
  },
  marginBottom0: {
    marginBottom: 0
  },
  marginBottom0Important: {
    marginBottom: [0, '!important']
  },
  marginBottom10: {
    marginBottom: 10
  },
  marginBottom20: {
    marginBottom: 20
  },
  marginBottom40: {
    marginBottom: 40
  },
  margin0: {
    margin: 0
  },
  alignSelfCenter: {
    margin: '0 auto'
  },
  displayInlineBlock: {
    display: 'inline-block'
  },
  noPadding: {
    padding: 0
  },
  width50Percent: {
    width: '50%'
  },
  displayNone: {
    display: 'none'
  },
  pointer: {
    cursor: 'pointer'
  }
});

export default CoreStyles;