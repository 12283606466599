import React from 'react';
//view
import CookieConsentView from './cookie-consent-view';

class CookieConsentContainer extends React.Component {
  constructor (props) {
    super(props);
    let cookieConsent = localStorage.getItem(consentKey);

    this.state = {
      cookieConsent
    };
  }

  handleClick = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    localStorage.setItem(consentKey, 'true');
    this.setState({
      cookieConsent: true
    })
  }

  render () {
    return (
      <CookieConsentView
        cookieConsent={this.state.cookieConsent}
        handleClick={this.handleClick}/>
    );
  }
}

const consentKey = 'cookieConsent';

export default CookieConsentContainer;