import React from 'react';
import styles from './app-footer-styles'
//Material
import { withStyles } from '@material-ui/core';
//Routing
import { withRouter } from 'react-router-dom';
//Component
import StaticFooter from '../../components/static-footer';

function AppFooterView (props) {
  return (
    <div>
      {
        props.showFooter && 
        <StaticFooter templateUrl={process.env.REACT_APP_COMPETITIONGOLF_FOOTER_TEMPLATE}/>
      }
    </div>
  )
}

export default withRouter(withStyles(styles) (AppFooterView));