import Axios from 'axios';

const competitionGolfApi = process.env.REACT_APP_COMPETITIONGOLF_PUBLIC_API_ENDPOINT;

class CompetitionService {

  getUserCompetitionDetails (competitionId, competitionPlayerId) {
    return Axios.get(competitionGolfApi + 'competitions/' + competitionId + '/' + competitionPlayerId)
      .then(resp => resp.data)
      .catch(err => {
        window.rg4js('send', {
          error: err
        });

        throw err;
      });
  }

  getUserCompetitionResults (competitionId, competitionPlayerId) {
    return Axios.get(competitionGolfApi + 'competitions/' + competitionId + '/players/' + competitionPlayerId + '/results' )
      .then(resp => resp.data)
      .catch(err => {
        if (err.response.status === 404)
          return [];

        window.rg4js('send', {
          error: err
        });
        throw err;
      });
  }

  getUserCompetitionOpponent (competitionId, competitionTeamId) {
    return Axios.get(competitionGolfApi + 'competitions/' + competitionId + '/pairs/' + competitionTeamId + '/opponent')
      .then(resp => resp.data)
      .catch(err => {
        window.rg4js('send', {
          error: err
        });

        throw err;
      });
  }

  setMatchResults (competitionId, competitionMatchId, results) {
    return Axios.put(competitionGolfApi + 'competitions/' + competitionId + '/results/' + competitionMatchId, results )
      .then(resp => resp.data)
      .catch(err => {
        window.rg4js('send', {
          error: err
        });

        throw err;
      });
  }

  registerForCompetition (competitionId, competitionTeamId, userRegistration) {
    return Axios.put(competitionGolfApi + 'competitions/' + competitionId + '/pairs/' + competitionTeamId + '/register', userRegistration)
      .then(resp => resp.data)
      .catch(err => {
        window.rg4js('send', {
          error: err
        });

        throw err;
      });
  }

  updatePlayerPhoto (competitionId, competitionTeamMemberId, image) {
    return Axios.post(competitionGolfApi + 'competitions/' + competitionId + '/players/' + competitionTeamMemberId + '/image', image)
      .then(resp => resp.data)
      .catch(err => {
        window.rg4js('send', {
          error: err
        });

        throw err;
      });
  }

  getCompetitionBracket (competitionId, bracketId, teamId) {
    return Axios.get(competitionGolfApi + 'competitions/' + competitionId + '/brackets/' + bracketId + '/' + teamId)
      .then(resp => resp.data)
      .catch(err => {
        window.rg4js('send', {
          error: err
        });

        throw err;
      });
  }

  getCompetitionStripePaymentDetails (competitionId) {
    return Axios.get(competitionGolfApi + 'competitions/' + competitionId + '/payments/stripe')
      .then(resp => resp.data)
      .catch(err => {
        window.rg4js('send', {
          error: err
        });

        throw err;
      });
  }

  getCompetitionStripeSku (competitionId, teamId) {
    return Axios.get(competitionGolfApi + 'competitions/' + competitionId + '/pairs/' + teamId +'/stripepaymentdetails')
        .then(resp => resp.data)
        .catch(err => {
          window.rg4js('send', {
            error: err
          });

          throw err;
        });
  }

  getCompetitionRegistrationSettings (competitionId) {
    return Axios.get(competitionGolfApi + 'competitions/' + competitionId + '/registrationsettings')
      .then(resp => resp.data)
      .catch(err => {
        window.rg4js('send', {
          error: err
        });

        throw err;
      });
  }

  checkUserCanRegister (competitionId, leadPlayerEmail, additionalPlayerEmail) {
    return Axios.get(competitionGolfApi + 'competitions/' + competitionId + '/pairs/canregister?leadPlayerEmail=' + leadPlayerEmail + '&additionalPlayerEmail=' + additionalPlayerEmail)
      .then(resp => resp.data)
      .catch(err => {
        if (err.response.status === 400)
          return { errorMessage: err.response.data };

        window.rg4js('send', {
          error: err
        });
        throw err;
      });
  }

  getPlayerStatus (competitionId, competitionPlayerId) {
    return Axios.get(competitionGolfApi + 'competitions/' + competitionId + '/players/' + competitionPlayerId + '/status')
      .then(resp => resp.data)
      .catch(err => {
        window.rg4js('send', {
          error: err
        });
        throw err;
      });
  }

  setCompetitionPlayer (competitionId, competitionPlayerId, player) {
    return Axios.put(competitionGolfApi + 'competitions/' + competitionId + '/players/' + competitionPlayerId, player)
      .then(resp => resp.data)
      .catch(err => {
        window.rg4js('send', {
          error: err
        });
        throw err;
      });
  }

  setCompetitionPair (competitionId, competitionTeamId, team) {
    return Axios.put(competitionGolfApi + 'competitions/' + competitionId + '/pairs/' + competitionTeamId, team)
      .then(resp => resp.data)
      .catch(err => {
        window.rg4js('send', {
          error: err
        });
        throw err;
      });
  }

  getCompetitionRounds (competitionId) {
    return Axios.get(competitionGolfApi + 'competitions/' + competitionId + '/rounds')
      .then(resp => resp.data)
      .catch(err => {
        if (err.response.status === 404)
          return [];

        window.rg4js('send', {
          error: err
        });
        throw err;
      });
  }

  getCompetitionMatch (competitionId, matchId) {
    return Axios.get(competitionGolfApi + 'competitions/' + competitionId + '/matches/' + matchId)
      .then(resp => resp.data)
      .catch(err => {
        window.rg4js('send', {
          error: err
        });
        throw err;
      });
  }

  getCompetitionMatchAnonymously (competitionId, matchId) {
    return Axios.get(competitionGolfApi + 'competitions/' + competitionId + '/matches/' + matchId + '/anonymous')
      .then(resp => resp.data)
      .catch(err => {
        window.rg4js('send', {
          error: err
        });
        throw err;
      });
  }
}

export default CompetitionService;
