import React from 'react';
import _ from 'lodash';
//components
import View from './bracket-view';
//utils
import { CompetitionService } from '../../../utils/data-transfer';

class BracketContainer extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      showMatchDialog: false,
      matchLoading: false,
      match: null
    };

    this._competitionService = new CompetitionService();
  }

  viewMatch = async (position, matchDetails) => {
    let bracketMatch = matchDetails ? matchDetails : _.find(this.props.bracketMatches, match => match.position === position);
    this.bracketMatch = bracketMatch;

    if (!_.find(bracketMatch.teams, team => team.name))
      return;

    this.setState({ showMatchDialog: true, matchLoading: true});

    try {
      let match = await this._competitionService.getCompetitionMatch(this.props.details.competitionId, bracketMatch.id);

      this.setState({ match, matchLoading: false });
    } catch (ex) {
      console.log(ex);
      this.setState({ showMatchDialog: false, matchLoading: false });
    }
  }

  handleCloseViewMatchDialog = () => this.setState({ showMatchDialog: false });

  render () {
    return (
      <View
        details={this.props.details}
        bracketMatches={this.props.bracketMatches}
        rounds={this.props.rounds}
        viewMatch={this.viewMatch}
        showMatchDialog={this.state.showMatchDialog}
        handleCloseViewMatchDialog={this.handleCloseViewMatchDialog}
        match={this.state.match}
        matchLoading={this.state.matchLoading}/>
    );
  }
}

export default BracketContainer;