import React from 'react';
import styles from './async-select-styles';
//material
import { TextField, Popper, Paper, CircularProgress, withStyles, Typography, List, ListItem, ListItemText } from '@material-ui/core';

function AsyncSelectView (props) {
  const classes = props.classes;

  return (
    <div>
      <TextField
        value={props.searchText}
        variant={'outlined'}
        label={props.label}
        placeholder={props.placeholder}
        fullWidth
        onChange={props.handleTextChange}
        onFocus={() => props.handleSelectFocused(true)}
        onBlur={() => props.handleSelectFocused(false)}
        helperText={props.helperText}/>

      {
        props.searchText.length > 2 && props.selectFocused && props.anchorEl &&
        <Popper
          className={classes.menuContainer}
          open
          anchorEl={props.anchorEl}
          placement={'bottom'}
          style={{ width: props.anchorEl.offsetWidth}}>
          <Paper
            className={classes.menuPaper}>
            {
              props.showLoading &&
              <CircularProgress color={'primary'}/>
            }

            {
              !props.showLoading && props.collection.length === 0 &&
              <Typography className={classes.noResults} variant={'body1'}>No Results found</Typography>
            }

            {
              !props.showLoading && props.collection.length > 0 &&
                <List>
                  {
                    props.collection.map((item, i) =>
                      <ListItem key={i} button onMouseDown={() => props.handleSelectItem(item.name, item.id)}>
                        <ListItemText primary={item.name}/>
                      </ListItem>)
                  }
                </List>
            }
          </Paper>
        </Popper>
      }
    </div>

  );
}

export default withStyles (styles) (AsyncSelectView);