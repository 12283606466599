import React from 'react';
//component
import RegistrationCompletePage from '../../components/registration-complete';

class RegistrationComplete extends React.Component {
  render () {
    let details = JSON.parse(localStorage.getItem('registrationCompleteDetails'));

    return (
      <RegistrationCompletePage
          teamName={details ? details.teamName : ''}
          drawDate={details ? details.drawDate : ''}
          competitionId={details ? details.competitionId : ''}
          competitionPlayerId={details ? details.competitionPlayerId : ''}/>
    );
  }
}

export default RegistrationComplete;