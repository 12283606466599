import React from 'react';
//components
import View from './summary-view';

class SummaryContainer extends React.Component {
  componentDidMount () {
    return this.props.canUserRegister();
  }

  render () {
    return (
      <View
        isLoading={this.props.isLoading}
        setActiveStep={this.props.setActiveStep}
        registration={this.props.registration}
        clubs={this.props.clubs}
        handleRegistrationChange={this.props.handleRegistrationChange}
        isSinglePlayerGame={this.props.isSinglePlayerGame}
        validationError={this.props.validationError}/>
    );
  }
}

export default SummaryContainer;