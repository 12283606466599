import CoreStyles from '../../utils/styles/core-styles';

const RegistrationStyles = theme => ({
  containerPadding: {
    [theme.breakpoints.down('sm')]: {
      paddingLeft: 0,
      paddingRight: 0
    }
  },
  stepperPaper: {
    backgroundColor: 'transparent',
  },
  activeStepper: {
    '& > :first-child': {
      borderColor: theme.palette.primary.main,
      borderTopWidth: 2
    }
  },
  buttonBack: {
    marginTop: 20,
    paddingLeft: 0
  },
  buttonNext: {
    marginTop: 20,
    paddingRight: 0,
    float: 'right'
  },
  componentGrid: {
    padding: '10px 20px 0',
    [theme.breakpoints.down('sm')]: {
      padding: '10px 10px 0'
    }
  },
  acceptConditions: {
    paddingTop: 24
  },
  acceptRules: {
    paddingTop: 0
  },
  textFieldSpacing: {
    paddingBottom: [ 8, '!important' ]
  },
  avatarImageContainer: {
    paddingLeft: '10px',
    textAlign: 'center',
    [theme.breakpoints.down('sm')]: {
      paddingLeft: 0,
      paddingTop: 20
    }
  },
  avatarImage: {
    margin: '0 auto',
    height: 170,
    width: 170
  },
  errorText: {
    fontSize: '12px',
    color: 'red'
  },
  registrationCard: {
    padding: '30px 16px'
  },
  registrationCompleteDate: {
    fontSize: 20,
    paddingLeft: 30
  },
  termsButton: {
    textDecoration: 'underline',
    cursor: 'pointer',
    color: '#008CBA'
  },
  errorIcon: {
    height: 60,
    width: 60
  },
  errorHeader: {
    padding: '20px 0'
  },
  errorBody: {
    padding: '30px 0'
  },
  competitionDialogHeaderRoot: {
    padding: '16px 24px 0'
  },
  competitionDialogRoot: {
    padding: '8px 0'
  },
  paymentButton: {
    display: 'block'
  },
  paymentIcon: {
    height: 40,
    width: 40
  },
  materialInputContainer: {
    width: 'calc(100% - 28px);',
    borderRadius: '4px',
    border: '1px solid #c8c8c8',
    position: 'relative',
    padding: '18.5px 14px',
    '&:hover': {
      borderColor: '#000000'
    }
  },
  materialInputLabel: {
    backgroundColor: '#ffffff',
    fontSize: '12px',
    padding: '0 4px',
    color: '#97a2a7',
    position: 'absolute',
    top: '-10px',
    left: '10px'
  },
  materialFocusStyle: {
    color: theme.palette.primary.main
  },
  materialBorderFocusStyle: {
    borderColor: theme.palette.primary.main,
    borderWidth: '2px',
    '&:hover': {
      borderColor: theme.palette.primary.main
    }
  },
  slideHeader: {
    fontSize: '18px',
    fontWeight: 'bold',
    color: theme.palette.primary.contrastText,
    backgroundColor: theme.palette.primary.main,
    textAlign: 'center',
    padding: '10px 0'
  },
  slideInfo: {
    marginTop: 0,
    padding: '0 20px',
    [theme.breakpoints.down('sm')]: {
      padding: '0 10px'
    }
  },
  paymentError: {
    fontSize: '14px',
    color: 'red',
    fontWeight: 'bold',
    margin: '0 auto',
    padding: '20px 0 10px'
  },
  helpButtonContainer: {
    lineHeight: '52px',
    paddingLeft: '10px',
    [theme.breakpoints.down('xs')]: {
      paddingLeft: 0
    }
  },
  helpButtonSkeleton: {
    marginTop: 18
  },
  warningText: {
    fontWeight: 'bold',
    color: '#000000',
    backgroundColor: process.env.REACT_APP_COMPETITIONGOLF_WARNING_COLOUR,
    textAlign: 'center',
    padding: '10px 0',
    fontSize: '12px',
    [theme.breakpoints.down('xs')]: {
      fontSize: '10px'
    }
  },
  helpButton: {
    color: '#008CBA',
    cursor: 'pointer',
    textDecoration: 'none',
    float:'right'
  },
  ...CoreStyles(theme)
});

export default RegistrationStyles;
