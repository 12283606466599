import React from 'react';
import AvatarEditor from 'react-avatar-editor';
import editorStyles from './avatar-editor-styles';
//material
import { Slider, withStyles } from '@material-ui/core';

function AvatarEditorView (props) {
  const classes = props.classes;

  return  (
    <div className={classes.textCenter}>
      <AvatarEditor
        ref={props.setEditorRef}
        height={props.height}
        width={props.width}
        borderRadius={props.width / 2}
        crossOrigin={'anonymous'}
        scale={props.scale}
        image={props.image}
        onImageChange={props.handleImageChange}
        onImageReady={props.commitOnChangeOnly ? () => {} : props.handleImageChange}/>

      <Slider
        classes={{ root: classes.sliderWidth }}
        min={0.75}
        max={2}
        step={0.01}
        value={props.scale}
        color={'primary'}
        onChange={props.handleSliderChange}/>

    </div>
  )
}

export default withStyles(editorStyles) (AvatarEditorView);
