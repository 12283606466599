import React from 'react';
//components
import AppBarView from './app-bar-view.js';
//utils
import { OrganisationService } from '../../utils/data-transfer';

class AppBarContainer extends React.Component {
  constructor (props) {
    super(props);

    this.state = {
      showMenu: false,
      showUserMenu: false,
      userMenuAnchor: null,
      showRegistration: false
    };

    this._organisationService = new OrganisationService();
  }

  componentDidMount(){
    return this.checkForOpenCompetitions();
  }

  checkForOpenCompetitions = async () => {
    try {
      let competitions = await this._organisationService.getOpenCompetitions();

      this.setState({showRegistration: competitions.length > 0});
    } catch (err) {
      this.setState({showRegistration: false});
      console.log(err)
    }
  };

  setMenu = (showMenu) => {
    this.setState({showMenu});
  };

  handleShowUserMenu = (e) => {
    this.setState({
      showUserMenu: true,
      userMenuAnchor: e.currentTarget
    });
  };

  handleToggleUserMenu = (showUserMenu) => {
    this.setState({
      showUserMenu
    });
  };

  render () {
    return (
      <AppBarView
        setMenu={this.setMenu}
        showMenu={this.state.showMenu}
        user={this.props.user}
        userHasPermissions={this.props.userHasPermissions}
        showUserMenu={this.state.showUserMenu}
        userMenuAnchor={this.state.userMenuAnchor}
        handleShowUserMenu={this.handleShowUserMenu}
        handleToggleUserMenu={this.handleToggleUserMenu}
        showRegistration={this.state.showRegistration}/>
    );
  }
}

export default AppBarContainer;
