  import React from 'react';
import _ from 'lodash';
//components
import AsyncSelectView from './async-select-view';

class AsyncSelectContainer extends React.Component {
  constructor (props) {
    super (props);

    this.state = {
      searchText: this.props.defaultText ? this.props.defaultText : '',
      selectFocused: false,
      anchorEl: null,
      showLoading: true
    };

    this.debounceApiCall = _.debounce(this.makeAsyncCall, 1000);
  }

  handleSelectFocused = (selectFocused) => this.setState({selectFocused});

  handleTextChange = (e) => {
    this.setState({ searchText:  e.target.value, showLoading: true});

    if (!this.state.anchorEl)
      this.setState({anchorEl: e.currentTarget});

    if (e.target.value.length <= 2)
      return;

    this.debounceApiCall(e.target.value);
  };

  makeAsyncCall = async (searchTerm) => {
    await this.props.asyncCall(searchTerm);

    this.setState({ showLoading: false });
  };

  handleSelectItem = (searchText, id) => {
    this.setState({
      searchText
    });

    this.props.handleAssignId(id);
  };

  render () {
    return (
      <AsyncSelectView
        searchText={this.state.searchText}
        selectFocused={this.state.selectFocused}
        handleSelectFocused={this.handleSelectFocused}
        handleTextChange={this.handleTextChange}
        anchorEl={this.state.anchorEl}
        showLoading={this.state.showLoading}
        collection={this.props.collection}
        label={this.props.label}
        placeholder={this.props.placeholder}
        handleSelectItem={this.handleSelectItem}
        helperText={this.props.helperText}/>
    );
  }
}

export default AsyncSelectContainer;