import CoreStyles from '../../../utils/styles/core-styles';

const ResultsStyles = theme => ({
  gridItem: {
    padding: '10px 0'
  },
  emptyListView: {
    fontSize: '20px',
    textAlign: 'center',
    marginTop: 60
  },
  ...CoreStyles(theme)
});

export default ResultsStyles;