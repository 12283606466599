import React from 'react';
import styles from '../detailed-bracket-styles';
import _ from 'lodash';
import Moment from 'moment';
//material
import { makeStyles } from '@material-ui/core';
//components
import Match from '../match';
import BracketMatch from '../bracket-match';

const useStyles = makeStyles(styles);

function Bracket16View (props) {
  const classes = useStyles(props);
  document.getElementById('siteRoot').style.fontSize = '16px';
  let headings = [{name: 'Round 1', number: 1}, {name: 'Quarter-Finals', number: 2}, {name:'Semi-Finals', number: 3}, {name:'Final', number:4}];
  headings.forEach(h => {
    let round = _.find(props.rounds, r => r.roundNumber === h.number);
    h.deadline = round.endDate ? Moment(new Date(round.endDate)).format('Do MMMM YYYY') : ' ';
  });

  return (
    <div className={classes.bracket + ' ' + classes.bracket16}>
      <div className={classes.headerContainer}>
        {
          headings.map((heading, i) =>
            <div key={i} className={classes.columnHeader + ' dark-mode-text'}>
              <div>{heading.name}</div>
              {
                !JSON.parse(process.env.REACT_APP_COMPETITIONGOLF_HIDE_ROUND_DATES) &&
                <div className={classes.columnSubheader}>{heading.deadline}</div>
              }
            </div>)
        }
      </div>

      <div className={classes.round + ' ' + classes.round1}>
        <BracketMatch
          viewMatch={props.viewMatch}
          classes={classes}
          match1Position={1}
          match2Position={3}
          matches={props.bracketMatches}/>

        <BracketMatch
          viewMatch={props.viewMatch}
          classes={classes}
          match1Position={5}
          match2Position={7}
          matches={props.bracketMatches}/>

        <BracketMatch
          viewMatch={props.viewMatch}
          classes={classes}
          match1Position={2}
          match2Position={4}
          matches={props.bracketMatches}/>

        <BracketMatch
          viewMatch={props.viewMatch}
          classes={classes}
          match1Position={6}
          match2Position={8}
          matches={props.bracketMatches}/>
      </div>
      <div className={classes.round + ' ' + classes.round2}>
        <BracketMatch
          viewMatch={props.viewMatch}
          classes={classes}
          match1Position={9}
          match2Position={11}
          matches={props.bracketMatches}/>

        <BracketMatch
          viewMatch={props.viewMatch}
          classes={classes}
          match1Position={10}
          match2Position={12}
          matches={props.bracketMatches}/>
      </div>
      <div className={classes.round + ' ' + classes.round3}>
        <BracketMatch
          viewMatch={props.viewMatch}
          classes={classes}
          match1Position={13}
          match2Position={14}
          matches={props.bracketMatches}/>
      </div>
      <div className={classes.round}>
        <div className={classes.bracketMatches}>
          <div className={classes.matchups}>
            <Match
              viewMatch={props.viewMatch}
              classes={classes}
              matchPosition={15}
              matches={props.bracketMatches}
              isSingle/>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Bracket16View;