export default (theme) => ({
  title: {
    fontSize: '22px',
    fontWeight: 400,
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    marginBottom: 10,
  },
  data: {
    textAlign: 'right',
    width: '50%',
  },
  column: {
    width: '50%',
    fontWeight: 600
  },
  table: {
    width: '100%',
    borderSpacing: '10px'
  },
  skeletonTable: {
    padding: 10
  },
  skeletonRow: {
    marginBottom: 8
  },
  defaultText: {
    height: 168,
    lineHeight: '168px',
    verticalAlign: 'middle',
    textAlign: 'center',
    fontSize: '18px'
  },
  subheader: {
    marginTop: 5,
    color: '#646262'
  },
  divider: {
    margin: '20px 0'
  },
});
