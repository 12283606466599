import React from 'react';
//component
import ViewCompetition from '../../components/competition';

class Competition extends React.Component {
  render () {
    return (
      <ViewCompetition
        competitionId={this.props.match.params.competitionId}
        competitionPlayerId={this.props.match.params.competitionPlayerId}/>
    );
  }
}

export default Competition;