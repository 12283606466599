import React from 'react';
import styles from './registration-styles';
import _ from 'lodash';
//material
import { Container, Stepper, Step, StepLabel, withStyles, Button, StepConnector, Hidden, MobileStepper } from '@material-ui/core';
import { KeyboardArrowLeft, KeyboardArrowRight, Check } from '@material-ui/icons'
//components
import TeamDetails from './team-details';
import LeadPlayer from './lead-player';
import AdditionalPlayer from './additional-player';
import Summary from './summary';
import SelectCompetition from './select-competition';
import Payment from './payment';

function RegistrationView (props) {
  const classes = props.classes;
  let isSinglePlayerGame = props.registration.tournamentFormatId === 1;
  let steps = [
    { name: isSinglePlayerGame ? 'Home Club' : 'Team Details', id: 0},
    { name:'Lead Player', id: 1},
    { name:'Additional Player', id: 2},
    { name:'Summary', showConfirm: true, id: 3}
  ];
  if (!props.registration.entryIsPrepaid)
    steps.push({ name:'Payment', hideNextStep: true, id: 4});

  if (isSinglePlayerGame)
    steps = steps.filter(step => step.name !== 'Additional Player');

  const currrentStep = steps[props.activeStep];
  const nextStep = ((props.activeStep + 1) < steps.length ) && !currrentStep.showConfirm && !currrentStep.hideNextStep ? steps[props.activeStep + 1] : null;

  return (
    <Container maxWidth={'lg'} classes={{ root: classes.containerPadding }}>
      {
        !props.hasSubmitted &&
        <Hidden xsDown>
          <Stepper className={classes.stepperPaper} activeStep={props.activeStep} alternativeLabel connector={<StepConnector classes={{ active: classes.activeStepper, completed: classes.activeStepper }}/>}>
            {
              steps.map((step, i) =>
                <Step key={i}>
                  <StepLabel>{step.name}</StepLabel>
                </Step>
              )
            }
          </Stepper>
        </Hidden>
      }

      {
        !props.hasSubmitted &&
        <Container maxWidth={'md'} classes={{ root: classes.containerPadding }}>
          {
            props.activeStep === _.findIndex(steps, step => step.id === 0) &&
            <TeamDetails
              setRef={props.setRef}
              isLoading={props.isLoading}
              registration={props.registration}
              handleUpdateRegistration={props.handleUpdateRegistration}
              getClubsMatchingQuery={props.getClubsMatchingQuery}
              handleUpdateRegistrationCheck={props.handleUpdateRegistrationCheck}
              handleClubSelection={props.handleClubSelection}
              clubs={props.clubs}
              isStepValid={props.isStepValid}
              setStepValid={props.setStepValid}
              isSinglePlayerGame={isSinglePlayerGame}/>
          }

          {
            props.activeStep === _.findIndex(steps, step => step.id === 1) &&
            <LeadPlayer
              registration={props.registration}
              handleUpdateRegistration={props.handleUpdateRegistration}
              counties={props.counties}
              isStepValid={props.isStepValid}
              setStepValid={props.setStepValid}
              registrationSettings={props.registrationSettings}/>
          }

          {
            props.activeStep === _.findIndex(steps, step => step.id === 2) &&
            <AdditionalPlayer
              registration={props.registration}
              handleUpdateRegistration={props.handleUpdateRegistration}
              isStepValid={props.isStepValid}
              setStepValid={props.setStepValid}
              registrationSettings={props.registrationSettings}/>
          }

          {
            props.activeStep === _.findIndex(steps, step => step.id === 3) &&
            <Summary
              isLoading={props.isLoading}
              setActiveStep={props.setActiveStep}
              registration={props.registration}
              clubs={props.clubs}
              handleRegistrationChange={props.handleRegistrationChange}
              isSinglePlayerGame={isSinglePlayerGame}
              canUserRegister={props.canUserRegister}
              validationError={props.validationError}/>
          }

          {
            props.activeStep === _.findIndex(steps, step => step.id === 4) &&
            <Payment
              setActiveStep={props.setActiveStep}
              registration={props.registration}
              stripeIntent={props.stripeIntent}
              completeDetails={props.completeDetails}/>
          }

          {
            !props.hasSubmitted &&
            <Hidden smUp>
              <MobileStepper
                position={'static'}
                className={classes.stepperPaper + ' ' + classes.marginTop10}
                classes={{ dots: classes.alignSelfCenter }}
                activeStep={props.activeStep}
                steps={steps.length}>
                {
                  steps.map((step, i) =>
                    <Step key={i}>
                      <StepLabel>{step.name}</StepLabel>
                    </Step>
                  )
                }
              </MobileStepper>
            </Hidden>
          }

          {
            props.activeStep > 0 &&
            <Button color={'primary'} className={classes.buttonBack} onClick={() => props.setActiveStep(props.activeStep - 1)} disabled={props.isLoading}>
              <KeyboardArrowLeft/>
              &nbsp;Back
            </Button>
          }

          {
            nextStep &&
            <Button color={'primary'} className={classes.buttonNext} onClick={() => props.setActiveStep(props.activeStep + 1)} disabled={props.isLoading || !props.isStepValid}>
              {nextStep.name}&nbsp;
              <KeyboardArrowRight/>
            </Button>
          }

          {
            !nextStep && props.activeStep < 4 &&
            <Button color={'primary'} className={classes.buttonNext} onClick={props.submitRegistration} disabled={props.isLoading || props.validationError}>
              Confirm&nbsp;
              <Check/>
            </Button>
          }
        </Container>
      }

      <SelectCompetition
        showSelectCompetition={props.showSelectCompetition}
        setCompetition={props.setCompetition}
        competitions={props.competitions}/>
    </Container>
  );
}

export default withStyles(styles) (RegistrationView);