import React from 'react';
//components
import AppFooterView from './app-footer-view.js';
//utils

class AppFooterContainer extends React.Component {
    constructor (props) {
      super(props);
  
      this.state = {
        showFooter: false,
      };
    }

    setFooter = (showFooter) => {
        this.setState({showFooter});
    };

    render () {
        return (
          <AppFooterView
            showFooter={this.setFooter}/>
        );
    }
}

export default AppFooterContainer;