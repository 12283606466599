import CoreStyles from '../../utils/styles/core-styles';

const AppBarStyles = theme => ({
  headerDense: {
    height: '40px',
    padding: '0 10px;',
    lineHeight: '40px',
    backgroundColor: theme.palette.secondary.main
  },
  headerDenseButton : {
    backgroundColor: 'transparent',
    float: 'right',
    height: '100%',
    color: theme.palette.secondary.contrastText
  },
  marginRight10: {
    marginRight: '10px'
  },
  logo: {
    maxHeight: 50,
    marginRight: 50,
    [theme.breakpoints.down('xs')]: {
      maxHeight: 40
    }
  },
  headerButton: {
    marginRight: '40px',
    [theme.breakpoints.up('lg')]: {
      fontSize: '18px'
    }
  },
  menuButton: {
    right: '16px',
    position: 'absolute'
  },
  paper: {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.secondary.contrastText
  },
  list: {
    width: 250
  },
  listItem: {
    paddingLeft: 30,
    textTransform: 'uppercase'
  },
  menuButtonContainer: {
    width: '100%',
    position: 'absolute',
    left: 0,
    top: 0,
    height: 64,
    lineHeight: '64px'
  },
  ...CoreStyles(theme)
});


export default AppBarStyles