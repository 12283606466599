import React from 'react';
import _ from 'lodash';

function MatchView (props) {
  const classes = props.classes;
  const match = _.find(props.matches, match => match.position === props.matchPosition);
  const getMatchTeamClasses = (team) => {
    if (!match)
      return '';

    if ((match.played || match.bye) && team.winner)
      return classes.winningTeam;

    if (match.played && !team.winner && team.name && team.name !== '-')
      return classes.losingTeam;

    return '';
  };

  const getHomeOrAwayIdentifier = (team) => {
    if (!team.name || team.name === '-' || match.bye)
      return '';

    return team.home ? '(H)' : '(A)';
  };

  const getByeDisplayValue = (match) => {
    if (!match.bye)
      return false;

    return hasTeam(match);
  };

  const hasTeam = (match) => match && _.find(match.teams, team => team.name);

  return (
    <div className={classes.match + ' ' + (props.isSingle ? classes.marginBottom0Important : '')+ ' ' + (hasTeam(match) ? classes.pointer : '')} onClick={() => props.viewMatch(props.matchPosition)}>
      {
        match && match.teams.map((team, key) =>
          <div className={classes.team + ' ' + getMatchTeamClasses(team) + ' ' + getHomeOrAwayIdentifier(team) } key={key}>
            <div className={classes.teamName}>{team.name ? team.name : (getByeDisplayValue(match) ? '' : '-')}</div>
            <div className={classes.teamType}>{getHomeOrAwayIdentifier(team)}</div>
          </div>)
      }

      {
        (!match || match.teams.length < 2) &&
        <div className={classes.team}>-</div>
      }

      {
        (!match || match.teams.length < 1) &&
        <div className={classes.team}>-</div>
      }
    </div>
  );
}

export default MatchView;