import React from 'react';
//components
import View from './static-page-view';

class StaticPageContainer extends React.Component {
  constructor (props) {
    super (props);

    this.state = {
      template: { __html: ''}
    }
  }

  componentDidMount () {
    this._isMounted = true;
    this.getStaticData();
  }

  componentWillUnmount () {
    this._isMounted = false;
  }

  getStaticData = () => {
    let oReq = new XMLHttpRequest();
    oReq.addEventListener("load", () => {
      if (this._isMounted)
        this.setState({ template: {__html: oReq.response} });
    });

    oReq.open("GET", this.props.templateUrl);
    oReq.send();
  };

  render () {
    return (
      <View
        template={this.state.template}
        hideCard={this.props.hideCard}/>
    );
  }
}

export default StaticPageContainer;