import React from 'react';
import styles from '../details-styles';
import Moment from 'moment';
//material
import { Card, CardContent, withStyles, Typography, Grid, Button } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
//utils
import { TeamPlayStatus } from '../../../../utils/constants';

function OpponentView (props) {
  const classes = props.classes;
  const isBye = props.results.length > 0 && props.results[0].isBye;

  return (
    <Card>
      <CardContent className={classes.cardContent}>
        <Typography className={classes.cardHeader}>Next Match</Typography>

        {
          !props.isLoading &&
          <div>
            {
              TeamPlayStatus.Won === props.details.teamPlayStatus.id &&
              <div className={classes.cardBodyPadding + ' ' + classes.textCenter}>
                <Typography className={classes.opponentXlText}>You Won the Competition!</Typography>
              </div>
            }

            {
              TeamPlayStatus.Eliminated === props.details.teamPlayStatus.id &&
              <div className={classes.cardBodyPadding + ' ' + classes.textCenter}>
                <Typography className={classes.opponentXlText}>You Have Been Eliminated</Typography>
              </div>
            }

            {
              TeamPlayStatus.Undrawn === props.details.teamPlayStatus.id &&
              <div className={classes.cardBodyPadding + ' ' + classes.textCenter}>
                <Typography className={classes.opponentXlText}>Waiting for the Draw</Typography>
                <Typography className={classes.opponentDrawDate}>Draw Date: { props.details.drawDate ? Moment(new Date(props.details.drawDate)).format('Do MMMM YYYY') : '-' }</Typography>
                <p className={classes.marginTop30}>You will receive an email when the draw has been made.</p>
              </div>
            }

            {
              TeamPlayStatus.Ready === props.details.teamPlayStatus.id &&
              <div className={classes.cardBodyPadding + ' ' + classes.textCenter}>
                <Typography className={classes.opponentXlText}>{ isBye ? 'You have a Bye!' : 'Waiting for Your Next Opponent'}</Typography>
                {
                  isBye &&
                  <Typography className={classes.opponentDrawDate}>Waiting for Your Next Opponent</Typography>
                }
                <p className={isBye ? classes.marginTop20 : classes.marginTop30}>You will receive an email when your opponent is ready.</p>
              </div>
            }

            {
              TeamPlayStatus.Playing === props.details.teamPlayStatus.id &&
              <div className={classes.cardBodyPadding}>
                <Typography className={classes.opponentXlText} align={'center'}>{ props.details.nextOpponent.name ? props.details.nextOpponent.name : '-' }</Typography>

                <Grid container>
                  { props.details.nextOpponent.contactName && <Grid item xs={12} className={classes.textCenter}>{ props.details.nextOpponent.contactName }</Grid> }
                  { props.details.nextOpponent.contactEmail && <Grid item xs={12} className={classes.textCenter}><a href={'mailto:' + props.details.nextOpponent.contactEmail}>{ props.details.nextOpponent.contactEmail }</a></Grid> }
                  { props.details.nextOpponent.contactPhone && <Grid item xs={12} className={classes.textCenter}>{ props.details.nextOpponent.contactPhone }</Grid> }
                </Grid>

                <Grid container>
                  <Grid item xs={12} className={classes.viewOpponent}>
                    <Button color={'primary'} size={'small'} aria-label={'small'} variant={'contained'} onClick={() => props.handleSetViewOpponent(true)}>
                      View Opponents
                    </Button>
                  </Grid>
                </Grid>

                <p className={classes.marginTop20 + ' ' + classes.textCenter}>
                  <strong>Match must by played by:</strong><br/>
                  { props.details.nextOpponent.matchDeadline ? Moment(new Date(props.details.nextOpponent.matchDeadline)).format('Do MMMM YYYY') : '-' }
                  <br/><br/>
                  <strong>Home Team:</strong>&nbsp;{ props.details.nextOpponent.isHome ? props.details.nextOpponent.name : props.details.pairName }
                  <br/>
                  <strong>Away Team:</strong>&nbsp;{ !props.details.nextOpponent.isHome ? props.details.nextOpponent.name : props.details.pairName }
                </p>
              </div>
            }
          </div>
        }

        {
          props.isLoading &&
            <div>
              <Skeleton className={classes.alignSelfCenter} height={50} width={'80%'}/>
              <Skeleton className={classes.alignSelfCenter} height={20} width={80}/>
              <Skeleton className={classes.alignSelfCenter} height={20} width={100}/>
              <Skeleton className={classes.alignSelfCenter} height={20} width={80}/>

              <br/>
              <Skeleton className={classes.alignSelfCenter} height={30} width={'70%'}/>
              <Skeleton className={classes.alignSelfCenter} height={30} width={'60%'}/>
            </div>
        }

      </CardContent>
    </Card>
  )
}

export default withStyles(styles) (OpponentView)