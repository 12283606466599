import React from 'react';
import styles from './bracket-styles';
//material
import { Grid, withStyles, Hidden } from '@material-ui/core';
//components
import DrawnPerRound from './drawn-per-round';
import Bracket16 from './bracket-16';
import Bracket32 from './bracket-32';
import Bracket64 from './bracket-64';
import ViewMatch from './view-match';

function BracketView (props) {
  const classes = props.classes;

  return (
    <Grid container>
      <Grid item xs={12}>
        {
          !props.details.isDrawn &&
          <div className={classes.emptyListView + ' dark-mode-text'}>
            Waiting for Draw
          </div>
        }

        {
          props.details.isDrawn && props.bracketMatches.length === 0 &&
          <div className={classes.emptyListView + ' dark-mode-text'}>
            No bracket matches to show
          </div>
        }
      </Grid>

      {
        props.details.isDrawn && props.bracketMatches.length > 0 && props.details.newDrawPerRound &&
        <Grid item xs={12}>
          <DrawnPerRound
            bracketMatches={props.bracketMatches}
            details={props.details}
            hideEmptyRounds
            viewMatch={props.viewMatch}
            rounds={props.rounds}/>
        </Grid>
      }

      {
        props.details.isDrawn && props.bracketMatches.length > 0 && !props.details.newDrawPerRound && props.details.bracketSize === 16 &&
        <Grid item xs={12}>
          <Hidden mdUp>
            <DrawnPerRound
              bracketMatches={props.bracketMatches}
              details={props.details}
              hideEmptyRounds
              viewMatch={props.viewMatch}
              rounds={props.rounds}/>
          </Hidden>
          <Hidden smDown>
            <Bracket16
              elementSize={16}
              bracketMatches={props.bracketMatches}
              details={props.details}
              viewMatch={props.viewMatch}
              rounds={props.rounds}/>
          </Hidden>
        </Grid>
      }

      {
        props.details.isDrawn && props.bracketMatches.length > 0 && !props.details.newDrawPerRound && props.details.bracketSize === 32 &&
        <Grid item xs={12}>
          <Hidden mdUp>
            <DrawnPerRound
              bracketMatches={props.bracketMatches}
              details={props.details}
              hideEmptyRounds
              viewMatch={props.viewMatch}
              rounds={props.rounds}/>
          </Hidden>
          <Hidden smDown>
            <Bracket32
              elementSize={12}
              bracketMatches={props.bracketMatches}
              details={props.details}
              viewMatch={props.viewMatch}
              rounds={props.rounds}/>
          </Hidden>
        </Grid>
      }

      {
        props.details.isDrawn && props.bracketMatches.length > 0 && !props.details.newDrawPerRound && props.details.bracketSize === 64 &&
        <Grid item xs={12}>
          <Hidden mdUp>
            <DrawnPerRound
              bracketMatches={props.bracketMatches}
              details={props.details}
              hideEmptyRounds
              viewMatch={props.viewMatch}
              rounds={props.rounds}/>
          </Hidden>
          <Hidden smDown>
            <Bracket64
              elementSize={10}
              bracketMatches={props.bracketMatches}
              details={props.details}
              viewMatch={props.viewMatch}
              rounds={props.rounds}/>
          </Hidden>
        </Grid>
      }

      {
        props.showMatchDialog &&
        <ViewMatch
          details={props.details}
          showDialog={props.showMatchDialog}
          handleCloseDialog={props.handleCloseViewMatchDialog}
          match={props.match}
          matchLoading={props.matchLoading}/>
      }
    </Grid>
  );
}

export default withStyles(styles) (BracketView);