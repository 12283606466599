import React from 'react';
//material
import { Card, CardContent } from '@material-ui/core';

function StaticPageView (props) {
  return (
    <div>
      {
        props.hideCard ?
          <div dangerouslySetInnerHTML={props.template}/> :
          <Card>
            <CardContent>
              <div dangerouslySetInnerHTML={props.template}/>
            </CardContent>
          </Card>
      }
    </div>
  );
}

export default StaticPageView;