import React from 'react';
//Material
import { Card as MaterialCard, Grid } from '@material-ui/core';

function Card (props) {
  return (
    <Grid container>
      <Grid item xs={12}>
        <MaterialCard>
          {props.children}
        </MaterialCard>
      </Grid>
    </Grid>
  );
}

export default Card;