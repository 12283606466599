
const SelectMultipleCompetitionsStyles = theme => ({
  dialogHeader: {
    padding: '16px 24px 0'
  },
  dialogContentRoot: {
    padding: '8px 0'
  },
  dialogActions: {
    paddingTop: 0
  }
});

export default SelectMultipleCompetitionsStyles;