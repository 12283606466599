//Polyfills
import 'react-app-polyfill/ie11';
import 'core-js/features/array/find-index';
import 'core-js/features/array/find';
import 'core-js/features/string/starts-with';
import 'core-js/es/symbol';
import 'core-js/modules/es.promise';
import 'promise-polyfill/src/polyfill';
//Core
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import { BrowserRouter } from 'react-router-dom';
import * as serviceWorker from './serviceWorker';
import {createMuiTheme, MuiThemeProvider} from '@material-ui/core';
import { Provider } from 'react-redux';
//Components
import AppBar from './components/app-bar';
import AppContent from './components/app-content';
import AppFooter from './components/app-footer';
import UserSession from './components/user-session';
import CookieConsent from './components/cookie-consent';
//utils
import reduxStore from './utils/redux';
//moment
import Moment from 'moment';
require('../node_modules/moment/min/locales.js');

Moment.locale(window.navigator.language);

const theme = createMuiTheme({
  typography: {
    useNextVariants: true,
  },
  palette: {
    primary: {
      main: process.env.REACT_APP_COMPETITIONGOLF_PRIMARY_COLOUR,
    },
    secondary: {
      main: process.env.REACT_APP_COMPETITIONGOLF_SECONDARY_COLOUR,
    },
    siteBackdrop: {
      main: process.env.REACT_APP_COMPETITIONGOLF_SITE_BACKDROP_COLOUR
    },
    siteHeaderMain: {
      main: process.env.REACT_APP_COMPETITIONGOLF_HEADER_COLOUR
    },
    cgPositive: {
      main: process.env.REACT_APP_COMPETITIONGOLF_POSITIVE_COLOUR
    },
    cgNeutral: {
      main: process.env.REACT_APP_COMPETITIONGOLF_NEUTRAL_COLOUR
    },
    cgWarning: {
      main: process.env.REACT_APP_COMPETITIONGOLF_WARNING_COLOUR
    }
  },
});

const store = reduxStore();

ReactDOM.render(
  <Provider store={store}>
    <BrowserRouter>
      <MuiThemeProvider theme={theme}>
        <UserSession>
          <AppBar/>
          <AppContent/>
          <AppFooter/>
        </UserSession>
        <CookieConsent/>
      </MuiThemeProvider>
    </BrowserRouter>
  </Provider>,
  document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
