import React from 'react';
import style from './member-styles';
import { withRouter } from 'react-router-dom';
//material
import { Card, CardContent, CardActionArea, Grid, Avatar, withStyles, IconButton, CardActions } from '@material-ui/core';
import { Facebook, LinkedIn, Twitter } from '@material-ui/icons';
import { Skeleton } from '@material-ui/lab';

function MemberView (props) {
  const classes = props.classes;

  const navigate = (organisationMemberId) =>
    props.history.push(`show-member/${organisationMemberId}`);

  return (
    <Card className={classes.card} onClick={!props.isLoading ? () => navigate(props.member.organisationMemberId) : null}>
      <div className={classes.avatarBackground}/>
      <CardActionArea disableRipple={props.isLoading}>
        <CardContent classes={{ root: classes.cardContent}}>
          <Grid className={classes.center} item xs={12}>
            {
              props.isLoading ?
                <Skeleton className={classes.avatar + ' ' + classes.skeletonLoading} variant={'circle'}/>:
                <Avatar className={classes.avatar} src={props.member.photoUrl}/>
            }

          </Grid>

          {
            !props.isLoading && props.member.memberType.highlight &&
              <div className={classes.memberTypePill}>
                {props.member.memberType.description}
              </div>
          }

          <Grid className={classes.center + ' ' + classes.name} item xs={12}>
            {
              props.isLoading ?
                <Skeleton className={classes.skeletonLoading} variant={'rect'} height={21} />:
                props.member.name
            }
          </Grid>

          <Grid className={classes.center + ' ' + classes.company} item xs={12}>
            {
              props.isLoading ?
                <Skeleton className={classes.skeletonLoading + ' ' + classes.skeletonCentered} variant={'rect'} height={13} width={'70%'}/>:
                props.member.companyName
            }
          </Grid>

          <Grid className={classes.center + ' ' + classes.details} item xs={12}>
            {
              props.isLoading ?
                <Skeleton className={classes.skeletonLoading + ' ' + classes.skeletonCentered} variant={'rect'} height={16} width={'70%'}/>:
                <a href={'mailto:' + props.member.emailAddress}>{props.member.emailAddress}</a>
            }
          </Grid>

          <Grid className={classes.center + ' ' + classes.details} item xs={12}>
            {
              props.isLoading ?
                <Skeleton className={classes.skeletonLoading + ' ' + classes.skeletonCentered} variant={'rect'} height={16} width={'70%'}/>:
                <a href={'tel:' + props.member.phoneNumber}>{props.member.phoneNumber}</a>
            }
          </Grid>

          <Grid className={classes.center + ' ' + classes.handicap} item xs={12}>
            {
              props.isLoading ?
                <Skeleton className={classes.skeletonLoading + ' ' + classes.skeletonCentered} variant={'rect'} height={16} width={'70%'}/>:
                'HCP Index: ' + props.member.handicap
            }
          </Grid>
        </CardContent>
      </CardActionArea>
      <CardActions className={classes.footerContainer}>
        {
          props.isLoading ?
            <Grid item xs={12}><Skeleton variant={'rect'} height={38}/></Grid>:
            <Grid className={classes.center} item xs={12}>
              <IconButton
                classes={{ root: classes.iconButton + ' ' + classes.facebookButton }}
                disabled={!props.member.facebookUrl}
                href={props.member.facebookUrl}
                target={'_blank'}
                rel={'noopener'}>
                <Facebook/>
              </IconButton>

              <IconButton
                classes={{ root: classes.iconButton + ' ' + classes.linkedInButton }}
                disabled={!props.member.linkedInUrl}
                href={props.member.linkedInUrl}
                target={'_blank'}
                rel={'noopener'}>
                <LinkedIn/>
              </IconButton>

              <IconButton
                classes={{ root: classes.iconButton + ' ' + classes.twitterButton }}
                disabled={!props.member.twitterUrl}
                href={props.member.twitterUrl}
                target={'_blank'}
                rel={'noopener'}>
                <Twitter/>
              </IconButton>
            </Grid>
        }
      </CardActions>
    </Card>
  );
}

export default withRouter(withStyles(style) (MemberView));
