const MembersStyles = theme => ({
  searchContainer: {
    margin: '0 auto',
    paddingBottom: 40
  },
  groupsContainer: {
    paddingBottom: 10
  },
  emptyListView: {
    fontSize: '20px',
    textAlign: 'center',
    marginTop: 60,
    width: '100%'
  },
  pinnedMembers: {
    marginBottom: 10
  }
});

export default MembersStyles;
