import React from 'react';
//component
import DetailsView from './details-view';
//utils
import { CompetitionService}  from '../../../utils/data-transfer';

class DetailsContainer extends React.Component {
  constructor (props) {
    super (props);

    this.state = {
      viewOpponent: false,
      opponent: { leadPlayer: {}, additionalPlayer: {}, results: []},
      isLoadingOpponent: false,
      showEditPlayerDialog: false,
      editPlayer: null,
      showEditTeamDialog: false,
      editTeam: null
    };

    this._competitionService = new CompetitionService();
  }

  handleShowDialog = (showEditPlayerDialog, editPlayer) => this.setState({showEditPlayerDialog, editPlayer});
  handleEditPlayerChange = (e) => this.setState({ editPlayer: Object.assign({}, this.state.editPlayer, {[e.target.name]: e.target.value})});

  handleShowTeamDialog = (showEditTeamDialog) => this.setState({showEditTeamDialog, editTeam: { pairName: this.props.details.pairName, clubName: this.props.details.clubName, clubId: this.props.details.clubId }});
  handleEditTeamChange = (e) => this.setState({ editTeam: Object.assign({}, this.state.editTeam, {[e.target.name]: e.target.value})});
  handleClubChange = (clubId) => this.setState({ editTeam: Object.assign({}, this.state.editTeam, {clubId})});

  handleSetViewOpponent = async (viewOpponent) => {
    this.setState({ viewOpponent });

    if (!viewOpponent)
      return this.setState({ opponent: { leadPlayer: {}, additionalPlayer: {}, results: [] }});

    try {
      this.setState({isLoadingOpponent: true});
      let opponent = await this._competitionService.getUserCompetitionOpponent(this.props.details.competitionId, this.props.details.competitionTeamId);

      this.setState({ opponent, isLoadingOpponent: false });
    } catch (err) {
      console.log(err);
    }
  };

  handleEditPlayerSubmit = async (image) => {
    try {
      this.props.setLoading(true);

      if (image)
        await this._competitionService.updatePlayerPhoto(this.props.details.competitionId, this.state.editPlayer.competitionTeamPlayerId, { image, format: 'jpeg' });

      let player = Object.assign({}, this.state.editPlayer);
      await this._competitionService.setCompetitionPlayer(this.props.details.competitionId, this.state.editPlayer.competitionTeamPlayerId, player);

      await this.props.getCompetition();
      this.props.setLoading(false);
    } catch (err) {
      console.log(err);
      this.props.setLoading(false);
    }
  };

  handleEditTeamSubmit = async () => {
    try {
      this.props.setLoading(true);
      this.setState({showEditTeamDialog: false});
      await this._competitionService.setCompetitionPair(this.props.details.competitionId, this.props.details.competitionTeamId, this.state.editTeam);

      await this.props.getCompetition();
      this.props.setLoading(false);
    } catch (err) {
      console.log(err);
      this.setState({showEditTeamDialog: false});
      this.props.setLoading(false);
    }
  };

  render () {
    return (
      <DetailsView
        details={this.props.details}
        isLoading={this.props.isLoading}
        viewOpponent={this.state.viewOpponent}
        handleSetViewOpponent={this.handleSetViewOpponent}
        opponent={this.state.opponent}
        isLoadingOpponent={this.state.isLoadingOpponent}
        showEditPlayerDialog={this.state.showEditPlayerDialog}
        handleShowDialog={this.handleShowDialog}
        editPlayer={this.state.editPlayer}
        handleEditPlayerSubmit={this.handleEditPlayerSubmit}
        handleEditPlayerChange={this.handleEditPlayerChange}
        results={this.props.results}
        showEditTeamDialog={this.state.showEditTeamDialog}
        editTeam={this.state.editTeam}
        handleShowTeamDialog={this.handleShowTeamDialog}
        handleEditTeamChange={this.handleEditTeamChange}
        getClubsMatchingQuery={this.props.getClubsMatchingQuery}
        clubs={this.props.clubs}
        handleClubChange={this.handleClubChange}
        handleEditTeamSubmit={this.handleEditTeamSubmit}
      />
    )
  }
}

export default DetailsContainer;
