import React from 'react';
import _ from 'lodash';
import { withRouter } from 'react-router-dom';
import Uuid from 'uuid';
//components
import View from './registration-view';
//utils
import { OrganisationService, ClubService, LookupService, CompetitionService } from '../../utils/data-transfer';
import AuthorisationService from '../../utils/authorisation/authorisationService';

class RegistrationContainer extends React.Component {
  constructor (props) {
    super (props);

    this.state = {
      activeStep: 0,
      isLoading: true,
      clubs: [],
      counties: [],
      completeDetails: {},
      isStepValid: false,
      summaryError: false,
      showSelectCompetition: false,
      competitions: [],
      stripeIntent: null,
      registrationSettings: {}
    };

    this._organisationService = new OrganisationService();
    this._clubService = new ClubService();
    this._lookupService = new LookupService();
    this._competitionService = new CompetitionService();
  }

  componentDidMount () {
    if (_.includes(this.props.location.pathname, 'resume-registration'))
      return this.resumeRegistration();

    localStorage.setItem('paymentError', '');

    if (_.includes(this.props.location.pathname, 'registration'))
      return this.getOrganisationCompetitions();

    return this.getInviteData();
  }

  setActiveStep = (activeStep) => this.setState({ activeStep });

  setStepValid = (isStepValid) => this.setState({ isStepValid });


  getInviteData = async () => {
    try {
      let response = await this._organisationService.getInvitedUserData(this.props.inviteCode);
      if (response.errorMessage)
        return this.props.history.push('/error?errorMessage=' + response.errorMessage);

      this.props.setRegistration(response);

      await this.getCounties();
      let registrationSettings = await this._competitionService.getCompetitionRegistrationSettings(response.competitionId);
      this.setState({ isLoading: false, registrationSettings });
    } catch (err) {
      console.log(err);
    }
  };

  resumeRegistration = async () => {
    try {
      let response = await this._organisationService.resumeRegistration(this.props.teamCode);
      if (response.errorMessage)
        return this.props.history.push('/error?errorMessage=' + response.errorMessage);

      this.props.setRegistration(response);

      await this.getCounties();
      let registrationSettings = await this._competitionService.getCompetitionRegistrationSettings(response.competitionId);
      this.setState({
        isLoading: false,
        activeStep: this.props.registration.tournamentFormatId === 1 ? 3 : 4,
        clubs: [ { clubId: response.clubId, name: response.clubName } ],
        completeDetails: { teamCode: response.teamCode, drawDate: response.drawDate },
        registrationSettings
      });
    } catch (err) {
      console.log(err);
    }
  };

  getOrganisationCompetitions = async () => {
    try {
      let competitions = await this._organisationService.getOpenCompetitions();
      if (!competitions.length) {
        let errorMessage = 'There are no competitions currently running with open registration.';
        this.props.history.push('/error?errorMessage=' + errorMessage);
      }

      if (competitions.length === 1)
        return this.setCompetition(competitions[0]);

      this.setState({ showSelectCompetition: true, competitions });
    } catch (err) {
      console.log(err);
      let errorMessage = 'An unexpected error occurred during registration. If the issue persists please contact support.';
      this.props.history.push('/error?errorMessage=' + errorMessage);
    }
  };

  setCompetition = async (competition) => {
    this.setState({ showSelectCompetition: false });

    try {
      this.props.setRegistration({
        competitionId: competition.id,
        addressId: Uuid.v4(),
        entryIsPrepaid: competition.entryIsPrepaid,
        tournamentFormatId: competition.tournamentFormatId
      });

      let user = await AuthorisationService.getUser();
      if (user) {
        this.props.updateRegistration({
          leadPlayerFirstName: user.profile.given_name,
          leadPlayerLastName: user.profile.family_name,
          leadPlayerEmail: user.profile.email
        });

        let details = await this._organisationService.getUserDetails();

        if (details)
          this.props.updateRegistration({
            leadPlayerFirstName: details.firstName,
            leadPlayerLastName: details.lastName,
            leadPlayerHandicap: details.handicap,
            leadPlayerHandicapNumber: details.handicapNumber,
            leadPlayerEmail: details.emailAddress,
            leadPlayerPhone: details.phoneNumber
          });
      }

      await this.getCounties();
      let registrationSettings = await this._competitionService.getCompetitionRegistrationSettings(competition.id);
      this.setState({ isLoading: false, registrationSettings });
    } catch (err) {
      console.log(err);
    }
  };

  getClubsMatchingQuery = async (queryString) => {
    try {
      let clubs = await this._clubService.getAnonymousFilteredClubs(queryString);

      this.setState({ clubs });
    } catch (err) {
      console.log(err);
    }
  };

  getCounties = async () => {
    try {
      let counties = await this._lookupService.getAnonymousCounties();

      this.setState({ counties });
    } catch (err) {
      console.log(err);
    }
  };

  submitRegistration = async () => {
    this.setState({isLoading: true});

    try {
      let registration = Object.assign({}, this.props.registration);

      let completeDetails = await this._competitionService.registerForCompetition(this.props.registration.competitionId, this.props.registration.competitionTeamId, registration);

      localStorage.setItem('registrationCompleteDetails', JSON.stringify({
        teamName: this.props.registration.pairName,
        drawDate: completeDetails.drawDate,
        competitionId: this.props.registration.competitionId,
        competitionPlayerId: this.props.registration.leadPlayerId
      }));

      if (this.props.registration.entryIsPrepaid)
        return this.props.history.push('/registration-complete');

      this.setState({completeDetails, isLoading: false, activeStep: 4});
    } catch (err) {
      this.setState({isLoading: false, summaryError: true});
      console.log(err)
    }
  };

  canUserRegister = async () => {
    this.setState({isLoading: true});

    try {
      let response = await this._competitionService.checkUserCanRegister(this.props.registration.competitionId, this.props.registration.leadPlayerEmail, this.props.registration.additionalPlayerEmail);

      this.setState({isLoading: false, validationError: response.errorMessage});
    } catch (err) {
      this.setState({isLoading: false});
      console.log(err);
      let errorMessage = 'An unexpected error occurred when trying to validate your registration. Please try again.';
      return this.props.history.push('/error?errorMessage=' + errorMessage);
    }
  };

  render () {
    return (
      <View
        activeStep={this.state.activeStep}
        setActiveStep={this.setActiveStep}
        setRef={this.setRef}
        isLoading={this.state.isLoading}
        registration={this.props.registration}
        handleUpdateRegistration={this.props.handleUpdateRegistration}
        getClubsMatchingQuery={this.getClubsMatchingQuery}
        handleUpdateRegistrationCheck={this.props.handleUpdateRegistrationCheck}
        handleClubSelection={this.props.handleClubSelection}
        clubs={this.state.clubs}
        counties={this.state.counties}
        getCounties={this.getCounties}
        hasSubmitted={this.state.hasSubmitted}
        completeDetails={this.state.completeDetails}
        submitRegistration={this.submitRegistration}
        isStepValid={this.state.isStepValid}
        setStepValid={this.setStepValid}
        summaryError={this.state.summaryError}
        showSelectCompetition={this.state.showSelectCompetition}
        setCompetition={this.setCompetition}
        competitions={this.state.competitions}
        stripeIntent={this.state.stripeIntent}
        handleRegistrationChange={this.props.handleRegistrationChange}
        registrationSettings={this.state.registrationSettings}
        canUserRegister={this.canUserRegister}
        validationError={this.state.validationError}/>
    );
  }
}

export default withRouter(RegistrationContainer);
