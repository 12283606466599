import React from 'react';
//component
import AccountRegistrationPage from '../../components/account-registration';

class AccountRegistration extends React.Component {
  render () {
    return (
        <AccountRegistrationPage accountCode={this.props.match.params.accountCode}/>
    );
  }
}

export default AccountRegistration;