import {UPDATE_REGISTRATION, SET_REGISTRATION} from './registration-actions';

const initialState = {
  competitionId: '',
  competitionTeamId: '',
  pairName: '',
  clubName: '',
  clubId: '',
  hasCheckedTerms: false,
  hasCheckedRules: false,
  leadPlayerId: '',
  leadPlayerFirstName: '',
  leadPlayerLastName: '',
  leadPlayerHandicap: '',
  leadPlayerHandicapNumber: '',
  leadPlayerEmail: '',
  leadPlayerPhone: '',
  addressId: '',
  addressLine1: '',
  addressLine2: '',
  town: '',
  countyId: '',
  postCode: '',
  additionalPlayerId: '',
  additionalPlayerFirstName: '',
  additionalPlayerLastName: '',
  additionalPlayerHandicap: '',
  additionalPlayerHandicapNumber: '',
  additionalPlayerEmail: '',
  additionalPlayerPhone: '',

  entryIsPrepaid: false,
  tournamentFormatId: ''
};

export default function (state = initialState, action) {
  const {type, payload} = action;

  switch (type) {
    case SET_REGISTRATION:
    case UPDATE_REGISTRATION:
      return Object.assign({}, state, payload);
    default:
      return state;
  }
}