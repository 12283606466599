import CoreStyles from '../../utils/styles/core-styles';

const InvalidCodeStyles = theme => ({
  errorIcon: {
    height: 60,
    width: 60
  },
  errorHeader: {
    padding: '20px 0'
  },
  errorBody: {
    padding: '30px 0',
    whiteSpace: 'pre-wrap'
  },
  header: {
    fontSize: '18px',
    fontWeight: 'bold',
    color: theme.palette.primary.contrastText,
    backgroundColor: theme.palette.primary.main,
    textAlign: 'center',
    padding: '10px 0'
  },
  ...CoreStyles(theme)
});

export default InvalidCodeStyles;