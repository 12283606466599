import CoreStyles from '../../../utils/styles/core-styles';

const DetailStyles = theme => ({
  gridItemLeft: {
    padding: '10px 5px 10px 0',
    [theme.breakpoints.down('sm')] : {
      padding: '10px 0'
    }
  },
  gridItemRight: {
    padding: '10px 0 10px 5px',
    [theme.breakpoints.down('sm')] : {
      padding: 0
    }
  },
  cardBodyPadding: {
    padding: '24px 10px 0'
  },
  cardHeader: {
    fontSize: 'h6',
    fontWeight: 'bold',
    color: theme.palette.primary.contrastText,
    backgroundColor: theme.palette.primary.main,
    textAlign: 'center',
    padding: '10px 0'
  },
  cardBar: {
    fontSize: 15,
    marginBottom: 5,
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden'
  },
  cardContent: {
    padding: '0 0 16px'
  },
  playerCardSpacer: {
    marginTop: 10
  },
  avatarImageContainer: {
    textAlign: 'center'
  },
  avatarRoot: {
    margin: '0 auto',
    height: 80,
    width: 80
  },
  editAvatar: {
    fontSize: 12,
    marginTop: 5,
    textDecoration: 'underline'
  },
  viewOpponent: {
    fontSize: 12,
    marginTop: 20,
    textAlign: 'center'
  },
  viewOpponentText: {
    textDecoration: 'underline',
    width: 'fit-content',
    margin: '0 auto',
    cursor: 'pointer',
    '&:hover' : {
      color: '#008CBA'
    }
  },
  opponentXlText: {
    fontSize: 28
  },
  opponentDrawDate: {
    fontSize: 20,
    marginTop: 10
  },
  enterResultsDetails: {
    textAlign: 'center',
    marginBottom: 35,
    fontSize: 16
  },
  enterResultsLabel: {
    fontSize: 18,
    lineHeight: '56px',
    marginBottom: 10
  },
  enterResultsPaper: {
    position: 'relative'
  },
  enterResultsLoadingContainer: {
    position: 'absolute',
    top: 0,
    left: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    zIndex: 1500,
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: 'white',
    flexDirection: 'column'
  },
  errorText: {
    fontSize: '12px',
    color: 'red'
  },
  ...CoreStyles(theme)
});

export default DetailStyles;