import Uuid from 'uuid';
export const UPDATE_REGISTRATION = 'UPDATE_REGISTRATION';
export const SET_REGISTRATION = 'SET_REGISTRATION';

export function setRegistration (value) {
  let defaultPropertyValues = null;
  if (value)
    defaultPropertyValues = {
      competitionId: value.competitionId,
      competitionTeamId: value.competitionTeamId ? value.competitionTeamId : Uuid.v4(),
      pairName: value.pairName ? value.pairName : '',
      clubName: value.clubName ? value.clubName : '',
      clubId: value.clubId ? value.clubId : '',
      hasCheckedTerms: false,
      hasCheckedRules: false,
      leadPlayerId: value.leadPlayer ? value.leadPlayer.competitionTeamPlayerId : Uuid.v4(),
      leadPlayerFirstName: value.leadPlayer && value.leadPlayer.firstName ? value.leadPlayer.firstName : '',
      leadPlayerLastName: value.leadPlayer && value.leadPlayer.lastName ? value.leadPlayer.lastName : '',
      leadPlayerHandicap: value.leadPlayer && value.leadPlayer.handicap ? value.leadPlayer.handicap : '',
      leadPlayerHandicapNumber: value.leadPlayer && value.leadPlayer.handicapNumber ? value.leadPlayer.handicapNumber : '',
      leadPlayerEmail: value.leadPlayer && value.leadPlayer.email ? value.leadPlayer.email : '',
      leadPlayerPhone: value.leadPlayer && value.leadPlayer.phone ? value.leadPlayer.phone : '',
      addressId: '',
      addressLine1: value.leadPlayer && value.leadPlayer.addressLine1  ? value.leadPlayer.addressLine1 : '',
      addressLine2: value.leadPlayer && value.leadPlayer.addressLine2  ? value.leadPlayer.addressLine2 : '',
      town: value.leadPlayer && value.leadPlayer.town  ? value.leadPlayer.town : '',
      countyId: value.leadPlayer && value.leadPlayer.countyId  ? value.leadPlayer.countyId : '',
      postCode: value.leadPlayer && value.leadPlayer.postCode  ? value.leadPlayer.postCode : '',
      additionalPlayerId: value.additionalPlayer ? value.additionalPlayer.competitionTeamPlayerId : Uuid.v4(),
      additionalPlayerFirstName: value.additionalPlayer && value.additionalPlayer.firstName  ? value.additionalPlayer.firstName : '',
      additionalPlayerLastName: value.additionalPlayer && value.additionalPlayer.lastName ? value.additionalPlayer.lastName : '',
      additionalPlayerHandicap: value.additionalPlayer && value.additionalPlayer.handicap ? value.additionalPlayer.handicap : '',
      additionalPlayerHandicapNumber: value.additionalPlayer && value.additionalPlayer.handicapNumber ? value.additionalPlayer.handicapNumber : '',
      additionalPlayerEmail: value.additionalPlayer && value.additionalPlayer.email ? value.additionalPlayer.email : '',
      additionalPlayerPhone: value.additionalPlayer && value.additionalPlayer.phone ? value.additionalPlayer.phone : '',
      entryIsPrepaid: value.entryIsPrepaid,
      tournamentFormatId: value.tournamentFormatId
    };

  return { type: SET_REGISTRATION, payload: { ...value, ...defaultPropertyValues } }
}

export function updateRegistration (value) {
  return { type: UPDATE_REGISTRATION, payload: { ...value } }
}