import React from 'react';
import styles from './competition-styles';
import Moment from 'moment';
//Material
import { withStyles, Card, CardContent, Grid, Button } from '@material-ui/core';
//utils
import { TeamPlayStatus } from '../../../utils/constants';

function CompetitionView (props) {
  const classes = props.classes;

  return (
    <Card className={classes.competition}>
      <CardContent classes={{ root: classes.contentRoot }}>
        {
          TeamPlayStatus.Won === props.competition.teamPlayStatus.id &&
          <Grid container>
            <Grid item xs={12}>
              {
                props.competition.organisationName &&
                  <div className={classes.organisationText}>
                    {
                      props.competition.organisationName
                    }
                  </div>
              }
              <div className={classes.competitionName + ' ' + classes.leftHeader}>{props.competition.competitionName}</div>
              <div className={classes.competitionPlayStatus + ' ' + classes.rightHeader}>WINNER</div>
            </Grid>

            <div className={classes.buttonContainer}>
              <Button className={classes.buttonWinner} color={'primary'} size={'small'} aria-label={'small'} variant={'contained'} onClick={() => props.viewCompetition(props.competition.competitionId, props.competition.competitionPlayerId)}>
                View
              </Button>
            </div>
          </Grid>
        }

        {
          TeamPlayStatus.Eliminated === props.competition.teamPlayStatus.id &&
          <Grid container>
            <Grid item xs={12}>
              {
                props.competition.organisationName &&
                <div className={classes.organisationText}>
                  {
                    props.competition.organisationName
                  }
                </div>
              }
              <div className={classes.competitionName + ' ' + classes.leftHeader}>{props.competition.competitionName}</div>
              <div className={classes.competitionPlayStatus + ' ' + classes.rightHeader}>ELIMINATED</div>
            </Grid>

            <Grid item xs={12}>
              <div className={classes.competitionPlaySubtitle + ' ' + classes.alignRight}>Round&nbsp;{props.competition.roundEliminated}</div>
            </Grid>


            <div className={classes.buttonContainer + ' ' + classes.buttonContainerEliminated}>
              <Button className={classes.buttonWinner} color={'primary'} size={'small'} aria-label={'small'} variant={'contained'} onClick={() => props.viewCompetition(props.competition.competitionId, props.competition.competitionPlayerId)}>
                View
              </Button>
            </div>
          </Grid>
        }

        {
          (TeamPlayStatus.Undrawn === props.competition.teamPlayStatus.id || TeamPlayStatus.Playing === props.competition.teamPlayStatus.id || TeamPlayStatus.Ready === props.competition.teamPlayStatus.id || TeamPlayStatus.Incomplete === props.competition.teamPlayStatus.id ) &&
            <Grid container>
              <Grid item xs={12}>
                {
                  props.competition.organisationName &&
                  <div className={classes.organisationText}>
                    {
                      props.competition.organisationName
                    }
                  </div>
                }
                <div className={classes.competitionName + ' ' + classes.fullHeader}>{props.competition.competitionName}</div>
              </Grid>

              <div className={classes.bottomFooterContainer}>
                {
                  TeamPlayStatus.Playing === props.competition.teamPlayStatus.id &&
                  <div className={classes.bodyTextContainer}>
                    <div className={classes.bodyText + ' ' + classes.matchText}>Next&nbsp;Opponent:&nbsp;{props.competition.opposingTeamName ? props.competition.opposingTeamName : '-'}</div>
                    <div className={classes.bodyText + ' ' + classes.matchText}>Match&nbsp;Deadline:&nbsp;{props.competition.currentRoundEndDate ? Moment(new Date(props.competition.currentRoundEndDate)).format('Do MMMM YYYY') : '-'}</div>
                  </div>
                }

                {
                  TeamPlayStatus.Undrawn === props.competition.teamPlayStatus.id &&
                  <div className={classes.bodyTextContainer}>
                    <div className={classes.bodyText}>Draw Date: { props.competition.drawDate ? Moment(new Date(props.competition.drawDate)).format('Do MMMM YYYY') : '-' }</div>
                  </div>
                }

                {
                  TeamPlayStatus.Ready === props.competition.teamPlayStatus.id &&
                  <div className={classes.bodyTextContainer}>
                    <div className={classes.bodyText}>Waiting for Your Next Opponent</div>
                  </div>
                }

                {
                  TeamPlayStatus.Incomplete === props.competition.teamPlayStatus.id &&
                  <div className={classes.bodyTextContainer}>
                    <div className={classes.bodyText + ' ' + classes.matchText}>Please complete your registration</div>
                    <div className={classes.bodyText + ' ' + classes.matchText}>Register&nbsp;By:&nbsp;{props.competition.registrationDeadline ? Moment(new Date(props.competition.registrationDeadline)).format('Do MMMM YYYY') : '-'}</div>
                  </div>
                }

                <Button className={classes.alignRight} color={'primary'} size={'small'} aria-label={'small'} variant={'contained'}
                        onClick={() => TeamPlayStatus.Incomplete !== props.competition.teamPlayStatus.id ? props.viewCompetition(props.competition.competitionId, props.competition.competitionPlayerId) : props.completeRegistration(props.competition.inviteCode)}>
                  { TeamPlayStatus.Incomplete !== props.competition.teamPlayStatus.id ? 'View' : 'Complete Registration' }
                </Button>
              </div>
            </Grid>
        }
      </CardContent>

      {
        props.competition.infoText &&
          <div className={classes.infoText + ' ' + (props.competition.isInfoWarning ? classes.warningText : '')}>
            {props.competition.infoText}
          </div>
      }
    </Card>
  );
}

export default withStyles(styles) (CompetitionView);