import Axios from 'axios';

const competitionGolfApi = process.env.REACT_APP_COMPETITIONGOLF_PUBLIC_API_ENDPOINT;
const organisationId = process.env.REACT_APP_COMPETITIONGOLF_ORGANISATION_ID;

class LookupService {

  getAnonymousCounties () {
    return Axios.get(competitionGolfApi + 'lookups/anonymous/county')
      .then(resp => resp.data)
      .catch(err => {
        if (err.response.status === 404)
          return [];

        window.rg4js('send', {
          error: err
        });
        throw err;
      });
  }

  getOrganisationMemberTypes () {
    return Axios.get(competitionGolfApi + 'lookups/organisationmembertype/' + organisationId)
      .then(resp => resp.data)
      .catch(err => {
        if (err.response.status === 404)
          return [];

        throw err;
      });
  }
}

export default LookupService;
