import React from 'react';
import RedeemCodeComponent from '../../components/redeem-code';

class RedeemCode extends React.Component {
  render() {
    return (
      <RedeemCodeComponent/>
    );
  }
}

export default RedeemCode;
