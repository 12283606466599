import React from 'react';
import styles from './results-styles';
//material
import { Grid, withStyles } from '@material-ui/core';
//component
import Result from './result';

function ResultsView (props) {
  const classes = props.classes;

  return (
    <Grid container>
      {
        props.results.length > 0 && props.results.map((result, key) =>
          <Grid item xs={12} className={classes.gridItem} key={key}>
            <Result result={result}/>
          </Grid>)
      }

      <Grid item xs={12}>
        {
          props.results.length === 0 &&
          <div className={classes.emptyListView + ' dark-mode-text'}>
            No Results to show
          </div>
        }
      </Grid>

    </Grid>
  );
}

export default withStyles(styles) (ResultsView);