import React from 'react';
import styles from './details-styles';
//material
import { Grid, withStyles } from '@material-ui/core';
//components
import Team from '../../team';
import TeamPlayer from '../../team-player';
import NextOpponent from './next-opponent';
import ViewOpponent from './view-opponent';
import EditPlayer from './edit-player';
import EditTeam from './edit-team';

function DetailsView (props) {
  const classes = props.classes;

  return (
    <Grid container>
      <Grid item xs={12} md={6} className={classes.gridItemLeft}>
        <Team
          isWizardView
          handleWizardEditClick={() => props.handleShowTeamDialog(true)}
          details={props.details}
          isLoading={props.isLoading}
          isSingles={props.details.tournamentFormatId === 1}/>

        <TeamPlayer
          isLeadPlayer
          isWizardView={props.details.isUserLeadPlayer}
          handleWizardEditClick={() => props.handleShowDialog(true, props.details.leadPlayer)}
          player={props.details.leadPlayer}
          isLoading={props.isLoading}
          displayAvatar/>

        {
          props.details.additionalPlayer &&
          <TeamPlayer
            isWizardView
            handleWizardEditClick={() => props.handleShowDialog(true, props.details.additionalPlayer)}
            player={props.details.additionalPlayer}
            handleShowDialog={props.handleShowDialog}
            isLoading={props.isLoading}
            displayAvatar/>
        }

      </Grid>

      <Grid item xs={12} md={6} className={classes.gridItemRight}>
        <NextOpponent
          details={props.details}
          handleSetViewOpponent={props.handleSetViewOpponent}
          isLoading={props.isLoading}
          results={props.results}/>
      </Grid>

      {
        props.viewOpponent &&
          <ViewOpponent
            viewOpponent={props.viewOpponent}
            handleSetViewOpponent={props.handleSetViewOpponent}
            opponent={props.opponent}
            isLoadingOpponent={props.isLoadingOpponent}/>
      }

      {
        props.showEditPlayerDialog &&
          <EditPlayer
            details={props.details}
            player={props.editPlayer}
            showEditPlayerDialog={props.showEditPlayerDialog}
            handleShowDialog={props.handleShowDialog}
            handleEditPlayerSubmit={props.handleEditPlayerSubmit}
            handleEditPlayerChange={props.handleEditPlayerChange}/>
      }

      {
        props.showEditTeamDialog &&
          <EditTeam
            details={props.details}
            team={props.editTeam}
            showDialog={props.showEditTeamDialog}
            handleShowDialog={props.handleShowTeamDialog}
            handleChange={props.handleEditTeamChange}
            getClubsMatchingQuery={props.getClubsMatchingQuery}
            clubs={props.clubs}
            handleClubChange={props.handleClubChange}
            handleEditTeamSubmit={props.handleEditTeamSubmit}
          />
      }
    </Grid>
  )
}

export default withStyles(styles) (DetailsView);