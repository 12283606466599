import React from 'react';
import styles from './user-session-styles';
//material
import { withStyles } from '@material-ui/core';

function UserSessionView (props) {
  const classes = props.classes;

  return (
    <div className={classes.container}>
      {props.children}
    </div>
  );
}

export default withStyles(styles) (UserSessionView);
