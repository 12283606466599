import React from 'react';
import styles from './redeem-code-styles';
//material
import {Button, Card, CardContent, Container, Grid, TextField, withStyles} from '@material-ui/core';
import {Skeleton} from '@material-ui/lab';

function RedeemCodeView (props) {
  const classes = props.classes;

  let isSubmitDisabled = () => {
    return (!props.registration.firstName ||
      !props.registration.lastName ||
      !props.registration.code ||
      !props.registration.email ||
      !props.registration.email.match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/));
  };

  return (
    <Container maxWidth={'md'}>
      <Card>
        <CardContent>
          {
            props.isLoading ?
              <Skeleton width={'70%'} height={56}/> :
              <h2>
                Redeem a Code
              </h2>
          }

          {
            props.isLoading ?
              <Skeleton width={'70%'} height={56}/> :
              <p>
                Please enter the details of the lead player.
              </p>
          }

          <Grid container spacing={2}>
            <Grid item xs={8} md={5}>
              {
                props.isLoading ?
                  <Skeleton width={'100%'} height={56}/> :
                  <TextField
                    fullWidth
                    label={'First Name'}
                    value={props.registration.firstName}
                    name={'firstName'}
                    onChange={props.handleChange}
                    variant={'outlined'}/>
              }
            </Grid>

            <Grid item xs={8} md={5}>
              {
                props.isLoading ?
                  <Skeleton width={'100%'} height={56}/> :
                  <TextField
                    fullWidth
                    label={'Last Name'}
                    value={props.registration.lastName}
                    name={'lastName'}
                    onChange={props.handleChange}
                    variant={'outlined'}/>
              }
            </Grid>

            <Grid item xs={10} md={8}>
              {
                props.isLoading ?
                  <Skeleton width={'100%'} height={56}/> :
                  <TextField
                    fullWidth
                    label={'Email Address'}
                    value={props.registration.email}
                    name={'email'}
                    onChange={props.handleChange}
                    variant={'outlined'}
                    type={'email'}/>
              }
            </Grid>

            <Grid item xs={10} md={8}>
              {
                props.isLoading ?
                  <Skeleton width={'100%'} height={56}/> :
                  <TextField
                    fullWidth
                    label={'Voucher Code'}
                    value={props.registration.code}
                    name={'code'}
                    onChange={props.handleChange}
                    variant={'outlined'}
                    error={props.errorMessage !== ''}
                    helperText={props.errorMessage}/>
              }
            </Grid>

            <Grid container className={classes.buttonOuterContainer}>
              <Grid container>
                <Grid item xs={12} sm={8} md={6} className={classes.buttonContainer}>
                  {
                    props.isLoading ?
                      <Skeleton className={classes.submitButton} width={'100%'} height={36} variant={'rect'}/> :
                      <Button
                        className={classes.submitButton}
                        variant={'contained'}
                        color={'primary'}
                        disableRipple
                        disabled={isSubmitDisabled()}
                        fullWidth
                        onClick={props.handleSubmit}>
                        Submit
                      </Button>
                  }
                </Grid>
              </Grid>
            </Grid>
          </Grid>

        </CardContent>
      </Card>
    </Container>
  );
}

export default withStyles(styles) (RedeemCodeView);
