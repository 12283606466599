import React from 'react';
import styles from './registration-complete-styles';
import Moment from 'moment';
//material
import { Grid, Card, CardContent, Typography, withStyles, Container, Button } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import { Error } from '@material-ui/icons';
//components
import StaticPage from '../static-page';
import TellAFriend from '../tell-a-friend';

function RegistrationCompleteView (props) {
  const classes = props.classes;
  let showTellAFriend = process.env.REACT_APP_COMPETITIONGOLF_SHOW_TELL_A_FRIEND;
  showTellAFriend = (!showTellAFriend.startsWith('#{') && !showTellAFriend.endsWith('}#')) ? JSON.parse(showTellAFriend) : false;

  return (
      <Container maxWidth={'md'} className={classes.containerPadding}>
        <Grid container>

          {
            !props.summaryError &&
            <Grid item xs={12}>

              <Container maxWidth={'sm'}>
                <Grid item xs={12}>
                  <Card className={classes.marginTop20 + ' ' + classes.marginBottom40}>
                    <div className={classes.header}>
                      Registration Complete
                    </div>

                    <CardContent className={classes.registrationCard}>
                      <Grid container>
                        <Grid item xs={12} className={classes.textCenter  + ' ' + classes.marginBottom10}>
                          Thank you for registering to take part in this competition.
                          <br/><br/>
                          Draw&nbsp;Date:&nbsp;{ props.drawDate ? Moment(new Date(props.drawDate)).format('Do MMMM YYYY') : '-' }
                          <br/><br/>
                          Please check your email for your entry confirmation.
                          <br/><br/>
                          {
                            props.isLoading ?
                              <Skeleton width={'50%'} variant={'rect'} className={classes.alignSelfCenter}/> :
                              <Button
                                color={'primary'}
                                variant={'contained'}
                                onClick={props.nextAction}>
                                { props.hasAccount ? (props.user ? 'Go to Dashboard' : 'Sign In') : 'Create Your Account' }
                              </Button>
                          }
                        </Grid>

                        {
                          showTellAFriend && !props.isLoading &&
                          <Grid item xs={12}>
                            <TellAFriend/>
                          </Grid>
                        }
                      </Grid>
                    </CardContent>
                  </Card>
                </Grid>
              </Container>

              <Grid item xs={12} className={classes.marginTop10 + ' dark-mode-text'}>
                {
                  props.isLoading ?
                      <div>
                        <Skeleton width={'50%'} height={28}/>
                        <Skeleton width={'80%'}/>
                        <Skeleton width={'80%'}/>
                      </div>:
                      <StaticPage
                          templateUrl={process.env.REACT_APP_COMPETITIONGOLF_REGISTRATION_COMPLETE_TEMPLATE}
                          hideCard/>
                }

              </Grid>
            </Grid>
          }

          {
            props.summaryError &&
            <Grid className={classes.textCenter} item xs={12}>
              <Card>
                <CardContent className={classes.textCenter}>
                  <Typography className={classes.errorHeader} variant={'h5'}>Error</Typography>
                  <Error className={classes.errorIcon}/>
                  <Typography className={classes.errorBody} variant={'body1'}>
                    An unexpected error occurred when registering you for this competition. Please try again later.
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          }

        </Grid>
      </Container>
  )
}

export default withStyles(styles) (RegistrationCompleteView);
