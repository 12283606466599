import React from 'react';
import styles from './account-registration-styles';
//material
import { Container, Card, CardContent, withStyles, Grid, TextField, Paper, InputAdornment, IconButton, FormControlLabel, Radio, Button } from '@material-ui/core';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import { Skeleton } from '@material-ui/lab';
//utils
import AuthorisationService from '../../utils/authorisation/authorisationService';

function AccountRegistrationView (props) {
  const classes = props.classes;

  return (
      <Container maxWidth={'md'}>
        <Card>
          {
            !props.registrationComplete &&
            <CardContent classes={{ root: classes.cardContent }}>
              {
                props.isLoading ?
                    <Skeleton width={'70%'} height={56}/> :
                    <h2>
                      Sign up for your Competition Golf account
                    </h2>
              }

              {
                props.isLoading ?
                    <Skeleton width={'40%'} height={36}/> :
                    <div>
                      Hello&nbsp;{ props.user.firstName }, thank you for registering for the Matchplay Competition.
                    </div>
              }

              <br/>

              {
                props.isLoading ?
                    <Skeleton width={'40%'} height={30}/> :
                    <strong>
                      By signing up to Competition Golf you can:
                    </strong>
              }

              {
                props.isLoading ?
                    <div>
                      <Skeleton width={'40%'} height={25}/>
                      <Skeleton width={'40%'} height={25}/>
                      <Skeleton width={'40%'} height={25}/>
                    </div>:
                    <ul>
                      <li>View all Competitions you are registered for</li>
                      <li>Track your upcoming Matches and enter the results for them</li>
                      <li>Manage your player details such as the photo we display</li>
                    </ul>
              }

              <Grid container className={classes.gridContainer}>
                <Grid item xs={12} sm={8} md={6}>
                  {
                    props.isLoading ?
                        <Skeleton width={'100%'} height={56} className={classes.marginBottom20}/> :
                        <TextField
                            className={classes.marginBottom20}
                            fullWidth
                            variant={'outlined'}
                            label={'Password'}
                            value={props.password}
                            name={'password'}
                            onChange={props.handleChange}
                            type={!props.showPassword ? 'password' : 'text'}
                            InputProps={{
                              endAdornment:(
                                  <InputAdornment position={'end'}>
                                    <IconButton
                                        onClick={props.handleSetShowPassword}>
                                      { props.showPassword ? <Visibility /> : <VisibilityOff /> }
                                    </IconButton>
                                  </InputAdornment>)
                            }}/>
                  }

                  {
                    props.isLoading ?
                        <Skeleton width={'100%'} height={56}/> :
                        <TextField
                            error={props.verifyPassword.length > 0 && props.verifyPassword !== props.password}
                            helperText={props.verifyPassword.length > 0 && props.verifyPassword !== props.password ? 'Does not match password' : ''}
                            fullWidth
                            variant={'outlined'}
                            label={'Confirm Password'}
                            value={props.verifyPassword}
                            name={'verifyPassword'}
                            onChange={props.handleChange}
                            type={!props.showPassword ? 'password' : 'text'}
                            InputProps={{
                              endAdornment:(
                                  <InputAdornment position={'end'}>
                                    <IconButton
                                        onClick={props.handleSetShowPassword}>
                                      { props.showPassword ? <Visibility /> : <VisibilityOff /> }
                                    </IconButton>
                                  </InputAdornment>)
                            }}/>
                  }
                </Grid>

                <Grid item xs={12} md={4} className={classes.passwordProgressContainer}>
                  {
                    props.isLoading ?
                        <Skeleton width={'100%'} height={150} variant={'rect'}/> :
                        <Paper elevation={0} classes={{ root: classes.passwordProgress }}>
                          Your password should be:
                          <ul>
                            <li className={props.password.length >= 8 ? classes.passwordConditionMet : ''}>a minimum of 8 characters</li>
                            <li className={props.password.match(/[A-Z]/) ? classes.passwordConditionMet : ''}>at least 1 upper-case letter</li>
                            <li className={props.password.match(/[a-z]/) ? classes.passwordConditionMet : ''}>at least 1 lower-case letter</li>
                            <li className={props.password.match(/[0-9]/) ? classes.passwordConditionMet : ''}>at least 1 number</li>
                          </ul>
                        </Paper>
                  }

                </Grid>
              </Grid>

              {
                props.isLoading ?
                    <Skeleton width={'100%'} height={36}/> :
                    <div>
                      Would you like to register for our newsletter and be entered into a monthly draw for a chance to win a box of golf balls?
                    </div>
              }

              <div className={classes.marginBottom20}>
                {
                  props.isLoading ?
                      <Skeleton width={68} height={48} className={classes.displayInlineBlock}/> :
                      <FormControlLabel
                          control={
                            <Radio
                                checked={props.newsletter}
                                onChange={() => props.handleSetNewsletter(true)}
                                color={'primary'}/>}
                          label={'Yes'}/>
                }

                {
                  props.isLoading ?
                      <Skeleton width={68} height={48} className={classes.displayInlineBlock}/> :
                      <FormControlLabel
                          control={
                            <Radio
                                checked={props.newsletter === false}
                                onChange={() => props.handleSetNewsletter(false)}
                                color={'primary'}/>}
                          label={'No'}/>
                }
              </div>

              <Grid container justify={'flex-end'}>
                {
                  props.isLoading ?
                      <Skeleton width={88} height={36} variant={'rect'}/> :
                      <Button
                          color={'primary'}
                          variant={'contained'}
                          onClick={props.registerUser}
                          disabled={props.newsletter === null || props.password.length < 8 || !props.password.match(/[A-Z]/) || !props.password.match(/[a-z]/) || !props.password.match(/[0-9]/) || props.verifyPassword !== props.password}>
                        Sign Up
                      </Button>
                }
              </Grid>

            </CardContent>
          }

          {
            props.registrationComplete &&
                <CardContent classes={{ root: classes.cardContent }}>
                  <h2>
                    Thank you for signing up to Competition Golf
                  </h2>

                  <div>
                    Now that you are signed up with a Competition Golf account you will be able manage the Competition you registered for along with any past and future competitions.
                  </div>

                  <br/>

                  <div className={classes.marginBottom20}>
                    Sign in using the button below to get started.
                  </div>

                  <Grid container justify={'flex-end'}>
                    <Button
                        color={'primary'}
                        variant={'contained'}
                        onClick={AuthorisationService.signinRedirect}>
                      Sign In
                    </Button>
                  </Grid>
                </CardContent>
          }

        </Card>
      </Container>
  );
}

export default withStyles(styles) (AccountRegistrationView);