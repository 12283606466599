import React from 'react';
//components
import AppContentView from './app-content-view.js';

class AppContentContainer extends React.Component {

  render () {
    return (
      <AppContentView
        user={this.props.user}
        settings={this.props.settings}/>
    )
  }
}

export default AppContentContainer;
