import CoreStyles from '../../../../utils/styles/core-styles';

const ViewOpponentStyles = theme => ({
  gridItemLeft: {
    paddingRight: 5,
    [theme.breakpoints.down('sm')] : {
      padding: '10px 0'
    }
  },
  gridItemRight: {
    paddingLeft: 5,
    [theme.breakpoints.down('sm')] : {
      padding: 0
    }
  },
  emptyListView: {
    fontSize: '20px',
    textAlign: 'center',
    marginTop: 60
  },
  viewPaper: {
    backgroundColor: theme.palette.siteBackdrop.main
  },
  dialogHeader: {
    textAlign: 'center',
    maxHeight: '86px'
  },
  headerSubtitle: {
    fontSize: '14px',
    fontWeight: 400
  },
  ...CoreStyles(theme)
});

export default ViewOpponentStyles;