import React from 'react';
import View from './express-checkout-complete-view';
import { withRouter } from 'react-router-dom';
import ReactGA from 'react-ga';
//Utils
import AuthorisationService from '../../utils/authorisation/authorisationService';


class ExpressCheckoutCompleteContainer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      user: null,
      hasAccount: false,
      registrationCode: '',
      voucherRedeemed: false
    };
  }

  componentDidMount() {
    this.getUserStatus();
  }

  getUserStatus = async () => {
    let checkoutDetails = JSON.parse(localStorage.getItem('checkoutCompleteDetails'));
    this.setState({
      hasAccount:  checkoutDetails.isUser,
      registrationCode: checkoutDetails.createAccountCode,
      voucherRedeemed: checkoutDetails.voucherRedeemed
    });

    let user = await AuthorisationService.getUser();
    if (user)
      this.setState({ user });

    ReactGA.event({
      category: 'payment',
      action: 'success'
    });

    ReactGA.pageview(this.props.location.pathname);
  }

  nextAction = async () => {
    if (!this.state.hasAccount)
      return this.props.history.push('/register-user/' + this.state.registrationCode);

    if (this.state.user && !this.state.user.expired)
      return this.props.history.push('/dashboard');

    AuthorisationService.signinRedirect();
  }

  render() {
    return(
      <View
        user={this.state.user}
        hasAccount={this.state.hasAccount}
        nextAction={this.nextAction}
        voucherRedeemed={this.state.voucherRedeemed}/>
    )
  }
}

export default withRouter(ExpressCheckoutCompleteContainer);
