import CoreStyles from '../../utils/styles/core-styles';

const AvatarEditorStyles = theme => ({
  sliderWidth: {
    width: '75%',
    maxWidth: 250,
    display: 'block',
    margin: '0 auto'
  },
  ...CoreStyles(theme)
});

export default AvatarEditorStyles;