export default (theme) => ({
  eventName: {
    fontSize: '30px',
    fontWeight: 500,
    width: 'calc(100% - 50px)',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  },
  subheader: {
    marginTop: 5,
    color: '#646262'
  },
  price: {
    position: 'absolute',
    right: 48,
    top: 32
  },
  bookButton: {
    position: 'absolute',
    right: 40,
    top: 60
  },
  divider: {
    marginTop: 20
  },
  description: {
    marginTop: 20,
  },
});
