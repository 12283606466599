import CoreStyles from '../../utils/styles/core-styles';

const InvalidUserStyles = theme => ({
  header: {
    fontSize: '18px',
    fontWeight: 'bold',
    color: theme.palette.primary.contrastText,
    backgroundColor: theme.palette.primary.main,
    textAlign: 'center',
    padding: '10px 0'
  },
  ...CoreStyles(theme)
});

export default InvalidUserStyles;