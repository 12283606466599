import React from 'react';
//components
import PublicBracket from '../../components/public-bracket';

class ViewBracket extends React.Component {
  render () {
    return (
      <PublicBracket
        bracketCode={this.props.match.params.bracketCode}/>
    )
  }
}

export default ViewBracket;