import React from 'react';
import { withRouter } from 'react-router-dom';
import _ from 'lodash';
import ReactGA from 'react-ga';
//components
import View from './express-checkout-view';
//utils
import { OrganisationService } from '../../utils/data-transfer';
import AuthorisationService from '../../utils/authorisation/authorisationService';
//stripe
import { loadStripe } from '@stripe/stripe-js';

const stripePublicKey = process.env.REACT_APP_COMPETITIONGOLF_STRIPE_PUBLIC_KEY;
const stripePromise = loadStripe(stripePublicKey);

class ExpressCheckoutContainer extends React.Component {
  constructor(props) {
    super(props);

    this._organisationService = new OrganisationService();
    this.state = {
      competitions: [],
      selectedCompetitions: [],
      showSelectCompetitionsDialog: false,
      isLoading: true,
      registration: {
        firstName: '',
        lastName: '',
        email: '',
        discountCode: ''
      },
      discountedCompetitions: [],
      errorMessage: '',
      discountApplied: false,
      discountCodeLocked: false
    }
  }

  componentDidMount() {
    this.getUpcomingCompetitions();
  }

  getUpcomingCompetitions = async () => {
    try {
      ReactGA.pageview(this.props.location.pathname);

      let user = await AuthorisationService.getUser();
      if (user)
        this.setState(
          {
            registration: Object.assign({}, this.state.registration,
              {
                firstName: user.profile.given_name,
                lastName: user.profile.family_name,
                email: user.profile.email
              })
          });

      let competitions = await this._organisationService.getOpenCompetitions();
      if (!competitions.length) {
        let errorMessage = 'There are no competitions currently running with open registration.';
        this.props.history.push('/error?errorMessage=' + errorMessage);
      }

      if (this.props.discountCode) {
        let code = {
          voucherCode: this.props.discountCode,
          competitionIds: competitions.map( x => x.id)
        };

        let validationResponse = await this._organisationService.validateDiscountCode(code);
        if (validationResponse.errorMessage) {
          this.setState({errorMessage: validationResponse.errorMessage, registration: Object.assign({}, this.state.registration, {discountCode: this.props.discountCode})});
        } else {
          return this.setState({
            selectedCompetitions: [_.find(competitions, c => c.id === validationResponse.tournamentId)],
            discountedCompetitions: [...this.state.discountedCompetitions, validationResponse ],
            discountCodeLocked: true,
            isLoading: false,
            registration: Object.assign({}, this.state.registration, {discountCode: this.props.discountCode})
          });
        }
      }

      if (competitions.length === 1)
        return this.setState({ selectedCompetitions: [competitions[0]], isLoading: false });

      this.setState({ competitions, showSelectCompetitionsDialog: true });
    } catch (err) {
      console.log(err);
      let errorMessage = 'An unexpected error occurred when trying to get competitions, please try again later.';
      this.props.history.push('/error?errorMessage=' + errorMessage);
    }
  }

  submitExpressCheckout = async () => {
    try {
      this.setState({ isLoading: true });

      let registration = {
        ...this.state.registration,
        competitionIds: this.state.selectedCompetitions.map( x => x.id),
        discountCodes: this.state.discountedCompetitions
      }
      let paymentResponse = await this._organisationService.setStripeCheckoutPayment(registration);
      localStorage.setItem('checkoutCompleteDetails', JSON.stringify(paymentResponse));

      await this.stripeCheckout(paymentResponse);
    } catch (err) {
      console.log(err);
      let errorMessage = 'We were unable to process your payment at this time, please try again later.';
      this.props.history.push('/error?errorMessage=' + errorMessage);
    }
  };

  stripeCheckout = async (payment) => {
    try {
      const stripe = await stripePromise;
      let products = payment.products;
      if (this.state.discountedCompetitions.length) {
        products = products.map(p => {
          let discountedComp = _.find(this.state.discountedCompetitions, d => d.stripeSku === p);
          if (discountedComp)
            return discountedComp.discountStripeSku;

          return p
        });

      }

      const { error } = await stripe.redirectToCheckout({
        lineItems: products.map(product => ({
          price: product,
          quantity: 1
        })),
        mode: 'payment',
        successUrl: 'https://' + window.location.host + '/checkout-complete',
        cancelUrl: 'https://' + window.location.host + '/registration',
        clientReferenceId: payment.paymentReferenceCode,
        customerEmail: this.state.registration.email
      });

    } catch (err) {
      console.log(err);
      let errorMessage = 'We were unable to process your payment at this time, please try again later.';
      this.props.history.push('/error?errorMessage=' + errorMessage);
    }
  };

  payPalCheckoutComplete = async (details, data) => {
    try {
      this.setState({ isLoading: true });

      let registration = {
        ...this.state.registration,
        competitionIds: this.state.selectedCompetitions.map( x => x.id),
        discountCodes: this.state.discountedCompetitions,
        orderId: data.orderID,
        payerId: data.payerID,
        paymentFirstName: details.payer.name.given_name,
        paymentLastName: details.payer.name.surname,
        paymentEmail: details.payer.email_address,
        amount: details.purchase_units[0].amount.value,
        currency: details.purchase_units[0].amount.currency_code,
        status: details.status
      }

      let paymentResponse = await this._organisationService.setPayPalPayment(registration);
      localStorage.setItem('checkoutCompleteDetails', JSON.stringify(paymentResponse));

      this.props.history.push('/checkout-complete');
    } catch (err) {
      console.log(err);
      let errorMessage = 'An error occurred when attempting to register you but your payment may have still been taken. Please contact support for help completing registration.';
      this.props.history.push('/error?errorMessage=' + errorMessage);
    }
  }

  applyVoucherCode = async () => {
    try {
      this.setState({ errorMessage: '', discountApplied: false });
      let code = {
        voucherCode: this.state.registration.discountCode,
        competitionIds: this.state.selectedCompetitions.map( x => x.id)
      };

      let validationResponse = await this._organisationService.validateDiscountCode(code);
      if (validationResponse.errorMessage)
        return this.setState({errorMessage: validationResponse.errorMessage});

      this.setState({discountedCompetitions: [...this.state.discountedCompetitions, validationResponse ], discountApplied: true });
    } catch (err) {
      console.log(err);
      let errorMessage = 'An error occurred when attempting to register you but your payment may have still been taken. Please contact support for help completing registration.';
      this.props.history.push('/error?errorMessage=' + errorMessage);
    }
  };

  selectCompetitions = (selectedCompetitions) => this.setState({ selectedCompetitions, isLoading: false, showSelectCompetitionsDialog: false });
  handleChange = (e) => this.setState({registration: Object.assign({}, this.state.registration, {[e.target.name]: e.target.value})});
  handleValueTypeChange = (e) => this.setState({ [e.target.name]: e.target.value });

  render () {
    return (
      <View
        isLoading={this.state.isLoading}
        showSelectCompetitionsDialog={this.state.showSelectCompetitionsDialog}
        competitions={this.state.competitions}
        selectedCompetitions={this.state.selectedCompetitions}
        selectCompetitions={this.selectCompetitions}
        registration={this.state.registration}
        handleChange={this.handleChange}
        handleValueTypeChange={this.handleValueTypeChange}
        submitExpressCheckout={this.submitExpressCheckout}
        payPalCheckoutComplete={this.payPalCheckoutComplete}
        applyVoucherCode={this.applyVoucherCode}
        errorMessage={this.state.errorMessage}
        discountApplied={this.state.discountApplied}
        discountedCompetitions={this.state.discountedCompetitions}
        discountCodeLocked={this.state.discountCodeLocked}/>
    )
  }
}

export default withRouter (ExpressCheckoutContainer);
