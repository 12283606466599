import React from 'react';
//component
import ShowMemberComponent from '../../components/show-member';

class ShowMember extends React.Component {
  render () {
    return (
      <ShowMemberComponent
        organisationMemberId={this.props.match.params.organisationMemberId}/>
    );
  }
}

export default ShowMember;
