import React from 'react';
import { withRouter } from 'react-router-dom';
//utils
import { OrganisationService } from '../../utils/data-transfer';
//components
import View from './redeem-code-view';

class RedeemCodeContainer extends React.Component {
  constructor(props) {
    super(props);

    this._organisationService = new OrganisationService();
    this.state = {
      isLoading: false,
      registration: {
        firstName: '',
        lastName: '',
        email: '',
        code: ''
      },
      errorMessage: ''
    }
  }

  handleChange = (e) => this.setState({registration: Object.assign({}, this.state.registration, {[e.target.name]: e.target.value})});
  handleValueTypeChange = (e) => this.setState({ [e.target.name]: e.target.value });

  handleSubmit = async () => {
    this.setState({isLoading: true, errorMessage: ''});

    try {
      let response = await this._organisationService.setCodeCheckout(this.state.registration);

      if (response.errorMessage)
        return this.setState({isLoading: false, errorMessage: response.errorMessage});

      localStorage.setItem('checkoutCompleteDetails', JSON.stringify({ ...response, voucherRedeemed: true}));
      this.props.history.push('/checkout-complete');
    }
     catch (err) {
       console.log(err);
       let errorMessage = 'We were unable to process your code redemption at this time, please try again later.';
       this.props.history.push('/error?errorMessage=' + errorMessage);
    }
  }

  render () {
    return (
      <View
        isLoading={this.state.isLoading}
        registration={this.state.registration}
        handleChange={this.handleChange}
        handleValueTypeChange={this.handleValueTypeChange}
        handleSubmit={this.handleSubmit}
        errorMessage={this.state.errorMessage}/>
    )
  }
}

export default withRouter(RedeemCodeContainer);
