import CoreStyles from '../../utils/styles/core-styles';

const AccountRegistrationStyles = theme => ({
  gridContainer: {
    paddingTop: 40
  },
  cardContent: {
    padding: '16px 21px'
  },
  passwordProgressContainer: {
    padding: '0 20px',
    marginBottom: 40,
    [theme.breakpoints.down('sm')]: {
      padding: '20px 0 ',
      marginBottom: 0
    }
  },
  passwordProgress: {
    backgroundColor: '#eee',
    padding: 10,
    fontSize: '15px'
  },
  passwordConditionMet: {
    listStyleType: '" \\2713   "'
  },
  ...CoreStyles(theme)
});

export default AccountRegistrationStyles;