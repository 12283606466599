const ShowMemberStyles = theme => ({
  grid: {
    [theme.breakpoints.down('xs')]: {
      marginBottom: 20
    }
  },
  memberName: {
    fontWeight: 600,
    fontSize: '24px',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden'
  },
  memberDetails: {
    fontSize: '18px',
    marginTop: 5,
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden'
  },
  socialDetailsContainer: {
    lineHeight: '21px',
    display: 'inline',
  },
  socialDetails: {
    fontSize: '14px',
    marginTop: 5,
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden'
  },
  socialIconContainer: {
    lineHeight: '21px',
    display: 'inline-block',
    verticalAlign: 'middle'
  },
  socialIcon: {
    fontSize: '16px'
  },
  containerPadding: {
    [theme.breakpoints.down('sm')]: {
      paddingLeft: 0,
      paddingRight: 0
    }
  },
  tabContainer: {
    minHeight: 400,
  },
  profileDescription: {
    width: '100%',
    fontSize: '18px',
    paddingTop: 10
  },
  avatarSkeleton: {
    margin: '0 auto'
  },
  avatar: {
    width: 125,
    height: 125,
    margin: '0 auto'
  }
});

export default ShowMemberStyles;
