
const RedeemCodeStyles = theme => ({
  radioButtonItem: {
    marginTop: 16
  },
  submitButton: {
    float: 'right',
    margin: '10px 0'
  },
  buttonOuterContainer: {
    marginTop: 20
  },
  buttonContainer: {
    margin: '0 auto'
  },
  faIcon: {
    width: 'fit-content',
    marginRight: 10
  }
});

export default RedeemCodeStyles;
