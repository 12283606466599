import React from 'react';
//material
import { Container } from '@material-ui/core';
//component
import StaticPage from '../../components/static-page';

class Faqs extends React.Component {
  render () {
    return (
      <Container maxWidth={'md'}>
        <StaticPage
          templateUrl={process.env.REACT_APP_COMPETITIONGOLF_FAQS_TEMPLATE}/>
      </Container>
    );
  }
}

export default Faqs;