import {combineReducers, createStore} from 'redux';
//reducers
import registration from './registration/registration-reducer.js';

const rootReducer = combineReducers({
  registration
});

export default function configureStore (initialState) {
  return createStore(rootReducer, initialState);
}
