import React from 'react';
//services
import { OrganisationService, CompetitionService } from '../../utils/data-transfer';
//components
import PublicBracketView from './public-bracket-view';
import _ from "lodash";

class PublicBracketContainer extends React.Component {
  constructor (props) {
    super (props);

    this.state = {
      details: {},
      bracketMatches: [],
      rounds: [],
      isLoading: true,
      showMatchDialog: false,
      matchLoading: false,
      match: null
    };
    this._organisationService = new OrganisationService();
    this._competitionService = new CompetitionService();
  }

  componentDidMount () {
    return this.getBracket();
  }

  getBracket = async () => {
    try {
      let details = await this._organisationService.viewPublicBracket(this.props.bracketCode);
      let bracketMatches = details.bracketMatches.matches;
      let rounds = details.rounds;

      delete details.bracketMatches;
      delete details.rounds;

      this.setState({
        details,
        bracketMatches,
        rounds,
        isLoading: false
      });
    } catch (err) {

    }
  };

  viewMatch = async (position, matchDetails) => {
    let bracketMatch = matchDetails ? matchDetails : _.find(this.state.bracketMatches, match => match.position === position);
    this.bracketMatch = bracketMatch;

    if (!bracketMatch)
      return;

    if (!_.find(bracketMatch.teams, team => team.name))
      return;

    this.setState({ showMatchDialog: true, matchLoading: true});

    try {
      let match = await this._competitionService.getCompetitionMatchAnonymously(this.state.details.tournamentId, bracketMatch.id);

      this.setState({ match, matchLoading: false });
    } catch (ex) {
      console.log(ex);
      this.setState({ showMatchDialog: false, matchLoading: false });
    }
  }

  handleCloseViewMatchDialog = () => this.setState({ showMatchDialog: false });

  render () {
    return (
      <PublicBracketView
        details={this.state.details}
        bracketMatches={this.state.bracketMatches}
        rounds={this.state.rounds}
        viewMatch={this.viewMatch}
        showMatchDialog={this.state.showMatchDialog}
        handleCloseViewMatchDialog={this.handleCloseViewMatchDialog}
        match={this.state.match}
        matchLoading={this.state.matchLoading}/>
    );
  }
}

export default PublicBracketContainer;