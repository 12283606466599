import React from 'react';
import styles from './result-styles';
//material
import { Card, CardContent, withStyles, Grid } from '@material-ui/core';

function ResultView (props) {
  const classes = props.classes;

  return (
    <Card className={classes.marginBottom10}>
      <CardContent>
        <Grid container className={classes.gridContainer}>
          <div className={classes.textContainer}>
            <div className={classes.roundText}>
              Round #{props.result.roundNumber}
            </div>
          </div>

          <div className={classes.textContainer}>
            <div className={classes.matchText}>Opponent:&nbsp;{ props.result.opponentName ? props.result.opponentName : 'none' }</div>
            <div className={classes.matchText}>{ props.result.clubName ? 'Club: ' + props.result.clubName : '' }</div>
          </div>

          <div className={classes.result + ' ' + ((props.result.isWinner || props.result.isBye) ? classes.resultWin : classes.resultLoss) }>
            <div>{ props.result.isBye ? 'BYE' : props.result.result }</div>
            { !props.result.isBye && <div className={classes.resultText}>{ props.result.isWinner ? 'WON' : 'LOST' }</div>}
          </div>
        </Grid>
      </CardContent>
    </Card>
  );
}

export default withStyles(styles) (ResultView);